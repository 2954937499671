import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Header from './Header';

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '96%',
        padding: '0px 10px',
        //margin: '10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
    progress: {
        margin: theme.spacing(2),
    },
});

class CustomAmount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            custom_amount: '',
            type: '',
            AdminToken: '',
            snackMsg: '',
            snack: false,
            spinner: 'none',
            BUTTONS: true
        }
    }

    componentWillMount() {
        //console.log("Custom Amount Mount")
        if (this.props.state.token === '' || this.props.state.user_type === 'User') {
			window.location.href = "/"
		}
    }

    FormSubmit = (event) => {
        event.preventDefault();
        if (this.state.custom_amount !== '') {
            if (this.state.custom_amount > 0) {
                this.VerifyAdmin()
            }
            else {
                this.setState({ snackMsg: "Invalid Amount.", snack: true })
            }

        } else {
            this.setState({ snackMsg: "Amount is Required.", snack: true })
        }
    }

    async VerifyAdmin() {
        if (navigator.onLine) {
            this.props.history.push("/PaymentMethod", { PropsData: { item_id: -9, price: this.state.custom_amount, type: 'item_refill' } })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }


    render() {
        const { classes } = this.props;
        if(this.props.state.token !== ''){
        return (
            <div className="App">
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.snack}
                    autoHideDuration={1500}
                    onClose={() => this.setState({ snack: false })}>
                    <SnackbarContent
                        className="error"
                        aria-describedby="client-snackbar"
                        message={this.state.snackMsg}
                    />
                </Snackbar>
                {/* {this.state.BUTTONS && <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
                    {
                        this.props.Logout()
                        window.location.href = "/"
                    }
                }}>Sign Out</Button>} */}

                <Header propsState={this.props.state} />

                <Grid container>
                    <Grid item xs />
                    <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                        <div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '20px 20px' }}>
                            <form className={classes.container} autoComplete="off" onSubmit={this.FormSubmit}>
                                <p style={{ textAlign: 'left', fontSize: 16, color: '#000000' }}>Customer Amount</p>
                                <TextField
                                    autoFocus={true}
                                    type="number"
                                    id="custom_amount"
                                    label="Custom Amount"
                                    value={this.state.custom_amount}
                                    onChange={(event) => this.setState({ custom_amount: event.target.value })}
                                    className={classes.Margin}
                                    variant="outlined"
                                    margin="normal"
                                    style={{ width: '100%' }}
                                />
                                {this.state.BUTTONS &&
                                    <Grid container>
                                        <Button type="submit" variant="contained" style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673AB7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                            Continue
				                </Button>
                                        <Button variant="contained" onClick={() => this.props.history.goBack()} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                            Back
                                </Button>
                                    </Grid>}
                                <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                    <center><CircularProgress className={classes.progress} /></center>
                                </div>
                            </form>
                        </div>
                    </Grid>
                    <Grid item xs />
                </Grid>
            </div>
        );}
        else{
        return(<div/>);
        }
    }
}

CustomAmount.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomAmount));
