import React, { useState, useEffect } from 'react';
import { Grid, Switch, Tooltip,
    Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, Select, InputLabel, MenuItem, } from '@material-ui/core';
import progressPng from '../assets/progress.png';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker, TimePicker } from "@material-ui/pickers";
import moment from 'moment';
import URLHelper from '../Helper/URLHelper';

const GreenSwitch = withStyles({
	switchBase: {
        color: green[300],
		'&$checked': {
            color: green[500],
		},
		'&$checked + $track': {
			backgroundColor: green[500],
		},
	},
	checked: {},
	track: {},
})(Switch);

function AddEditPromotionDialog(props){
    const { open, handleClose, setSnackMessage, currentPromotionDetail, token } = props.data
    const [promotionData, setPromotionData] = useState(currentPromotionDetail)
    const [spinner, setSpinner] = useState('none')

    useEffect(() => {
        if(currentPromotionDetail){
            let promo = { ...currentPromotionDetail }
            const { promotion_from_date, promotion_till_date, promotion_from_time, promotion_till_time } = promo
            let dateOfFromDate = moment(promotion_from_date).format('YYYY-MM-DD')
            let dateOfTillDate = moment(promotion_till_date).format('YYYY-MM-DD')
            // console.log('currentPromotionDetail :',currentPromotionDetail)
            if(currentPromotionDetail.action_type !== 'add'){
                let dateOfFromDateTime = moment(dateOfFromDate + "T" + promotion_from_time).format()
                let dateOfTillDateTime = moment(dateOfTillDate + "T" + promotion_till_time).format()
                // console.log('dateOfFromDate :',dateOfFromDate + " " + promotion_from_time)
                // console.log('dateOfTillDate :',dateOfTillDate + " " + promotion_till_time)
                // console.log('dateOfFromDateTime :',dateOfFromDateTime)
                // console.log('dateOfTillDateTime :',dateOfTillDateTime)
                promo = { ...promo, promotion_from_time: dateOfFromDateTime, promotion_till_time: dateOfTillDateTime }
            }
            setPromotionData(promo)
        }
    },[currentPromotionDetail])

    const addEditPromotion = async (fromData) => {
        if (navigator.onLine) {
            setSpinner('block')
            return fetch(URLHelper.AddEditPromotion, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(fromData)
            }).then((Response) => {
                if (Response.status == 404) {
                    setSpinner('none')
                }
                else {
                    Response.json()
                        .then((res) => {
                            setSpinner('none')
                            if (res.status) {
                                console.log("result log list : ", res)
                                setSnackMessage(res.message, "success")
                                
                                resetModal()
                            }
                            else {
                                setSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setSnackMessage("Something went wrong.", "error")
                            setSpinner('none')
                        })
                }
            }).catch((error) => {
                console.log(error)
                setSpinner('none')
                setSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            setSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };


    // console.log('promotionData ::', promotionData)
    const handleDateChange = (date, field) => {
    //   console.log('handleDateChange ::', date, field)
      setPromotionData({ ...promotionData, [field]: date })
    };

    const inputHandler = (event, field) => {
        // console.log('inputHandler ::',event)
        let value = ''
        if(field === 'is_active'){
            value = event.target.checked ? 1 : 0
        }else{
            value = event.target.value
        }
        // console.log('inputHandler value ::',value)
        setPromotionData({ ...promotionData, [field]: value })
    }

    const promotionFormSubmit = () => {
        // console.log('promotionData ::',promotionData)
        let msg = "";
        const { promotion_name, promotion_minutes, promotion_from_date, promotion_till_date, promotion_from_time, promotion_till_time } = promotionData
        let formData = {
            ...promotionData,
            promotion_from_date: moment(promotion_from_date).format('YYYY-MM-DD'),
            promotion_till_date: moment(promotion_till_date).format('YYYY-MM-DD'),
            promotion_from_time: moment(promotion_from_time).format('HH:mm:ss'),
            promotion_till_time: moment(promotion_till_time).format('HH:mm:ss')
        }
        // console.log('formData ::',formData)
        // if(moment(promotion_from_time).format() >= moment(promotion_till_time).format()){
        //     msg += "from time could not be greater than or equal to till time, "
        // }
        // if(moment(promotion_from_time).format('HH:mm:ss') >= moment(promotion_till_time).format('HH:mm:ss')){
        //     msg += "from time could not be greater than or equal to till time, "
        // }
        if(promotion_name === ''){
            msg += "promotion name is required, "
        }
        if(promotion_minutes === ''){
            msg += "promotion minutes are required, "
        }
        if(msg != ""){
            console.log('msg::', msg)
            setSnackMessage(msg, "error")
        }else{
            // console.log('passs ::',formData)
            addEditPromotion(formData)
        }
    }

    const resetModal = () => {
        setPromotionData({
            action_type: 'add',
            promotion_name: '',
            is_active: 1,
            promotion_from_date: new Date(),
            promotion_from_time: new Date(),
            promotion_till_date: moment().add(1,'d').format(),
            promotion_till_time: new Date(),
            // promotion_till_time: moment().add(1,'h').format(),
            promotion_id: 0,
            promotion_minutes: '',
            promotion_type: 'bonus'
        })
        handleClose()
    }

    return(
        <Dialog
            open={open}
            onClose={resetModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <span className="text-safeFormGray text-base ml-6">{`${promotionData.action_type === 'add' ? 'Add' : 'Edit'} Promotion`}</span>
            </DialogTitle>
            <DialogContent>
                <div className="px-6 mt-2">
                    <div className="flex-col -mt-4">
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="promotion_name"
                                        label="Promotion name"
                                        type="text"
                                        className="w-full"
                                        value={promotionData.promotion_name}
                                        onChange={event => inputHandler(event, "promotion_name")}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControl className="w-full">
                                        <InputLabel id="promotion-type-label">Promotion type</InputLabel>
                                        <Select
                                            labelId="promotion-type-label"
                                            id="promotion-type"
                                            value={promotionData.promotion_type}
                                            onChange={event => inputHandler(event, "promotion_type")}
                                        >
                                            <MenuItem value={'bonus'}>Bonus</MenuItem>
                                            <MenuItem value={'rebate'}>Rebate</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="flex flex-row justify-between items-center w-full">
                                        <span>Promotion Date </span>
                                        <span className="text-safeGray">From: </span>
                                        <div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    // autoOk
                                                    className="w-32"
                                                    format="MM-dd-yyyy"
                                                    placeholder="Promotion from date"
                                                    // minDate={(promotionData.action_type === 'add') && new Date()}
                                                    minDate={(promotionData.action_type === 'add') ? new Date() : promotionData.promotion_from_date}
                                                    value={promotionData.promotion_from_date}
                                                    onChange={event => handleDateChange(event,"promotion_from_date")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <span className="text-safeGray">Till: </span>
                                        <div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    // autoOk
                                                    className="w-32"
                                                    format="MM-dd-yyyy"
                                                    placeholder="Promotion till date"
                                                    minDate={moment(promotionData.promotion_from_date).add(1, 'd')}
                                                    value={promotionData.promotion_till_date}
                                                    onChange={event => handleDateChange(event,"promotion_till_date")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="flex flex-row justify-between items-center w-full">
                                        <span>Promotion Time </span>
                                        <span className="text-safeGray">From: </span>
                                        <div>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <TimePicker
                                                    // autoOk
                                                    // format="HH:mm:ss"
                                                    className="w-32"
                                                    // ampm={false}
                                                    views={["hours","minutes","seconds"]}
                                                    placeholder="Promotion start time"
                                                    value={promotionData.promotion_from_time}
                                                    onChange={event => handleDateChange(event,"promotion_from_time")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                        <span className="text-safeGray">Till: </span>
                                        <div>
                                            {/* {console.log('promotionData.promotion_from_time ::',promotionData.promotion_from_time)} */}
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <TimePicker
                                                    // autoOk
                                                    // format="HH:mm:ss"
                                                    className="w-32"
                                                    // ampm={false}
                                                    views={["hours","minutes","seconds"]}
                                                    placeholder="Promotion start time"
                                                    minDate={promotionData.promotion_from_time}
                                                    value={promotionData.promotion_till_time}
                                                    onChange={event => handleDateChange(event,"promotion_till_time")}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="promotion_minutes"
                                        label="Promotion minutes"
                                        type="number"
                                        className="w-full"
                                        value={promotionData.promotion_minutes}
                                        onChange={event => inputHandler(event, "promotion_minutes")}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className="flex flex-row items-center w-full">
                                        <span className="text-safeGray">Promotion Status: </span>
                                        <div>
                                            <Tooltip title={(promotionData.is_active === 1) ? "Active" : "InActive"} placement="top">
                                                <GreenSwitch
                                                    checked={(promotionData.is_active === 1) ? true : false}
                                                    onChange={event => inputHandler(event, "is_active")}
                                                    value={promotionData.is_active}
                                                />
                                            </Tooltip>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button
                    type="button"
                    disabled={(spinner === 'block')}
                    className="text-safeRed w-32 py-1 float-right font-sans font-semibold text-xs md:text-sm"
                    onClick={() => resetModal() }>Back
                </button>
                <button
                    type="button"
                    disabled={(spinner === 'block')}
                    onClick={() => promotionFormSubmit() }
                    className="bg-primary w-32 float-left text-white py-4 my-2 mr-8 shadow-xl rounded font-sans text-xs md:text-sm">
                    {(spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Save' }
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default AddEditPromotionDialog;