import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Header from './Header';

const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexGrow: 1,
        paddingTop: 50,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class ReplenishmentDone extends Component {
    constructor(props) {
        super(props)
        this.state = {
            PropsData: ''
        }
    }

    componentWillMount() {
        //console.log("Enter CC Info Mount")
        if (this.props.state.token === '') {
            //console.log("Token not")
            window.location.href = "/"
        } else if (this.props.location.state !== undefined) {
            //console.log("State")
            this.setState({ PropsData: this.props.location.state.PropsData })
        }
        else {
            //console.log("State not")
            window.location.href = "/MainMenu"
        }
    }

    onBackButtonEvent = (e) => {
        e.preventDefault();
        this.props.Logout()
        window.location.href = '/'
    }

    componentDidMount() {
        window.onpopstate = this.onBackButtonEvent;

    }

    render() {
        const { classes } = this.props;
        if (this.props.state.token !== '') {
            return (
                <div className="App">
                    {/* <Button style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
                        {
                            this.props.Logout()
                            window.location.href = "/"
                        }
                    }}>Sign Out</Button> */}

                <Header propsState={this.props.state} />

                    <Grid container>
                        <Grid item xs />
                        <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF', color: '#000000', textAlign: 'center', border: '0.5px solid #707070', marginBottom: '9.44%' }}>
                            <h2>Auto Replenish ${this.props.location.state.PropsData} is setup!</h2>
                            <Grid container>
                                <Grid item xs />
                                <Grid item xs={6} sm={6} md={6} style={{ margin: '2%' }}>
                                    <Button variant="contained" onClick={() => {
                                        if (this.props.state.user_type != 'Admin') {
                                            this.props.Logout()
                                            window.location.href = "/"
                                        }
                                        else {
                                            this.props.history.push("/AccountList")
                                        }
                                    }} className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673ab7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                        Done
                                </Button>
                                </Grid>
                                <Grid item xs />
                            </Grid>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

ReplenishmentDone.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ReplenishmentDone));
