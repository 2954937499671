const AppHelper = {
    makeDateFormat: function (data) {
        let expDate = ''
        if (data.length > 0) {
            let first = data.substring(0,2)
            let last = data.substring(2,4)
            expDate = first + "/" + last
        }
        return expDate
    },
    changeDateFormat: function (data) {

        // if (data.length == 7) {
        //     var arr = data.split('-')
        //     data = arr[1] + "-" + arr[0]
        // }

        return data.replace("/", '')
    },
    verifyStatus: function (status) {
        console.log("got status at helper ::", status);
        if (status === 401) {
            window.location.href = "/";
        }
    },
    makeCurrencyFormat: function (passData) {
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        });
        return formatter.format(passData)
    },
    chargePerMinute: 0.15,

    PleaseWaitTitle: 'Please wait while the transaction is being processed.',
    PleaseWaitMsg: 'Please do not refresh the page.',
    DailogTitle: 'Are you sure?',
    DailogMsg: 'You want to cancel this transaction?',
    // SUT token generator
    xKey: '',
    xSoftwareName: "Safe Tech",
    xSoftwareVersion: "1.0",
    xTokenType: "card",
    xTokenVersion: 1,
    xVersion: "2.5.1905.0801"

}

export default AppHelper