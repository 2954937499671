import './css/App.css'
import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Button } from '@material-ui/core'
import URLHelper from '../Helper/URLHelper'
import AppHelper from '../Helper/AppHelper'

function RemoveAutoreplenishModal(props) {
    // console.log('RemoveAutoreplenishModal ::',props.data)
    const { open, handleClose, setSnackMessage, user_id, token } = props.data

    const removeAutoReplenish = async () => {
        if (navigator.onLine) {
            return fetch(URLHelper.RemoveAutoReplenish, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify({ user_id })
            }).then((Response) => {
                Response.json().then((res) => {
                    if (res.status) {
                        // console.log('RES ::',res)
                        setSnackMessage(res.message, "success")
                        handleClose(true)
                    }
                    else {
                        setSnackMessage(res.message, "error")
                        handleClose(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setSnackMessage("Something went wrong.", "error")
                    handleClose(false)
                })
            })
        }
        else {
            setSnackMessage("Internet Not Available. Try Again!!", "error")
            handleClose(false)
        }
    }

    return(
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">You want to remove Auto Replenish?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} color="primary">
                    No
                </Button>
                <Button onClick={() => {
                    removeAutoReplenish()
                }} color="primary" autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveAutoreplenishModal