import './css/App.css';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Checkbox, Tooltip, DialogContentText } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
// import Header from './Header';
import UserHeader from './UserHeader';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import AppHelper from '../Helper/AppHelper';
import DialogTitle from '@material-ui/core/DialogTitle';
import correctPng from '../assets/correct.png';
import filledCorrectPng from '../assets/filled_correct.png';
import deleteCardPng from '../assets/delete_card.png';
import visaCardPng from '../assets/credit_card_visa.png';
import americanExpressCardPng from '../assets/credit_card_american_express.png';
import masterCardPng from '../assets/credit_card_master.png';
import clsx from 'clsx';
import MaskedInput from 'react-maskedinput';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import progressPng from '../assets/progress.png';
import SafeTechSnackbar from './SafeTechSnackbar';
import UpdateExpiryDateModal from './UpdateExpiryDateModal';
import RemoveAutoreplenishModal from './RemoveAutoreplenishModal';
// import ToggleOnTwoToneIcon from '@material-ui/icons/ToggleOnTwoTone';
// import ToggleOffTwoToneIcon from '@material-ui/icons/ToggleOffTwoTone';

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	roundedBox: {
		height: '200px',
		width: '200px'
	},
	boxIcon: {
		height: '40px',
		width: '40px'
	},
	balanceNumber: {
		textAlign: 'center',
		font: 'Bold Roboto',
		letterSpacing: '0px',
		textShadow: '0px 2px 5px #00000029',
		opacity: 1
	}
});

const PrimaryCheckbox = withStyles({
	root: {
	  '&$checked': {
		color: '#273359',
	  },
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

class UserCards extends Component {
	constructor(props) {
		super(props)
		this.state = {
            card_list: [],
            modalCCOpen: false,
			spinner: 'none',
			add_card_spinner: 'none',
            snackMsg: '',
            snackVariant: 'error',
			snack: false,
			BUTTONS: false,
			modalOpen: false,
            remove_credit_card_id: 0,
            modalDPOpen: false,
            name_on_card: '',
            card_number: '',
            expiry_date: '',
            cvv: '',
            card_street_number: '',
            card_address: '',
            card_city: '',
            card_state: '',
            card_zip: '',
            is_billing_address: false,
            is_primary_card: false,
            is_agree_tnc: false,
            modalCEOpen: false,
            update_creditcard_id: 0,
            old_exp_date: '',
            // modal_auto_replenish: false,
            // auto_replenish_action: '',
            auto_replenish: '',
            auto_replenish_card_id: 0,
            modal_remove_auto_replenish: false,
            openCancel: false,
            plan: null
        }

        this.setTextInputRef = element => {
            // console.log('setTextInputRef :::', element)
            if (element !== null){
                // console.log('element not null.....')
                this.addressReference = React.createRef();
            }
        };
	}

	// Logout = () => {
	// 	this.props.Logout()
	// 	window.location.href = "/"
	// }
    componentDidUpdate(prevProps) {
        // console.log('componentDidUpdate ::', prevProps);
        if (this.addressReference){
			const autocompleteFormField = this.addressReference.current;
            // console.log('autocompleteFormField 2::====>', autocompleteFormField);
			const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
				types: ['address'],
            });
            // console.log('autocomplete ::====>', autocomplete);
			window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
				this.setState({ card_street_number: '', card_address: '', card_zip: '', card_state: '', card_city: '' })
				const place = autocomplete.getPlace();
				// console.log('PLACE ===>', place);
				if (place.address_components != null) {
					place.address_components.map((value, index) => {
						if (value.types.includes('street_number')) {
							this.setState({ card_street_number: value.long_name })
						}
						if (value.types.includes('route')) {
							this.setState({ card_address: this.state.card_street_number + " " + value.long_name })
						}
						if (value.types.includes('postal_code')) {
							this.setState({ card_zip: value.long_name })
						}
						if (value.types.includes('administrative_area_level_1')) {
							this.setState({ card_state: value.long_name })
						}
						if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
							this.setState({ card_city: value.long_name })
						}
					})
				}
            });
        }
    }
    
    componentDidMount(){
        // this.focusTextInput();
        // console.log('on user cards state : ',this.props);
        if (this.props.state.token === '' || this.props.state.user_type === 'Admin') {
            if(this.props.state.token === ''){
                window.location.href = "/"
            }else{
                window.location.href = "/AccountList"
            }
		}
		else {
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true, snackVariant: 'success' })
				this.props.UpdateMSG('')
			}
			this.fetchCardListing()
			this.fetchCurrentUserDetails()
			this.fetchPlanListing()
        }
    }

	handleClickOpen = () => {
		// console.log("user id to set new pass : ",this.props.state.user_id);
		this.setState({ modalOpen: true })
	}

    callAction = (action_name, credit_card_id) => {
		let url = '';
		let Data = {
			// admin_token: this.props.state.token,
			user_id: this.props.state.user_id,
			creditcard_id: credit_card_id
		}
		if (action_name == 'remove_credit_card') {
			url = URLHelper.RemoveCreditCard
		} else if (action_name == 'set_default_credit_card') {
			url = URLHelper.SetDefaultCreditCard
		}
		console.log('poting on url :', url)
		if (navigator.onLine) {
			this.setState({ spinner: 'block' })
			// console.log('posting data : ', Data)
			return fetch(url, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ spinner: 'none' })
					// console.log("Response==>\n" + JSON.stringify(res))
					if (res.status) {
						// console.log("TRUE")
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'success', spinner: 'none' })
						this.fetchCardListing()
					}
					else {
						this.setState({ spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'error' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
    }
    
	async fetchCardListing() {
        if (navigator.onLine) {
            this.setState({ spinner: 'block', BUTTONS: false })
            var Data = {
                start: 0,
                user_id: this.props.state.user_id
            }
            return fetch(URLHelper.GetCardListing, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify(Data)
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                if (Response.status == 404) {
                    this.setState({ spinner: 'none', card_list: [] })
                } else {
                    Response.json().then((res) => {
                        this.setState({ spinner: 'none', BUTTONS: true })
                        if (res.status) {
                            let CARDS = [];
                            // let auto_replenish_card_id = ''
                            for (var i = 0; i < res.data.length; i++) {
                                // if(i === 0){
                                //     auto_replenish_card_id = res.data[i].auto_replenish_creditcard_id
                                // }
                                CARDS.push({ default_creditcard_id: res.data[i].default_creditcard_id, card_type: res.data[i].card_type, creditcard_id: res.data[i].creditcard_id, last_four: res.data[i].last_four, exp_date: res.data[i].exp_date, name: res.data[i].name })
                            }
                            // console.log('CARDS ::', CARDS)
                            this.setState({ card_list: CARDS,
                                // auto_replenish_card_id
                            })
                        }
                        else {
                            this.setState({ card_list: [], spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                        }
                    })
                    .catch((error) => {
                        this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
                    })
                }
            })

        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }
    
    inputHandler = (event, inputID) => {
		let value = event.target.value
		if (inputID == "search_address") {
			this.setState({ search_address: value })
		}
		else if (inputID == "card_address") {
			this.setState({ card_address: value })
		}
		else if (inputID == "card_city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ card_city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "card_state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setState({ card_state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "card_zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setState({ card_zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
		// else if (inputID == "phone") {
		// 	if (value.length < 11) {
		// 		if (/^\d+$/.test(value) || value.length == 0) {
		// 			this.setState({ user_phone: value })
		// 		}
		// 		else {
		// 			//console.log("Not number")
		// 		}
		// 	}
		// }
		// else if (inputID == "email") {
		// 	this.setState({ user_email: value })
        // }
        else if (inputID == "name_on_card") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ name_on_card: value })
            }
        }
        else if (inputID == "card_number") {
            if (value.length < 17) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ card_number: value })
                }
            }
        }
        else if (inputID == "expiry_date") {
            this.setState({ expiry_date: value })
        }
        else if (inputID == "cvv") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ cvv: value })
                }
            }
        }
    }

    handleChageExpOpen = (data) => {
        const { card_id, expDate } = data
        this.setState({ modalCEOpen: true, update_creditcard_id: card_id, old_exp_date: expDate })
    }

    handleChageExpClose = updateState => {
        this.setState({ modalCEOpen: false, update_creditcard_id: 0, old_exp_date: '' })
        if(updateState) this.fetchCardListing();
    }

    handleSnackMessage = ( snackMsg, snackVariant ) => {
        this.setState({ snackMsg, snack: true, snackVariant })
    }

    handleRemoveAutoreplenishOpen = () => {
        this.setState({ modal_remove_auto_replenish: true })
    }

    handleRemoveAutoreplenishClose = updateState => {
        this.setState({ modal_remove_auto_replenish: false })
        if(updateState) this.fetchCurrentUserDetails();
    }
    
    listCards = (plansArray) => {
		// console.log('cards ::',plansArray)
        return (
		plansArray.map((data, index) =>
			<div key={data.creditcard_id} className="p-2 w-6/12 lg:w-4/12">
                <div className={clsx((data.creditcard_id === data.default_creditcard_id) && "border border-safeGreen", "md:w-mdCw md:h-mdCh rounded p-2 my-2 credit-card-list")}>
                    <div className="grid grid-rows-3 grid-flow-col items-center">
                        <div className='flex flex-row text-safeFormGray justify-between'>
                            <span className="text-smXs sm:text-xs ml-0 sm:ml-2 px-0">{data.name}</span>
                            <span className="pl-4 ml-2 sm:ml-6 text-xs lg:text-sm">{data.last_four}</span>
                        </div>
                        <Tooltip title={(data.creditcard_id !== data.default_creditcard_id) ? 'Set as primary' : ''} placement="left">
                        <div className="py-0 sm:py-2">
                            {
                                (data.creditcard_id === data.default_creditcard_id) ?
                                <img title="set-as-primary" className="h-4 cursor-pointer" src={filledCorrectPng} alt="filled-correct-png"/>
                                :
                                <img onClick={() => { this.callAction('set_default_credit_card', data.creditcard_id) }} className="h-4 selected-card-png" src={correctPng} alt="correct-png"/>
                                // <RadioButtonUncheckedIcon fontSize="small" className="text-black bg-gray-200"/>
                            }
                        </div>
                        </Tooltip>
                        <div className='flex flex-row text-safeFormGray items-center justify-between'>
                            <Tooltip title='Remove credit card' placement="bottom">
                                <img onClick={() => {
                                    // if(data.creditcard_id === data.default_creditcard_id){
                                    //     this.setState({ snackMsg: 'Can\'t remove primary credit card', snack: true, snackVariant: 'error' })
                                    // }else{
                                    this.setState({ remove_credit_card_id: data.creditcard_id, modalDPOpen: true })
                                    // }
                                    }} className="h-4 ml-0 px-4 delete-card-png" src={deleteCardPng} alt="delete-card-png"/>
                            </Tooltip>
                            <Tooltip title='Click to edit' placement="bottom">
                                {/* <span onClick={ev => this.handleChageExpOpen({ card_id: data.creditcard_id, expDate: 1021 + '' }) } className="cursor-pointer mx-auto -mr-4 px-3 sm:px-4 text-smXs sm:text-xs">{`10/21`}</span> */}
                                <span onClick={ev => this.handleChageExpOpen({ card_id: data.creditcard_id, expDate: data.exp_date }) } className="cursor-pointer mx-auto -mr-4 px-3 sm:px-4 text-smXs sm:text-xs">{AppHelper.makeDateFormat(data.exp_date)}</span>
                                {/* <CreateIcon fontSize="small" style={{ height: '14px' }} /> */}
                            </Tooltip>
                            {
                                (typeof data.card_type !== 'undefined' && ( data.card_type === 'visa' || data.card_type === 'mastercard' || data.card_type === 'american-express' )) ?
                                <img className="h-5 ml-0 sm:ml-4" src={this.getCardCreditCardImg(data.card_type)} alt="card-png"/> :
                                <CreditCardIcon className="h-5 ml-0 sm:ml-4" color="primary"/>
                            }
                        </div>
                    </div>
                    
                </div>
            </div>
		));
    }
    
    getCardCreditCardImg(card_type){
        if(card_type === 'visa'){
            return visaCardPng;
        }else if(card_type === 'mastercard'){
            return masterCardPng;
        }else if(card_type === 'american-express'){
            return americanExpressCardPng;
        }
    }

    handleBillingCheckbox = (event, inputField) => {
		// console.log('handleBillingCheckbox :', event.target.checked, inputField)
		if(inputField === 'is_billing_address'){
			this.setBillingAddress(event.target.checked)
		}else{
			this.setState({ [inputField]: event.target.checked })
		}
	}

	setBillingAddress = (action) => {
        const { address, city, state, zip } = this.props.state;
		if(action){
			this.setState({ is_billing_address: true, card_address: address,
                card_city: city, card_state: state, card_zip: zip })
		}else{
			this.setState({ is_billing_address: false, card_address: '',
                card_city: '', card_state: '', card_zip: '' })
		}
    }
    
    resetAddCardDialog = () => {
		this.setState({
        modalCCOpen: false,    
		is_billing_address: false, card_address: '', card_city: '', card_state: '', card_zip: '',
		is_primary_card: false, is_agree_tnc: false, name_on_card: '',
		card_number: '', expiry_date: '', cvv: ''});
    }

    billingFormSubmit = () => {
		// console.log("called billingFormSubmit...")
		if(this.state.is_agree_tnc){
			this.addCreditCard()
		}else{
			this.setState({snackMsg: 'Please accept terms and conditions!!', snack: true, snackVariant: 'error' })
		}
    }
    
    addCreditCard = () => {
		if (navigator.onLine) {
			this.setState({ add_card_spinner: 'block' })
			let Data = {
				user_id: this.props.state.user_id,
				name: this.state.name_on_card,
				cc_num: this.state.card_number,
				exp_date: AppHelper.changeDateFormat(this.state.expiry_date),
				cvv: this.state.cvv,
				address: this.state.card_address,
				city: this.state.card_city,
				state: this.state.card_state,
				zip: this.state.card_zip,
				set_default_card : this.state.is_primary_card
			}
			return fetch(URLHelper.AddCreditCard, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					this.setState({ add_card_spinner: 'none' })
					// console.log("Response==>\n" + JSON.stringify(res))
					if (res.status) {
						// console.log("TRUE", res)
                        this.fetchCardListing();
                        this.resetAddCardDialog();
						this.setState({snackMsg: res.message, snack: true, snackVariant: 'success'})
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error' })
					}
				})
				.catch((error) => {
					console.log("Error==>" + error)
					this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', add_card_spinner: 'none' })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
    }
    
    hideSnackBar = () => {
		this.setState({ snack: false })
    }

    async fetchPlanListing() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetPlans + "/0", {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						let plan = null;
						if(res.data.length > 0)
						{
							res.data.forEach((element) => {
								// console.log('element ::::', element)
                                if(element.is_unlimited){
                                    plan = element;
                                }
							})
						}
						this.setState({ plan })
					}
					else {
						this.setState({ spinner: 'none', plan: null, snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ spinner: 'none', plan: null, snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

    async fetchCurrentUserDetails() {
		if (navigator.onLine) {
			return fetch(URLHelper.GetUserDetail + "/" + this.props.state.user_id, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						let userData = res.data[0];
						// console.log('userData :', userData)
                        const { auto_replenish, auto_replenish_creditcard_id } = userData
						this.setState({ auto_replenish, auto_replenish_card_id: auto_replenish_creditcard_id })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
					}
				})
				.catch((error) => {
					// console.log("Error==>" + error)
					this.setState({ button: '', snackMsg: "Something went wrong here.", snack: true, snackVariant: 'error', BUTTONS: true })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

    handleClickManageAutoReplenish = (active_monthly_subscription) => {
        let is_auto_replenish = ((this.state.auto_replenish === 1) || (this.state.auto_replenish_card_id > 0));
        if(!is_auto_replenish && active_monthly_subscription){
            if(this.state.plan){
                const PropsData = {
                    type: 'auto-refill',
                    is_unlimited: this.state.plan.is_unlimited,
                    plan_id: this.state.plan.plan_id,
                    price: this.state.plan.price,
                    bonus: this.state.plan.bonus,
                    actionBy: 'user',
                    userId : this.props.state.user_id
                }
                this.props.history.push("/PaymentMethod", { PropsData })
            }
        }else{
            this.props.history.push("/Plans", { PropsData: { type: 'auto-refill' } })
        }
    }

    handleCancelMonthlySubscription = (user_monthly_subscription_id) => {
		if (navigator.onLine) {
            this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.CancelMonthlySubscription, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify({ user_monthly_subscription_id })
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                if (Response.status == 404) {
                    this.setState({ spinner: 'none' })
                } else {
                    Response.json().then((res) => {
                        if (res.status) {
                            // console.log('RES ::',res)
                            this.setState({ BUTTONS: true, spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'success' }, () => {
								setTimeout(() => {
									const { credit_balance } = this.props.state
                                    this.props.CustomerDetail({ ...this.props.state, credit: credit_balance, user_monthly_subscription_id: '', is_active_monthly_subscription: 1, is_subscribed: 0 })
                                    this.props.history.push("/MainMenu");
								}, 1500);
							})
                        }
                        else {
                            this.setState({ spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                        }
                    })
                        .catch((error) => {
                            console.log(error)
                            this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
                        })
                }
            })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
	}

	render() {
        const { is_active_monthly_subscription, is_subscribed, user_monthly_subscription_id } = this.props.state;
        // console.log("this.props.state ::",this.props.state)

		if (this.props.state.token !== '' && this.props.state.user_type === 'User') {
			return (
				<div>
                    <SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />
					
					<UserHeader propsState={this.props.state} />
                    <Dialog
                        open={this.state.openCancel}
                        onClose={() => this.setState({ openCancel: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">You want to cancel your monthly subscription?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ openCancel: false })} color="primary">
                                No
                            </Button>
                            <Button onClick={() => {
                                this.setState({ openCancel: false })
                                this.handleCancelMonthlySubscription(user_monthly_subscription_id)
                            }} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        ref={this.setTextInputRef}
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.modalCCOpen}
                        onClose={() => this.setState({ modalCCOpen: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ zIndex: 0 }}
                        >
                        <DialogTitle id="alert-dialog-title">
                            <span className="text-safeFormGray text-base ml-6">Credit Card Info</span>
                        </DialogTitle>
                        <DialogContent>
                            <div className="px-6 mt-2">
                                <div className="flex-col -mt-4">
                                    <div className="text-center">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextField
                                                    id="name_on_card"
                                                    label="Name on card"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.name_on_card}
                                                    onChange={(event) => this.inputHandler(event, "name_on_card")}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextField
                                                    id="card_number"
                                                    label="Card Number"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.card_number}
                                                    onChange={(event) => this.inputHandler(event, "card_number")}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className="flex">
                                                    <div className="flex-initial w-1/2 text-center pr-4">
                                                        <MaskedInput
                                                            mask="11/11"
                                                            id="expiry_date"
                                                            name="expiry_date"
                                                            className="w-full pl-2 border-2 border-gray-300 text-black rounded"
                                                            style={{ height: '55px', fontSize: '16px' }}
                                                            value={this.state.expiry_date}
                                                            placeholder="MM/YY"
                                                            onChange={(event) => this.inputHandler(event, "expiry_date")}
                                                        />
                                                    </div>
                                                    <div className="flex-initial w-1/2 text-center">
                                                        <TextField
                                                            type="tel"
                                                            id="cvv"
                                                            label="CVV"
                                                            className="w-full"
                                                            value={this.state.cvv}
                                                            onChange={(event) => this.inputHandler(event, "cvv")}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="text-center mt-4">
                                        <div className="flex flex-col my-2 items-start">
                                            <div>
                                                <div className="text-safeFormGray text-xs -ml-2">
                                                    <PrimaryCheckbox
                                                    size="small"
                                                    checked={this.state.is_billing_address}
                                                    onChange={(event) => { (this.state.edit_button_disabled) ? this.setState({ snackMsg: "You haven't selected any user!!", snack: true, snackVariant: 'error' }) : this.handleBillingCheckbox(event, 'is_billing_address') }}
                                                    inputProps={{ 'aria-label': 'billing address checkbox' }}/>
                                                    Billing address same as customer info
                                                </div>
                                            </div>
                                            <div className="text-safeFormGray text-sm">
                                                <span className="text-safeFormGray text-base my-2">Billing Address</span>
                                            </div>
                                        </div>
                                        <div>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <input
                                                        id="location"
                                                        placeholder="Search Address"
                                                        type="text"
                                                        margin="normal"
                                                        onChange={(event) => this.inputHandler(event, "search_address")}
                                                        style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
                                                        ref={this.addressReference}
                                                        variant="outlined"
                                                        className="w-full border-solid border border-gray-500 rounded"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        id="card_address"
                                                        label="Address"
                                                        type="text"
                                                        disabled={this.state.is_billing_address}
                                                        className="w-full"
                                                        value={this.state.card_address}
                                                        onChange={(event) => this.inputHandler(event, "card_address")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <div className="flex">
                                                        <div className="flex-auto w-2/5 pr-2 text-center">
                                                            <TextField
                                                                id="card_city"
                                                                label="City"
                                                                type="text"
                                                                disabled={this.state.is_billing_address}
                                                                className="w-full"
                                                                value={this.state.card_city}
                                                                onChange={(event) => this.inputHandler(event, "card_city")}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className="flex-auto w-1/5 pr-2 text-center">
                                                            <TextField
                                                                id="card_state"
                                                                label="State"
                                                                type="text"
                                                                disabled={this.state.is_billing_address}
                                                                className="w-full"
                                                                value={this.state.card_state}
                                                                onChange={(event) => this.inputHandler(event, "card_state")}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className="flex-auto w-2/5 text-center">
                                                            <TextField
                                                                id="card_zip"
                                                                label="Zip"
                                                                type="tel"
                                                                disabled={this.state.is_billing_address}
                                                                className="w-full"
                                                                value={this.state.card_zip}
                                                                onChange={(event) => this.inputHandler(event, "card_zip")}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="flex flex-col my-2 items-start">
                                            <div>
                                                <div className="text-safeFormGray text-xs -ml-2">
                                                    <PrimaryCheckbox
                                                    size="small"
                                                    checked={this.state.is_primary_card}
                                                    onChange={(event) => { this.handleBillingCheckbox(event, 'is_primary_card') }}
                                                    inputProps={{ 'aria-label': 'billing address checkbox' }}/>
                                                    Set as primary card
                                                </div>
                                            </div>
                                            <div className="text-safeFormGray text-xs -ml-2 -mt-4">
                                                <PrimaryCheckbox
                                                    size="small"
                                                    checked={this.state.is_agree_tnc}
                                                    onChange={(event) => { this.handleBillingCheckbox(event, 'is_agree_tnc') }}
                                                    inputProps={{ 'aria-label': 't&c' }}/>
                                                    Agree to <span className="text-secondary cursor-pointer">
                                                terms and condition</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button
                                type="button"
                                disabled={(this.state.add_card_spinner === 'block')}
                                className="text-safeRed w-32 py-1 float-right font-sans font-semibold text-xs md:text-sm"
                                onClick={() => this.resetAddCardDialog() }>Back
                            </button>
                            <button
                                type="button"
                                disabled={(this.state.add_card_spinner === 'block')}
                                onClick={() => this.billingFormSubmit() }
                                className="bg-primary w-32 float-left text-white py-4 my-2 shadow-xl rounded font-sans text-xs md:text-sm">
                                {(this.state.add_card_spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Add Card' }
                            </button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={this.state.modalDPOpen}
                        onClose={() => this.setState({ modalDPOpen: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">You want to remove this card?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ modalDPOpen: false })} color="primary">
                                No
                            </Button>
                            <Button onClick={() => {
                                this.setState({ modalDPOpen: false })
                                this.callAction('remove_credit_card', this.state.remove_credit_card_id)
                            }} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <RemoveAutoreplenishModal data={{
                        open: this.state.modal_remove_auto_replenish,
                        token: this.props.state.token,
                        user_id: this.props.state.user_id,
                        handleClose: this.handleRemoveAutoreplenishClose,
                        setSnackMessage: this.handleSnackMessage
                    }} />

                    <UpdateExpiryDateModal data={{
                        open: this.state.modalCEOpen,
                        token: this.props.state.token,
                        user_id: this.props.state.user_id,
                        update_creditcard_id: this.state.update_creditcard_id,
                        old_exp_date: this.state.old_exp_date,
                        handleClose: this.handleChageExpClose,
                        setSnackMessage: this.handleSnackMessage
                    }} />
					<div>
						<div className="sm:p-10">
						    <div className='user-body-card px-6 md:px-12 py-10 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden bg-white'>

                                <div className="flex content-between">
                                    <div className="flex-1">
                                        <span className="float-left text-primary text-sm">Available Cards</span>
                                    </div>
                                    <div>
                                        <div className="float-none sm:float-right">
                                            <button type="button" onClick={() => {this.setState({modalCCOpen: true})}} className="bg-primary mx-2 text-white px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Add New Card</button>
                                            <button
                                            onClick={() => this.handleClickManageAutoReplenish((is_active_monthly_subscription && is_subscribed == 1 && user_monthly_subscription_id > 0))}
                                            type="button" className="bg-primary mx-2 text-white px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
                                                {((this.state.auto_replenish === 1) || (this.state.auto_replenish_card_id > 0)) ?
                                                'Modify Auto Replenish' : 'Setup Auto Replenish' }
                                            </button>
                                        </div>
                                    </div>
                                    {
                                        ((this.state.auto_replenish === 1) || (this.state.auto_replenish_card_id > 0)) &&
                                        <Tooltip title="Remove Auto Replenish Setup" placement="top">
                                            <button type="button" onClick={() => this.handleRemoveAutoreplenishOpen()} className="bg-safeRed text-white rounded px-1 md:px-2 font-sans font-semibold text-xs md:text-sm focus:outline-none">Remove Auto Replenish</button>
                                        </Tooltip>
                                    }
                                    {
                                        (is_active_monthly_subscription && is_subscribed == 1 && user_monthly_subscription_id > 0) ?
                                        <Tooltip title="Cancel Monthly Subscription" placement="top">
                                            <button type="button" onClick={() => this.setState({ openCancel: true })} className="bg-safeRed text-white rounded mx-2 px-1 md:px-2 font-sans font-semibold text-xs md:text-sm focus:outline-none">Cancel Monthly Subscription</button>
                                        </Tooltip> : null
                                    }
                                </div>

                                <div className="py-6">
                                    <div className="flex flex-wrap text-sm text-safeFormGray">
                                        <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                            <center><CircularProgress /></center>
                                        </div>
                                        {
                                            ((this.state.spinner === 'none') && (this.state.card_list.length === 0)) && 
                                            <p>No any cards available</p>
                                        }
                                        { this.listCards(this.state.card_list) }
                                    </div>
                                </div>

							</div>
						</div>
					</div>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value }),
        CustomerDetail: passData => { dispatch({ type: 'CustomerDetail', value: { ...passData } }) },
		UpdateCustomer: (value) => { dispatch({ type: 'UpdateCustomer', value }) },
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UserCards);
