import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import URLHelper from '../Helper/URLHelper'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import jwt_decode from 'jwt-decode'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	Margin: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
	},
	bootstrapInput: {
		backgroundColor: theme.palette.common.white,
		border: '1px solid #ced4da',
		fontSize: 16,
		width: 'auto',
		padding: '10px 12px',
		margin: '10px 12px',
		transition: theme.transitions.create(['border-color', 'box-shadow']),
		'&:focus': {
			borderRadius: 4,
			borderColor: '#673ab7',
			boxShadow: '0 0 0 0.0rem #673ab7',
		},
	},
	progress: {
		margin: theme.spacing(2),
	},
});


class AutoLogin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			phone: '',
			password: '',
			snack: false,
			snackMsg: '',
			spinner: 'none',
			BUTTONS: true,
		}
	}

	componentWillMount() {
		if (this.props.state.token !== '') {
			if(this.props.state.user_type == 'Admin'){
				window.location.href = "/AccountListing"
			}
			else{
				window.location.href = "/MainMenu"
			}
			
		}
	}

    componentDidMount() {
        console.log(this.props.match.params)
        console.log(this.props.match.params.adminToken)
        console.log(this.props.match.params.userPhone)

		if(
			(this.props.match.params.userPhone && this.props.match.params.userPhone != "")
			&& (this.props.match.params.adminToken && this.props.match.params.adminToken != "")
		)
		{
            this.AutoSignInCall()
		}

    }

	async AutoSignInCall() {
		if (navigator.onLine) {

            var Data = {
                phone_no: this.props.match.params.userPhone,
                admin_token: this.props.match.params.adminToken,
            }

            this.setState({ spinner: 'block', BUTTONS: false })

            // console.log("Auto Sign In")
            // console.log(Data)
            return fetch(URLHelper.AutoLogin, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none', BUTTONS: false })
                    if (res.status) {
                        console.log(res)
                        var token = res.data.token
                        var user_id = jwt_decode(token).user_id
                        var type = res.data.role
                        this.props.LoginSuccess(token, user_id, type)
                        if (type === 'Admin') {
                            this.props.history.push("/AccountList")
                        }
                        else {
                            this.props.history.push("/MainMenu")
                        }

                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({ spinner: 'none' })
                    this.setState({ snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
                })
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	render() {
		const { classes } = this.props;
		return (
			<div></div>
		);
	}
}

AutoLogin.propTypes = {
	styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		LoginSuccess: (token, user_id, type) => {
			var value = {
				token: token,
				user_id: user_id,
				user_type: type
			}
			dispatch({ type: 'CustomerLoginSuccess', value: value })
		},
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AutoLogin));
