import React, { useState, useEffect } from 'react';
import AdminHeader from './AdminHeader';
import { connect } from 'react-redux';
import { Paper, TableContainer, Table, TableBody, TableCell,
	TableHead, TableRow, TableFooter, CircularProgress } from '@material-ui/core';
import URLHelper from '../Helper/URLHelper';
import CustomWindowDimensions from './CustomWindowDimensions';
import SafeTechSnackbar from './SafeTechSnackbar';
import InfiniteScroll from "react-infinite-scroll-component";

function ActiveStationReport(props) {
    // console.log('ActiveStationReport component :', props)
    const { height: tableHeight } = CustomWindowDimensions();
    const [stationReportList, setStationReportList] = useState([])
    const [page, setPage] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    const [spinner, setSpinner] = useState('none')
    const [snackMsg, setSnackMsg] = useState('')
    const [snack, setSnack] = useState(false)
    const [snackVariant, setSnackVariant] = useState('error')

    const fetchActiveStationReport = async (isReset = false) => {
        if (navigator.onLine) {
            setSpinner('block')
            return fetch(URLHelper.GetActiveStationList + "/" + (isReset ? 1 : page) + "/" + 20, {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + props.state.token
                }
            }).then((Response) => {
                if (Response.status == 404) {
                    setSpinner('none')
                    // this.setState({ logSpinner: 'none', spinner: 'none', hasMoreLogData: false, userLogs: (this.state.filterEvent !== 'all') ? [] : this.state.userLogs})
                }
                else {
                    Response.json()
                        .then((res) => {
                            setSpinner('none')
                            if (res.status) {
                                // console.log("result promotion list : ", res)
                                const { data, total_records } = res;
                                let newProposalList = isReset ? [...data] : [ ...stationReportList, ...data]
                                setPage(isReset ? 2 : (page + 1))
                                setTotalRecords(total_records)
                                // console.log("total_records : ", total_records)
                                // console.log("newProposalList.length : ", newProposalList.length)
                                setHasMore(total_records > newProposalList.length)
                                setStationReportList(newProposalList)
                            }
                            else {
                                handleSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setSpinner('none')
                            handleSnackMessage("Something went wrong.", "error")
                        })
                }
            }).catch((error) => {
                console.log(error)
                setSpinner('none')
                handleSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            handleSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };

    useEffect(() => {
        // console.log('start API call')
        if (props.state.token === '' || props.state.user_type === 'User') {
            if(props.state.token === ''){
				window.location.href = "/"
			}else{
				window.location.href = "/MainMenu"
			}
		}
		else {
            fetchActiveStationReport()
		}
    },[]);

    const handleSnackMessage = ( snackMsg, snackVariant ) => {
        setSnack(true)
        setSnackMsg(snackMsg)
        setSnackVariant(snackVariant)
    }

    const hideSnackBar = () => {
		setSnack(false)
    }
    
	const fetchMoreData = () => {
        // console.log("fetchMoreData ::-----")
        if(stationReportList.length < totalRecords){
            fetchActiveStationReport()
        }
	}

    if (props.state.token !== '' && props.state.user_type === 'Admin') {
        return (
            <div className="App">
                <AdminHeader propsState={props.state} />
                <SafeTechSnackbar snackMsg={snackMsg} snack={snack} variant={snackVariant} closeSnack={hideSnackBar} />
                <div className="my-12 w-11/12 sm:w-9/12 rounded mx-auto">
                    <Paper elevation={3}>
                        <div className="mt-2 bg-white w-full justify-center">
                        {/* <div id="account-list-scroll" className="static mt-2 scrollable-table overflow-y-scroll rounded-b-lg bg-white w-full justify-center" style={{ height: '80vh' }}> */}
                            <div className="wrapper">
                                <InfiniteScroll
                                    className="scrollable-container"
                                    height={tableHeight}
                                    dataLength={stationReportList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                >
                                    <Table className="w-full rounded sticky-header-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Station Name</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Station IP Address</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Active</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Last Log In</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Last Log Out</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {stationReportList.length > 0 && stationReportList.map(
                                                ({ ip_address, is_active, last_login, last_logout, station_name }, index) => (
                                                <TableRow key={index}>
                                                    <TableCell className="px-4 text-xs md:text-sm" component="th" scope="row">{station_name}</TableCell>
                                                    <TableCell className="px-4 text-xs md:text-sm">{ip_address}</TableCell>
                                                    <TableCell className="px-4 text-xs md:text-sm">{is_active}</TableCell>
                                                    <TableCell className="px-4 text-xs md:text-sm">{(last_login !== '-' && last_login !== '') ? last_login : '-'}</TableCell>
                                                    <TableCell className="px-4 text-xs md:text-sm">{(last_logout !== '-' && last_logout !== '') ? last_logout : '-'}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                        {
                                            ((stationReportList.length < totalRecords) || totalRecords === 0) && ((tableHeight - (stationReportList.length * 40)) > 0) &&
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan="5" style={{ height: `${(tableHeight - ((stationReportList.length *  80)))}px` }}>
                                                    <div className="mt-2" style={{ width: '100%', alignItems: 'center', display: spinner }}>
                                                        <center><CircularProgress /></center>
                                                    </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        }
                                    </Table>
                                </InfiniteScroll>
                            </div>
                            {/* <InfiniteScroll
                            dataLength={stationReportList.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            // loader={<h4>Loading...</h4>}
                            scrollableTarget="account-list-scroll">
                                <TableContainer component={Paper}>
                                    <Table className="rounded" size="small" aria-label="logs table">
                                        <TableHead className="bg-primary rounded sticky-header">
                                            <TableRow>
                                                <TableCell><span className="text-white font-normal font-sans text-xs pl-4 md:text-sm">Station Name</span></TableCell>
                                                <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Station IP Address</span></TableCell>
                                                <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Active</span></TableCell>
                                                <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Last Log In</span></TableCell>
                                                <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Last Log Out</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {stationReportList.length > 0 && stationReportList.map(
                                            ({ ip_address, is_active, last_login, last_logout, station_name }, index) => (
                                            <TableRow key={index}>
                                                <TableCell component="th" scope="row">{station_name}</TableCell>
                                                <TableCell>{ip_address}</TableCell>
                                                <TableCell>{is_active}</TableCell>
                                                <TableCell>{(last_login !== '-' && last_login !== '') ? last_login : '-'}</TableCell>
                                                <TableCell>{(last_logout !== '-' && last_logout !== '') ? last_logout : '-'}</TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                        {
                                            ((stationReportList.length < totalRecords) || totalRecords === 0) && ((tableHeight - (stationReportList.length * 40)) > 0) &&
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan="5" style={{ height: `${(tableHeight - ((stationReportList.length *  40)))}px` }}>
                                                    <div className="mt-2" style={{ width: '100%', alignItems: 'center', display: spinner }}>
                                                        <center><CircularProgress /></center>
                                                    </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        }
                                    </Table>
                                </TableContainer>
                            </InfiniteScroll> */}
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }else{
        return (<div />);
    }
}


const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveStationReport);
