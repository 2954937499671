import React, { useState, useEffect } from 'react';
import AdminHeader from './AdminHeader';
import AppHelper from '../Helper/AppHelper';
import { connect } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Switch, Paper, TableContainer, Table, TableBody, TableCell, Button, TableFooter, CircularProgress,
	TableHead, TableRow, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import URLHelper from '../Helper/URLHelper';
import AddEditPromotionDialog from './AddEditPromotionDialog';
import moment from 'moment';
import SafeTechSnackbar from './SafeTechSnackbar';
import InfiniteScroll from "react-infinite-scroll-component";
import CustomWindowDimensions from './CustomWindowDimensions';
// import ListIcon from '@material-ui/icons/List';

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	list: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	signOutButton: {
		color: '#673ab7',
		float: 'right',
		margin: 20,
		textTransform: 'none',
		fontSize: '16px'
	},
	button: {
		color: '#673ab7',
		float: 'right',
		margin: 20,
		marginRight: 20,
		textTransform: 'none',
		fontSize: '16px'
	},
	toggleButton: {
		cursor: 'pointer',
		float: 'left',
		width: '1.5em',
		height: '1.5em',
		paddingTop: '2%'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
});

const GreenSwitch = withStyles({
	switchBase: {
        color: green[300],
		'&$checked': {
            color: green[500],
		},
		'&$checked + $track': {
			backgroundColor: green[500],
		},
	},
	checked: {},
	track: {},
})(Switch);

const initialPromotionDetail = {
    action_type: 'add',
    promotion_name: '',
    is_active: 1,
    promotion_from_date: new Date(),
    promotion_from_time: new Date(),
    promotion_till_date: moment().add(1,'d').format(),
    promotion_till_time: new Date(),
    // promotion_till_time: moment().add(1,'h').format(),
    promotion_id: 0,
    promotion_minutes: '',
    promotion_type: 'bonus'
};

function PromotionList(props) {
    // console.log('PromotionList component :', props)
    const { height: tableHeight } = CustomWindowDimensions();
    const [promotionList, setPromotionList] = useState([])
    const [modalPromoOpen, setModalPromoOpen] = useState(false)
    const [currentPromotionDetail, setCurrentPromotionDetail] = useState(initialPromotionDetail)
    const [activeInactivePromoDetail, setActiveInactivePromoDetail] = useState(null)
    const [deletePromoDetail, setDeletePromoDetail] = useState(null)
    const [page, setPage] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    const [spinner, setSpinner] = useState('none')
    const [snackMsg, setSnackMsg] = useState('')
    const [snack, setSnack] = useState(false)
    const [snackVariant, setSnackVariant] = useState('error')

    const [modalAIPOpen, setModalAIPOpen] = useState(false)
    const [modalDPOpen, setModalDPOpen] = useState(false)

    const fetchPromotionList = async (isReset = false) => {
        if (navigator.onLine) {
            setSpinner('block')
            return fetch(URLHelper.GetPromotions + "/" + (isReset ? 1 : page) + "/" + 20, {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + props.state.token
                }
            }).then((Response) => {
                if (Response.status == 404) {
                    setSpinner('none')
                    // this.setState({ logSpinner: 'none', spinner: 'none', hasMoreLogData: false, userLogs: (this.state.filterEvent !== 'all') ? [] : this.state.userLogs})
                }
                else {
                    Response.json()
                        .then((res) => {
                            setSpinner('none')
                            if (res.status) {
                                // console.log("result promotion list : ", res)
                                const { data, total_records } = res;
                                let newProposalList = isReset ? [...data] : [ ...promotionList, ...data]
                                setPage(isReset ? 2 : (page + 1))
                                setTotalRecords(total_records)
                                // console.log("total_records : ", total_records)
                                // console.log("newProposalList.length : ", newProposalList.length)
                                setHasMore(total_records > newProposalList.length)
                                setPromotionList(newProposalList)
                            }
                            else {
                                handleSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setSpinner('none')
                            handleSnackMessage("Something went wrong.", "error")
                        })
                }
            }).catch((error) => {
                console.log(error)
                setSpinner('none')
                handleSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            handleSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };

    useEffect(() => {
        // console.log('start API call')
        if (props.state.token === '' || props.state.user_type === 'User') {
            if(props.state.token === ''){
				window.location.href = "/"
			}else{
				window.location.href = "/MainMenu"
			}
		}
		else {
            fetchPromotionList()
		}
    },[]);

    const actionPromotion = async (action) => {
        if (navigator.onLine) {
            // this.setState({ spinner: 'block', BUTTONS: false })
            return fetch((action === 'active_inactive') ? URLHelper.ActiveInactivePromotion : URLHelper.DeletePromotion, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + props.state.token
                },
                body: JSON.stringify((action === 'active_inactive') ? activeInactivePromoDetail : deletePromoDetail)
            }).then((Response) => {
                if (Response.status == 404) {
                    // this.setState({ logSpinner: 'none', spinner: 'none', hasMoreLogData: false, userLogs: (this.state.filterEvent !== 'all') ? [] : this.state.userLogs})
                }
                else {
                    Response.json()
                        .then((res) => {
                            if (res.status) {
                                console.log("result log list : ", res)
                                handleSnackMessage(res.message, "success")
                                fetchPromotionList(true)
                                if(action === 'active_inactive'){
                                    resetActiveInactivePromotionDialog()
                                }else{
                                    resetDeletePromotionDialog()
                                }
                            }
                            else {
                                handleSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            handleSnackMessage("Something went wrong.", "error")
                        })
                }
            }).catch((error) => {
                handleSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            handleSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };

    const setActiveInactivePromotionDialog = (data) => {
        const { event, promotion_id } = data
        setActiveInactivePromoDetail({ is_active: event.target.checked ? 1 : 0, promotion_id })
        setModalAIPOpen(true)
    }

    const resetActiveInactivePromotionDialog = () => {
        setModalAIPOpen(false)
        setActiveInactivePromoDetail(null)
    }

    const setDeletePromoDialog = (data) => {
        setDeletePromoDetail(data)
        setModalDPOpen(true)
    }

    const resetDeletePromotionDialog = () => {
        setModalDPOpen(false)
        setDeletePromoDetail(null)
    }

    const handlePromoDialogOpen = (data) => {
        setModalPromoOpen(true)
        if(data){
            setCurrentPromotionDetail({ ...data, action_type: 'edit' })
        }else{
            setCurrentPromotionDetail(initialPromotionDetail)
        }
    }

    const handlePromoDialogClose = () => {
        // console.log('handleClose handlePromoDialogClose ::',initialPromotionDetail)
        setModalPromoOpen(false)
        setCurrentPromotionDetail(initialPromotionDetail)
        fetchPromotionList(true)
    }

    const handleSnackMessage = ( snackMsg, snackVariant ) => {
        setSnack(true)
        setSnackMsg(snackMsg)
        setSnackVariant(snackVariant)
    }

    const hideSnackBar = () => {
		setSnack(false)
    }

    
	const fetchMoreData = () => {
        // console.log("fetchMoreData ::-----")
        if(promotionList.length < totalRecords){
            fetchPromotionList()
        }
	}

    if (props.state.token !== '' && props.state.user_type === 'Admin') {
        return (
            <div className="App">
                <AdminHeader propsState={props.state} />
                <SafeTechSnackbar snackMsg={snackMsg} snack={snack} variant={snackVariant} closeSnack={hideSnackBar} />
                <AddEditPromotionDialog
                data={{
                    open: modalPromoOpen,
                    currentPromotionDetail: currentPromotionDetail,
                    token: props.state.token,
                    handleClose: handlePromoDialogClose,
                    setSnackMessage: handleSnackMessage
                }}/>
                <Dialog
                    open={modalAIPOpen}
                    onClose={() => resetActiveInactivePromotionDialog()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{`You want to ${activeInactivePromoDetail && activeInactivePromoDetail.is_active === 1 ? 'Activate' : 'Deactivate'} this promotion?`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => resetActiveInactivePromotionDialog()} color="primary">
                            No
                        </Button>
                        <Button onClick={() => {
                            actionPromotion('active_inactive')
                        }} color="primary" autoFocus>
                            Yes
                            </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={modalDPOpen}
                    onClose={() => resetDeletePromotionDialog()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{`You want to delete this promotion?`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => resetDeletePromotionDialog()} color="primary">
                            No
                        </Button>
                        <Button onClick={() => {
                            actionPromotion('delete')
                        }} color="primary" autoFocus>
                            Yes
                            </Button>
                    </DialogActions>
                </Dialog>
                <div className="my-12 w-11/12 sm:w-9/12 rounded mx-auto">
                    <Paper elevation={3}>
                        <div className="flex justify-between items-center">
                            <div className="text-center ml-2">
                                <span className="text-primary text-sm">Promotions List</span>
                            </div>
                            <div className="text-center mr-2">
                                <button
                                    type="button"
                                    className="bg-primary w-full text-white p-2 my-1 shadow-sm rounded font-sans text-xs md:text-sm"
                                    onClick={() => handlePromoDialogOpen(null)}
                                    >
                                    Add Promotion
                                </button>
                            </div>
                        </div>
                        <div className="mt-2 bg-white w-full justify-center">
                        {/* <div id="account-list-scroll" className="mt-2 overflow-y-scroll rounded-b-lg bg-white w-full justify-center" style={{ height: '80vh' }}> */}
                            <div className="wrapper">
                                <InfiniteScroll
                                    className="scrollable-container"
                                    height={tableHeight}
                                    dataLength={promotionList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                >
                                    <Table className="w-full sticky-header-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Name</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Type</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">From Date</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Till Date</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">From Time</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Till Time</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">Promotion Minutes</span></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><p className="text-white font-normal font-sans text-center text-xs md:text-sm">Active</p></TableCell>
                                                <TableCell className="bg-primary text-left px-4"><p className="text-white font-normal font-sans text-center text-xs md:text-sm">Action</p></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {promotionList.length > 0 && promotionList.map(
                                            ({
                                            is_active,
                                            promotion_from_date,
                                            promotion_from_time,
                                            promotion_id,
                                            promotion_minutes,
                                            promotion_name,
                                            promotion_till_date,
                                            promotion_till_time,
                                            promotion_type}, index) => (
                                            <TableRow key={promotion_id}>
                                                <TableCell>{promotion_name}</TableCell>
                                                <TableCell>{promotion_type}</TableCell>
                                                <TableCell>{moment(promotion_from_date).format('MM-DD-YYYY')}</TableCell>
                                                <TableCell>{moment(promotion_till_date).format('MM-DD-YYYY')}</TableCell>
                                                <TableCell>{moment(promotion_from_time, "HH:mm:ss").format("hh:mm a")}</TableCell>
                                                <TableCell>{moment(promotion_till_time, "HH:mm:ss").format("hh:mm a")}</TableCell>
                                                <TableCell>{promotion_minutes}</TableCell>
                                                <TableCell>
                                                    <Tooltip title={(is_active === 1) ? "Active" : "InActive"} placement="top">
                                                        <GreenSwitch
                                                            size="small"
                                                            checked={(is_active === 1) ? true : false}
                                                            onChange={event => setActiveInactivePromotionDialog({ event, promotion_id }) }
                                                            value={is_active}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Edit" placement="top">
                                                        <button className="mx-1 focus:outline-none"
                                                            onClick={() => handlePromoDialogOpen(promotionList[index]) }
                                                            >
                                                            <EditIcon fontSize="small" style={{ color: '#673ab7' }} />
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                        <button className="mx-1 focus:outline-none"
                                                            onClick={() => setDeletePromoDialog({promotion_id})}
                                                            >
                                                            <DeleteIcon fontSize="small" style={{ color: '#673ab7' }} />
                                                        </button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                        {
                                            ((promotionList.length < totalRecords) || totalRecords === 0) && ((tableHeight - (promotionList.length * 40)) > 0) &&
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan="5" style={{ height: `${(tableHeight - ((promotionList.length *  40)))}px` }}>
                                                    <div className="mt-2" style={{ width: '100%', alignItems: 'center', display: spinner }}>
                                                        <center><CircularProgress /></center>
                                                    </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        }
                                    </Table>
                                </InfiniteScroll>
                            </div>
                            {/* <InfiniteScroll
                            dataLength={promotionList.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            // loader={<h4>Loading...</h4>}
                            scrollableTarget="account-list-scroll">
                                <TableContainer component={Paper}>
                                <Table className="rounded" size="small" aria-label="logs table">
                                    <TableHead className="bg-primary rounded">
                                        <TableRow>
                                            <TableCell><span className="text-white font-normal font-sans text-xs pl-4 md:text-sm">Name</span></TableCell>
                                            <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Type</span></TableCell>
                                            <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">From Date</span></TableCell>
                                            <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Till Date</span></TableCell>
                                            <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">From Time</span></TableCell>
                                            <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Till Time</span></TableCell>
                                            <TableCell><span className="text-white font-normal font-sans text-xs md:text-sm">Promotion Minutes</span></TableCell>
                                            <TableCell><p className="text-white font-normal font-sans text-center text-xs md:text-sm">Active</p></TableCell>
                                            <TableCell><p className="text-white font-normal font-sans text-center text-xs md:text-sm">Action</p></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {promotionList.length > 0 && promotionList.map(
                                        ({
                                        is_active,
                                        promotion_from_date,
                                        promotion_from_time,
                                        promotion_id,
                                        promotion_minutes,
                                        promotion_name,
                                        promotion_till_date,
                                        promotion_till_time,
                                        promotion_type}, index) => (
                                        <TableRow key={promotion_id}>
                                            <TableCell component="th" scope="row">{promotion_name}</TableCell>
                                            <TableCell>{promotion_type}</TableCell>
                                            <TableCell>{moment(promotion_from_date).format('MM-DD-YYYY')}</TableCell>
                                            <TableCell>{moment(promotion_till_date).format('MM-DD-YYYY')}</TableCell>
                                            <TableCell>{moment(promotion_from_time, "HH:mm:ss").format("hh:mm a")}</TableCell>
                                            <TableCell>{moment(promotion_till_time, "HH:mm:ss").format("hh:mm a")}</TableCell>
                                            <TableCell>{promotion_minutes}</TableCell>
                                            <TableCell>
                                                <Tooltip title={(is_active === 1) ? "Active" : "InActive"} placement="top">
                                                    <GreenSwitch
                                                        size="small"
                                                        checked={(is_active === 1) ? true : false}
                                                        onChange={event => setActiveInactivePromotionDialog({ event, promotion_id }) }
                                                        value={is_active}
                                                    />
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Tooltip title="Edit" placement="top">
                                                    <button className="mx-1 focus:outline-none"
                                                        onClick={() => handlePromoDialogOpen(promotionList[index]) }
                                                        >
                                                        <EditIcon fontSize="small" style={{ color: '#673ab7' }} />
                                                    </button>
                                                </Tooltip>
                                                <Tooltip title="Delete" placement="top">
                                                    <button className="mx-1 focus:outline-none"
                                                        onClick={() => setDeletePromoDialog({promotion_id})}
                                                        >
                                                        <DeleteIcon fontSize="small" style={{ color: '#673ab7' }} />
                                                    </button>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                    {
                                        ((promotionList.length < totalRecords) || totalRecords === 0) && ((tableHeight - (promotionList.length * 40)) > 0) &&
                                        <TableFooter>
                                            <TableRow>
                                                <TableCell colSpan="5" style={{ height: `${(tableHeight - ((promotionList.length *  40)))}px` }}>
                                                <div className="mt-2" style={{ width: '100%', alignItems: 'center', display: spinner }}>
                                                    <center><CircularProgress /></center>
                                                </div>
                                                </TableCell>
                                            </TableRow>
                                        </TableFooter>
                                    }
                                </Table>
                            </TableContainer>
                            </InfiniteScroll> */}
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }else{
        return (<div />);
    }
}


const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateCustomer: (user_id, first_name, credit, address, city, state, zip) => {
			let value = {
				user_id: user_id,
				first_name: first_name,
				credit_balance: credit,
				address: address,
				city: city,
				state: state,
				zip: zip,
			}
			dispatch({ type: 'UpdateCustomer', value: value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PromotionList));
