import './css/App.css'
import React, { useState, useEffect } from 'react'
import progressPng from '../assets/progress.png'
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import MaskedInput from 'react-maskedinput'
import URLHelper from '../Helper/URLHelper'
import AppHelper from '../Helper/AppHelper'

function UpdateExpiryDateModal(props) {
    // console.log('UpdateExpiryDateModal ::',props.data)
    const { open, handleClose, setSnackMessage, old_exp_date, update_creditcard_id, user_id, token } = props.data
    const [newExpiryDate, setNewExpiryDate] = useState(old_exp_date)
    const [showSpinner, setShowSpinner] = useState('none')

    useEffect(() => {
        setNewExpiryDate(old_exp_date)
    }, [old_exp_date])

    const handleUpdate = async () => {
        if (navigator.onLine) {
            setShowSpinner('block')
            var Data = {
                user_id: user_id, 
                creditcard_id: update_creditcard_id,
                exp_date: AppHelper.changeDateFormat(newExpiryDate)
            }
            return fetch(URLHelper.updateCreditCardExpiry, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(Data)
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                if (Response.status == 404) {
                    setShowSpinner('none')
                    handleClose(false)
                } else {
                    Response.json().then((res) => {
                        setShowSpinner('none')
                        if (res.status) {
                            // console.log('RES ::',res)
                            setSnackMessage(res.message, "success")
                            handleClose(true)
                        }
                        else {
                            setSnackMessage(res.message, "error")
                            setShowSpinner('none')
                            handleClose(false)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        setSnackMessage("Something went wrong.", "error")
                        handleClose(false)
                    })
                }
            })
        }
        else {
            setSnackMessage("Internet Not Available. Try Again!!", "error")
            handleClose(false)
        }
    }

    return(
    <Dialog open={open}
    onClose={() => handleClose(false)}
    aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
            <Grid container>
                <div className="flex flex-col w-full">
                    <div className="text-center">
                        <p className="text-primary text-center">Update Current Expiry Date</p>
                    </div>
                </div>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <div className="w-64 text-center">
                <MaskedInput
                    mask="11/11"
                    id="new_expiry_date"
                    name="new_expiry_date"
                    className="pl-2 border-2 border-gray-300 text-black rounded"
                    style={{ height: '45px', fontSize: '14px' }}
                    value={newExpiryDate}
                    placeholder="MM/YY"
                    onChange={event => setNewExpiryDate(event.target.value)}
                />
            </div>
        </DialogContent>
        <DialogActions>
            <Grid container>
                <div className="flex flex-col w-full mx-4">
                    {/* opacity-50 cursor-not-allowed */}
                    <div className="text-center">
                        <button
                            type="button"
                            disabled={(showSpinner === 'block')}
                            className="bg-primary w-full text-white py-4 mb-2 shadow-xl rounded font-sans text-xs md:text-sm"
                            onClick={ev => handleUpdate()}
                            >
                            {(showSpinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Update Expiry Date'}
                        </button>
                    </div>
                    <div className="text-center">
                        <button
                            type="button"
                            disabled={(showSpinner === 'block')}
                            className="focus:outline-none text-safeRed w-full py-1 font-sans font-semibold text-xs md:text-sm"
                            onClick={() => handleClose(false) }
                            >Back
                        </button>
                    </div>
                </div>
            </Grid>
        </DialogActions>
    </Dialog>)
}

export default UpdateExpiryDateModal