import './css/App.css';
import React, { Component } from 'react';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid, Button, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import UserHeader from './UserHeader';
import AdminHeader from './AdminHeader';
import clsx from 'clsx';
import plansCardBgPng from '../assets/plans_card_bg.png';
import AppHelper from '../Helper/AppHelper';
import SafeTechSnackbar from './SafeTechSnackbar';

class Plans extends Component {
	
	constructor(props) {
		super(props)
		this.state = {
			plans: [],
			user_name: '',
			first_name: '',
			credit: '',
			min_credit: '',
			spinner: 'none',
			snackMsg: '',
			snackVariant: 'error',
			snack: false,
			BUTTONS: false,
			modalOpen: false,
			password: '',
			confirm_password: '',
			openCancel: false,
			openReactivate: false,
			monthlyPlan: null
		}
	}
	
	componentDidMount() {
		// console.log('componentDidMount on plan state : ',this.props);
		if (this.props.state.token === '') {
			window.location.href = "/"
		}
		else {
			if(!(this.props.location && this.props.location.state && this.props.location.state.PropsData)){
				if (this.props.state.token !== '') {
					if(this.props.state.user_type === 'Admin'){
						window.location.href = "/AccountList"
					}else{
						window.location.href = "/MainMenu"
					}
				}else{
					window.location.href = "/"
				}
			}
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true })
				this.props.UpdateMSG('')
			}
			this.fetchPlanListing();
			// this.fetchUserData()
		}
	}

	async fetchPlanListing() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetPlans + "/0", {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						let planList = [];
						if(res.data.length > 0)
						{
							res.data.forEach((element) => {
								// console.log('element ::::', element)
								planList.push(element)
							})
						}
						this.setState({ plans: planList })
					}
					else {
						this.setState({ spinner: 'none', plans: [], snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ spinner: 'none', plans: [], snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	async handleCancelMonthlySubscription() {
		if (navigator.onLine) {
            // console.log('this.props.location.state.PropsData ::::',this.props.location.state.PropsData);
            this.setState({ spinner: 'block', BUTTONS: false })
			const { user_monthly_subscription_id } = this.props.state
            return fetch(URLHelper.CancelMonthlySubscription, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify({ user_monthly_subscription_id })
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                if (Response.status == 404) {
                    this.setState({ spinner: 'none' })
                } else {
                    Response.json().then((res) => {
                        if (res.status) {
                            // console.log('RES ::',res)
							this.setState({ BUTTONS: true, spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'success' }, () => {
								setTimeout(() => {
									const { action_by } = this.props.location.state.PropsData;
									const { credit_balance } = this.props.state
									if(action_by === 'admin'){
										this.props.UpdateCustomer({ ...this.props.state, user_monthly_subscription_id: '', is_active_monthly_subscription: 0, is_subscribed: 0 })
										this.props.history.push("/AccountList");
									}else{
										this.props.CustomerDetail({ ...this.props.state, credit: credit_balance, user_monthly_subscription_id: '', is_active_monthly_subscription: 0, is_subscribed: 0 })
										this.props.history.push("/MainMenu");
									}
								}, 1500);
							})
                        }
                        else {
                            this.setState({ spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                        }
                    })
                        .catch((error) => {
                            console.log(error)
                            this.setState({ spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
                        })
                }
            })

        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
	}
    
	listItems = (plansArray, refillType, actionBy, userId) => {
		const { is_active_monthly_subscription, user_monthly_subscription_id, is_subscribed } = this.props.state
        return (
		plansArray.map(plan =>
			<div key={plan.plan_id} className="py-6 px-4 w-6/12 lg:w-4/12 xl:w-3/12" style={{ height: '206px' }}>
				<div className="plan-items-card bg-contain bg-top border py-2 my-2" style={{ backgroundImage: `url(${plansCardBgPng})` }}>
				{/* <div className={clsx(plan.is_unlimited ? (refillType === 'refill' ? 'opacity-100' : 'opacity-75 hidden') : 'opacity-100', "plan-items-card bg-contain bg-top border py-2 my-2")} style={{ backgroundImage: `url(${plansCardBgPng})` }}> */}
					{
						(plan.bonus > 0) &&
						<div className="-mt-6" style={{ marginLeft: '90%'}}>
							<button type="button"
								className="bg-safeYellow h-10 w-10 p-1 text-white rounded-lg font-sans text-xs">
								Save <span className="text-smXs">{(plan.bonus * 100 / plan.price) % 1 != 0 ? (plan.bonus * 100 / plan.price).toFixed(2) : (plan.bonus * 100 / plan.price)}%</span>
							</button>
						</div>
					}
					<div className={clsx((plan.bonus > 0) && '-mt-4', 'flex flex-col items-center px-8')}>
						{
							(['auto-refill','refill'].includes(refillType) && is_active_monthly_subscription && !plan.is_unlimited) ?
							<div className='pt-4 pb-12 text-sm md:text-lg w-full'>
								<table className='w-full'>
									<tbody>
									<tr>
										<td className='flex items-center justify-center'><span className='text-sm md:text-2xl text-white' style={{ font: 'Bold Roboto', letterSpacing: '0px',textShadow: '0px 2px 5px #00000029', opacity: 1 }}>B&W</span></td>
										<td><span className='flex justify-center text-sm md:text-2xl text-white' style={{ font: 'Bold Roboto', letterSpacing: '0px',textShadow: '0px 2px 5px #00000029', opacity: 1 }}>Color</span></td>
									</tr>
									<tr>
										<td className='pt-2 flex items-center justify-center'><span className='text-sm md:text-lg' style={{ font: 'Bold Roboto', letterSpacing: '0px',textShadow: '0px 2px 5px #00000029', opacity: 1 }}>{((plan.price + plan.bonus) / AppHelper.chargePerMinute).toFixed(0)}</span></td>
										<td className='pt-2'><span className='flex justify-center text-sm md:text-lg' style={{ font: 'Bold Roboto', letterSpacing: '0px',textShadow: '0px 2px 5px #00000029', opacity: 1 }}>{(((plan.price + plan.bonus) / AppHelper.chargePerMinute) / 7).toFixed(0)}</span></td>
									</tr>
									</tbody>
								</table>
							</div>
							:
							<span className="pt-4 pb-24 text-sm md:text-lg">{plan.is_unlimited ? 'Monthly Subscription' : this.convertTime(plan.price + plan.bonus)}</span>		
						}
						
						{
							plan.is_unlimited ?
							<>
								{
									(refillType === 'refill' && is_active_monthly_subscription && (is_subscribed == 1)) ?
									<Tooltip title="Cancel Monthly Subscription" placement="bottom">
										<button onClick={() => this.setState({ openCancel: true })} type="button" className="bg-safeRed w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
											<span className="text-sm">Cancel</span>
										</button>
									</Tooltip>
									:
									<button
										// disabled={refillType === 'refill' ? false : true} style={{ cursor: refillType === 'refill' ? 'pointer' : 'not-allowed' }}
										onClick={() => this.handlePurchaseUnlimitedPlan(plan)} type="button"
										className="bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base"
										// className={clsx(refillType === 'refill' ? 'cursor-pointer' : 'opacity-75 cursor-not-allowed', "bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base")}
										>
										<span className="text-xs">$ </span>{ plan.price.toFixed(2) }
									</button>
								}
							</>
							:
							<button  onClick={() => { this.props.history.push("/PaymentMethod",
							{ PropsData:
								{ type: refillType, is_unlimited: plan.is_unlimited, plan_id: plan.plan_id, price: plan.price, bonus: plan.bonus, actionBy, userId} }
								)}} type="button" className="bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
								<span className="text-xs">$ </span>{ plan.price.toFixed(2) }
							</button>
						}
					</div>
				</div>
			</div>
		));
	}

	convertTime = (amount) => {
        // console.log('convertTime ::',amount)
		const { is_active_monthly_subscription } = this.props.state
		const { type: purchaseType } = this.props.location.state.PropsData;

		if(['auto-refill','refill'].includes(purchaseType) && is_active_monthly_subscription){
			return 'print';
		}else{
			let givenSeconds = (amount / AppHelper.chargePerMinute) * 60;
			// console.log('givenSeconds ::',givenSeconds)
			let dateObj = new Date(givenSeconds * 1000); 
			let hours = dateObj.getUTCHours(); 
			let minutes = dateObj.getUTCMinutes();
			// let seconds = dateObj.getSeconds();

			if((amount / AppHelper.chargePerMinute) > 120){
				return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' Hours';
			}else{
				return (amount / AppHelper.chargePerMinute).toFixed(0) + ' Minutes'; 
			}
		}
    }

	getChoosePlanHeading = (purchaseType = '') => {
		const { is_active_monthly_subscription } = this.props.state
		if(purchaseType === 'auto-refill' && is_active_monthly_subscription){
			return 'Choose Prints Auto Replenish Amount'
		}else if(purchaseType === 'auto-refill' && !is_active_monthly_subscription) {
			return 'Choose Auto Replenish Amount'
		}else if(purchaseType === 'refill' && is_active_monthly_subscription){
			return 'Choose Prints Refill Amount'
		} else if(purchaseType === 'refill' && !is_active_monthly_subscription){
			return 'Choose Refill Amount'
		}else{
			return ''
		}
	}

	hideSnackBar = () => {
		this.setState({ snack: false })
	}

	handlePurchaseUnlimitedPlan = (plan) => {
		const { is_active_monthly_subscription, user_monthly_subscription_id, is_subscribed } = this.props.state
		if(is_active_monthly_subscription){
			this.setState({ openReactivate: true, monthlyPlan: plan })
		}else{
			const { type, action_by, user_id } = this.props.location.state.PropsData;
			const PropsData = {
				type, is_unlimited: plan.is_unlimited, plan_id: plan.plan_id, price: plan.price, bonus: plan.bonus, actionBy: action_by, userId: user_id
			}
			this.props.history.push("/PaymentMethod", { PropsData })
		}
	} 

	handleReactivateMonthlySubscription = () => {
		const { action_by, user_id } = this.props.location.state.PropsData;

		const PropsData = {
			type: 'auto-refill', is_unlimited: this.state.monthlyPlan.is_unlimited, plan_id: this.state.monthlyPlan.plan_id, price: this.state.monthlyPlan.price, bonus: this.state.monthlyPlan.bonus, actionBy: action_by, userId: user_id
		}
		this.props.history.push("/PaymentMethod", { PropsData })
	}

	render() {
		if (this.props.state.token !== '' && this.props.location && this.props.location.state && this.props.location.state.PropsData) {
			// console.log("this.props.location.state.PropsData ::",this.props.location.state.PropsData)
			const { type, action_by, user_id } = this.props.location.state.PropsData;
			return (
				<div>
					<SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />

					<Dialog
                        open={this.state.openCancel}
                        onClose={() => this.setState({ openCancel: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{`You want to cancel ${action_by === 'admin' ? 'this' : 'your'} monthly subscription?`}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ openCancel: false })} color="primary">
                                No
                            </Button>
                            <Button onClick={() => {
                                this.setState({ openCancel: false })
                                this.handleCancelMonthlySubscription()
                            }} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>

					<Dialog
                        open={this.state.openReactivate}
                        onClose={() => this.setState({ openReactivate: false, monthlyPlan: null })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Monthly subscription already running!</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
								You already have a running monthly subscription which is unsubscribed, you can reactivate your subscription by setting up auto replenish with this plan.
							</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <div className="text-center">
								<button
									type="button"
									className="w-20 text-primary py-3 text-base"
									onClick={() => this.setState({ openReactivate: false, monthlyPlan: null })}>Cancel
								</button>
							</div>
							<div className="text-center">
								<button
									type="button"
									className="bg-primary w-48 text-white py-3 shadow-xl rounded text-base"
									onClick={() => this.handleReactivateMonthlySubscription()}>Setup Auto Replenish
								</button>
							</div>
                        </DialogActions>
                    </Dialog>

					{
						(this.props.state.admin_first_name) ?
						<AdminHeader propsState={this.props.state} /> :
						<UserHeader propsState={this.props.state} />
					}
					<div className={clsx((this.props.state.admin_first_name !== '') ? "mt-12" : "mt-40"," w-11/12 sm:w-9/12 rounded mx-auto")}>
						<div className="text-center">
						<span className="text-safeFormGray text-base mx-auto">{this.getChoosePlanHeading(type)}</span>
						</div>
						<div className="py-2">
							<div className="flex flex-wrap text-lg font-sans text-white">
								<div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
									<center><CircularProgress /></center>
								</div>
								{ this.listItems(this.state.plans, type, action_by, user_id ) }
								{/* {
									(this.state.BUTTONS) &&
									<div key={'static'} className="py-6 px-4 w-6/12 lg:w-4/12 xl:w-3/12" style={{ height: '206px' }}>
										<div className="plan-items-card bg-contain bg-top border py-2 my-2" style={{backgroundImage: `url(${plansCardBgPng})` }}>
											<div className='flex flex-col items-center'>
												<span className="pt-4 pb-18 sm:pb-24 text-center text-sm md:text-lg">Monthly Subscription</span>
												<button type="button" className="bg-primary w-32 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-base">
													<span className="text-xs">Comming soon</span>
												</button>
											</div>
										</div>
									</div>
								} */}
							</div>
						</div>
					</div>
					
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		CustomerDetail: passData => { dispatch({ type: 'CustomerDetail', value: { ...passData } }) },
		UpdateCustomer: (value) => { dispatch({ type: 'UpdateCustomer', value }) },
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Plans);
