import React, { useState } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import progressPng from '../assets/progress.png';
import URLHelper from '../Helper/URLHelper';

function ChangePasswordDialog(props){
    const { open, user_id, token, handleClose, setSnackMessage } = props.data
    // console.log(props)
    const [spinner, setSpinner] = useState('none')
    const [data, setData] = useState({
        change_password: '',
        confirm_change_password: ''
    })

    const handleChangeData = (event, field) => {
        let value = event.target.value
        // console.log(value, field)
        setData({ ...data, [field]: value })
    }

    const getChangeCurrentPassword = async () => {
		if (data.change_password == '') {
            setSnackMessage("New Password is Required.", "error")
            // setSnackMessage("Change Password is Required.", "error")
		}
		else if (data.confirm_change_password == '') {
            setSnackMessage("Confirm Password is Required.", "error")
            // setSnackMessage("Confirm Change Password is Required.", "error")
		}
		else if (data.change_password !== data.confirm_change_password) {
            setSnackMessage("Confirm Password isn't matched!!.", "error")
            // setSnackMessage("Confirm Change Password isn't matched!!.", "error")
		}
		else {
			if (navigator.onLine) {
                let Data = {
                    user_id,
                    password: data.change_password,
                    confirm_change_password: 1
                }
                // console.log('Data ::',Data)
                setSpinner('block')
                return fetch(URLHelper.SetNewPassword, {
                    method: 'POST',
                    headers: {
                        'Accept': 'Application/json',
                        'Content-Type': 'application/json',
                        'authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(Data),
                }).then((Response) => Response.json())
                    .then((res) => {
                        if (res.status) {
                            // console.log(res)
                            setData({
                                change_password: '',
                                confirm_change_password: ''
                            })
                            setSpinner('none')
                            setSnackMessage(res.message, "success")
                            handleClose()
                        }
                        else {
                            setSpinner('none')
                            setSnackMessage(res.message, "error")
                        }
                    })
                    .catch((error) => {
                        console.log("Error==>" + error)
                        setSpinner('none')
                        setSnackMessage("Something went wrong.", "error")
                    })
            }
            else {
                setSnackMessage("Internet Not Available. Try Again!!", "error")
            }
        }
    }
    
    return(
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"><p className="text-primary text-center">Change Current Password</p></DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus={true}
                    id="change_password"
                    label="New Password"
                    type="password"
                    className="w-full"
                    value={data.change_password}
                    onChange={event => handleChangeData(event, 'change_password')}
                    variant="outlined"
                    inputProps={{
                        autoComplete: 'new-password',
                        form: {
                            autoComplete: 'off',
                        },
                    }}
                />
                <TextField
                    autoFocus={false}
                    id="confirm_change_password"
                    label="Confirm Password"
                    type="password"
                    margin="normal"
                    className="w-full"
                    value={data.confirm_change_password}
                    onChange={event => handleChangeData(event, 'confirm_change_password')}
                    variant="outlined"
                />
            </DialogContent>
            <DialogActions>
                <Grid container>
                        <div className="flex flex-col w-full mx-4">
                            {/* opacity-50 cursor-not-allowed */}
                            <div className="text-center">
                                <button
                                    type="button"
                                    disabled={(spinner === 'block')}
                                    className="bg-primary w-full text-white py-4 mb-2 shadow-xl rounded font-sans text-xs md:text-sm"
                                    onClick={() => getChangeCurrentPassword()}>
                                    {(spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Change Current Password'}
                                </button>
                            </div>
                            <div className="text-center">
                                <button
                                    type="button"
                                    disabled={(spinner === 'block')}
                                    className="text-safeRed w-full py-1 font-sans font-semibold text-xs md:text-sm"
                                    onClick={() => {
                                        handleClose()
                                    }}>Back
                                </button>
                            </div>
                        </div>
                    </Grid>
            </DialogActions>
        </Dialog>
    );
}
export default ChangePasswordDialog;