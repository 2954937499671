import React, { useEffect, useState } from 'react';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Radio, RadioGroup, FormControlLabel,
    // Tooltip, Switch
 } from '@material-ui/core';
import progressPng from '../assets/progress.png';
// import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import URLHelper from '../Helper/URLHelper';
import { loadOptions } from '../Helper/loadOptions';
import { AsyncPaginate } from "react-select-async-paginate";

// const LabelSwitch = withStyles({
// 	root: {
// 		width: "50px",
// 		height: "24px",
// 		padding: "0px"
// 	},
// 	switchBase: {
// 		color: "#818181",
// 		padding: "1px",
// 		"&$checked": {
// 		"& + $track": {
// 			backgroundColor: "#23bf58"
// 		}
// 		}
// 	},
// 	thumb: {
// 		color: "white",
// 		width: "20px",
// 		height: "20px",
// 		margin: "1px"
// 	},
// 	track: {
// 		borderRadius: "20px",
// 		backgroundColor: "#818181",
// 		opacity: "1 !important",
// 		"&:after, &:before": {
// 			color: "white",
// 			fontSize: "11px",
// 			position: "absolute",
// 			top: "6px"
// 		},
// 		"&:after": {
// 			content: "'On'",
// 			left: "8px"
// 		},
// 		"&:before": {
// 			content: "'Off'",
// 			right: "7px"
// 		}
// 	},
// 	checked: {
// 		color: "#23bf58 !important",
// 		transform: "translateX(26px) !important"
// 	}
// })(Switch);

function CouponSettingDialog(props){
    const { open, handleClose, setSnackMessage, token } = props.data
    const initialData = { action_type: 'setting', total_usage: 0, coupon_id: null, users: [], for_all_user: 'all', valid_for: '' }
    const [couponData, setCouponData] = useState(initialData)
    const [spinner, setSpinner] = useState('none')

    const addEditPromotion = async (fromData) => {
        if (navigator.onLine) {
            setSpinner('block')
            return fetch(URLHelper.AddEditCoupon, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(fromData)
            }).then((Response) => {
                if (Response.status === 404) {
                    setSpinner('none')
                }
                else {
                    Response.json()
                        .then((res) => {
                            setSpinner('none')
                            if (res.status) {
                                console.log("result log list : ", res)
                                setSnackMessage(res.message, "success")
                                resetModal(true)
                            }
                            else {
                                setSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setSnackMessage("Something went wrong.", "error")
                            setSpinner('none')
                        })
                }
            }).catch((error) => {
                console.log(error)
                setSpinner('none')
                setSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            setSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };

    const getCouponUsers = async () => {
        if(couponData.coupon_id){
            const { value } = couponData.coupon_id
            try {
                const response = await fetch(
                    URLHelper.GetCouponUsers + "/" + value,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'Application/json',
                            'Content-Type': 'application/json',
                            'authorization': 'Bearer ' + token
                        }
                    }
                );
                const res = await response.json()
                // console.log("res ::",res)
                const { data, status } = res
                if(status){
                    let selectedUsers = []
                    let prevIds = []
                    data.forEach(({ user_id, first_name, last_name }) => {
                        prevIds.push(user_id)
                        selectedUsers.push({ value: user_id, label: `${first_name} ${last_name}` })
                    })
                    // console.log("selectedUsers ::",selectedUsers)
                    // console.log("prevIds ::",prevIds)
                    setCouponData({ ...couponData, prev_users: prevIds, users: selectedUsers })
                }else{
                    setCouponData({ ...couponData, prev_users: [], users: [] })
                }
            } catch (error) {
                console.log("Error ::",error)
                setCouponData({ ...couponData, prev_users: [], users: [] })
            }
        }else{
            setCouponData({ ...couponData, prev_users: [], users: [] })
        }
    };

    useEffect(() => {
        getCouponUsers()
    },[couponData.coupon_id]);

    const inputHandler = (event, field) => {
        // console.log('inputHandler ::',event)
        let newData = {}
        if(field === 'users'){
            newData = { [field]: event }
        }
        else if(field === 'coupon_id'){
            const { total_usage, valid_for, coupon_code, start_date, end_date, for_all_user, discount, discount_type } = event
            newData = { [field]: event, for_all_user: for_all_user ? 'all' : 'specific', total_usage, valid_for, coupon_code, start_date, end_date, discount, discount_type }
        }else if(field === 'for_all_user'){
            newData = { [field]: event.target.value, valid_for: event.target.value == 'all' ? 'A' : 'E' }
        }else{
            newData = { [field]: event.target.value }
        }
        // console.log('inputHandler value ::',value)
        setCouponData({ ...couponData, ...newData })
    }

    const couponFormSubmit = () => {
        // console.log('couponData ::',couponData)
        let msg = "";
        const { coupon_id, total_usage, for_all_user, users, start_date, end_date, prev_users } = couponData
        let formData = {
            ...couponData,
            start_date: moment(start_date).format('YYYY-MM-DD'),
            end_date: moment(end_date).format('YYYY-MM-DD'),
        }
        if(coupon_id === ''){
            msg += "coupon code is required, "
        }
        if(total_usage === '' || (total_usage !== '' && total_usage == 0)){
            msg += "total usage is required, "
        }
        if(total_usage !== '' && total_usage < 0){
            msg += "total usage should be greater than zero, "
        }
        if(for_all_user === 'specific'){
            if(!users.length){
                msg += "users are required, "
            }
        }
        if(msg !== ""){
            console.log('msg::', msg)
            setSnackMessage(msg, "error")
        }else{
            // console.log('PRE passs ::',formData)
            if(formData.coupon_id){
                formData = { ...formData, coupon_id: formData.coupon_id.value }
            }
            if(formData.for_all_user){
                if(formData.for_all_user === 'all'){
                    formData = { ...formData, for_all_user: 1, users: '' }
                }else{
                    formData = { ...formData, for_all_user: 0 }
                }
            }
            if(formData.users && formData.users.length){
                let ids = []
                let remUsers = []
                formData.users.forEach(({ value }) => {
                    ids.push(value)
                });

                prev_users.forEach(value => {
                    if(!ids.includes(value)){
                        remUsers.push(value)
                    }
                });
                formData = { ...formData, users: ids.toString(), prev_users: remUsers.toString() }
            }else{
                formData = { ...formData, users: '', prev_users: prev_users.toString() }
            }
            // console.log('POST passs ::',formData)
            addEditPromotion(formData)
        }
    }

    const resetModal = (reload = false) => {
        setCouponData(initialData)
        handleClose(reload)
    }

    const getValiForString = () => {
        if(couponData.valid_for === 'N') return 'Coupon valid for New Users only!!'
        if(couponData.valid_for === 'A') return 'Coupon valid for All Users only!!'
        return ''
    }

    return(
        <Dialog
            open={open}
            maxWidth="xs"
            onClose={resetModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                <span className="text-safeFormGray text-base ml-4">Coupon Settings</span>
            </DialogTitle>
            <DialogContent style={{ minWidth: '440px', minHeight: '230px' }}>
                {/* overflow: 'hidden' */}
                <div className="px-4">
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <AsyncPaginate
                                debounceTimeout={300}
                                value={couponData.coupon_id}
                                className="z-10"
                                placeholder="Select Coupon"
                                loadOptions={loadOptions}
                                onChange={ev => inputHandler(ev, 'coupon_id')}
                                additional={{
                                    page: 1,
                                    url: URLHelper.GetCoupons,
                                    token: token,
                                    type: 'coupon'
                                }}
                            />
                        </Grid>
                            {couponData.coupon_id &&
                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="total_usage"
                                        label="Total usage"
                                        placeholder="Max number of usage"
                                        type="number"
                                        className="w-full"
                                        disabled={couponData.valid_for == 'N'}
                                        value={couponData.total_usage}
                                        onChange={event => inputHandler(event, "total_usage")}
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <div className='flex flex-col'>
                                    <div className="flex flex-row items-center w-full">
                                        <span className="text-safeGray mr-2">Allowed Users: </span>
                                        <RadioGroup row aria-labelledby="row-discount-type-label" name="discount-type" value={couponData.for_all_user} onChange={event => inputHandler(event,"for_all_user")} >
                                            <FormControlLabel value="all" disabled={couponData.valid_for == 'N'} control={<Radio />} label="All" />
                                            <FormControlLabel value="specific" disabled={couponData.valid_for == 'N'} control={<Radio />} label="Specific" />
                                        </RadioGroup>
                                    </div>
                                    {(couponData.valid_for == 'N') && <span className="text-sm text-safeGreen">{getValiForString()}</span>}
                                    </div>
                                </Grid>
                                {
                                ((couponData.for_all_user === 'specific') && (couponData.valid_for === 'E')) &&
                                <Grid item xs={12} sm={12} md={12} key={couponData.coupon_id ? couponData.coupon_id.value : 0}>
                                    {/* id='setting-dialog' */}
                                    <AsyncPaginate
                                        debounceTimeout={300}
                                        value={couponData.users}
                                        placeholder="Select Users"
                                        isMulti
                                        closeMenuOnSelect={false}
                                        loadOptions={loadOptions}
                                        onChange={ev => inputHandler(ev, 'users')}
                                        additional={{
                                            page: 1,
                                            url: URLHelper.GetUsers,
                                            token: token,
                                            type: 'user',
                                            coupon_id: couponData.coupon_id ? couponData.coupon_id.value : 0
                                        }}
                                        // menuPortalTarget={document.querySelector("setting-dialog")}
                                        // menuPortalTarget={document.body}
                                    />
                                </Grid>
                                }
                            </>
                            }
                    </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <button
                    type="button"
                    disabled={(spinner === 'block')}
                    className="text-safeRed w-32 py-1 float-right font-sans font-semibold text-xs md:text-sm"
                    onClick={() => resetModal() }>Back
                </button>
                <button
                    type="button"
                    disabled={(spinner === 'block')}
                    onClick={() => couponFormSubmit() }
                    className="bg-primary w-32 float-left text-white py-4 my-2 mr-8 shadow-xl rounded font-sans text-xs md:text-sm">
                    {(spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Save' }
                </button>
            </DialogActions>
        </Dialog>
    )
}

export default CouponSettingDialog;