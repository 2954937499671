import React, { useState, useEffect } from 'react';
import AdminHeader from './AdminHeader';
import AppHelper from '../Helper/AppHelper';
import { connect } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Switch, Paper, Table, TableBody, TableCell, Button, TableFooter, CircularProgress,
	TableHead, TableRow, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import URLHelper from '../Helper/URLHelper';
import AddEditCouponDialog from './AddEditCouponDialog';
import CouponSettingDialog from './CouponSettingDialog';
import moment from 'moment';
import SafeTechSnackbar from './SafeTechSnackbar';
import InfiniteScroll from "react-infinite-scroll-component";
import CustomWindowDimensions from './CustomWindowDimensions';

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	list: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: theme.spacing(0.5),
	},
	signOutButton: {
		color: '#673ab7',
		float: 'right',
		margin: 20,
		textTransform: 'none',
		fontSize: '16px'
	},
	button: {
		color: '#673ab7',
		float: 'right',
		margin: 20,
		marginRight: 20,
		textTransform: 'none',
		fontSize: '16px'
	},
	toggleButton: {
		cursor: 'pointer',
		float: 'left',
		width: '1.5em',
		height: '1.5em',
		paddingTop: '2%'
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
});

const GreenSwitch = withStyles({
	switchBase: {
        color: green[300],
		'&$checked': {
            color: green[500],
		},
		'&$checked + $track': {
			backgroundColor: green[500],
		},
	},
	checked: {},
	track: {},
})(Switch);

const initialCouponDetail = {
    action_type: 'add',
    coupon_code: '',
    start_date: null,
    // start_date: new Date(),
    end_date: null,
    // end_date: moment().add(1,'d').format(),
    coupon_id: 0,
    discount: '',
    total_usage: 1,
    for_all_user: 'all',
    discount_type: 'F',
    valid_for: 'A',
    users: [],
    prev_users: []
};

const SfTableCell = (props) => {
    return(
        <TableCell className="bg-primary text-left px-4"><span className="text-white font-normal font-sans text-xs md:text-sm">{props.headingText}</span></TableCell>
    )
}

function Coupons(props) {
    // console.log('Coupons component :', props)
    const { height: tableHeight } = CustomWindowDimensions();
    const [couponList, setCouponList] = useState([])
    const [modalCPOpen, setModalCPOpen] = useState(false)
    const [modalCSOpen, setModalCSPOpen] = useState(false)
    const [currentCouponDetail, setCurrentCouponDetail] = useState(initialCouponDetail)
    const [deletePromoDetail, setDeletePromoDetail] = useState(null)
    const [page, setPage] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [hasMore, setHasMore] = useState(false)

    const [spinner, setSpinner] = useState('none')
    const [snackMsg, setSnackMsg] = useState('')
    const [snack, setSnack] = useState(false)
    const [snackVariant, setSnackVariant] = useState('error')

    const [modalDCOpen, setModalDCOpen] = useState(false)

    const fetchCouponList = async (isReset = false) => {
        if (navigator.onLine) {
            setSpinner('block')
            return fetch(URLHelper.GetCoupons + "/" + (isReset ? 1 : page), {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + props.state.token
                }
            }).then((Response) => {
                if (Response.status == 404) {
                    setSpinner('none')
                    // this.setState({ logSpinner: 'none', spinner: 'none', hasMoreLogData: false, userLogs: (this.state.filterEvent !== 'all') ? [] : this.state.userLogs})
                }
                else {
                    Response.json()
                        .then((res) => {
                            setSpinner('none')
                            if (res.status) {
                                const { data, total_records } = res;
                                let newProposalList = isReset ? [...data] : [ ...couponList, ...data]
                                setPage(isReset ? 2 : (page + 1))
                                setTotalRecords(total_records)
                                setHasMore(total_records > newProposalList.length)
                                setCouponList(newProposalList)
                            }
                            else {
                                setCouponList([])
                                handleSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            setCouponList([])
                            setSpinner('none')
                            handleSnackMessage("Something went wrong.", "error")
                        })
                }
            }).catch((error) => {
                console.log(error)
                setSpinner('none')
                handleSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            handleSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };

    useEffect(() => {
        // console.log('start API call')
        if (props.state.token === '' || props.state.user_type === 'User') {
            if(props.state.token === ''){
				window.location.href = "/"
			}else{
				window.location.href = "/MainMenu"
			}
		}
		else {
            fetchCouponList()
		}
    },[]);

    const actionCoupon = async (action) => {
        if (navigator.onLine) {
            // this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(action, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + props.state.token
                },
                body: JSON.stringify(deletePromoDetail)
            }).then((Response) => {
                if (Response.status == 404) {
                    // this.setState({ logSpinner: 'none', spinner: 'none', hasMoreLogData: false, userLogs: (this.state.filterEvent !== 'all') ? [] : this.state.userLogs})
                }
                else {
                    Response.json()
                        .then((res) => {
                            if (res.status) {
                                console.log("result log list : ", res)
                                handleSnackMessage(res.message, "success")
                                fetchCouponList(true)
                                resetDeleteCouponDialog()
                            }
                            else {
                                handleSnackMessage(res.message, "error")
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            handleSnackMessage("Something went wrong.", "error")
                        })
                }
            }).catch((error) => {
                handleSnackMessage("Something went wrong.", "error")
            })
        }
        else {
            handleSnackMessage("Internet Not Available. Try Again!!", "error")
        }
    };

    const setDeletePromoDialog = (data) => {
        setDeletePromoDetail(data)
        setModalDCOpen(true)
    }

    const resetDeleteCouponDialog = () => {
        setModalDCOpen(false)
        setDeletePromoDetail(null)
    }

    const handleCSDialogOpen = (data) => {
        setModalCSPOpen(true)
    }
    const handleCSDialogClose = (reload) => {
        setModalCSPOpen(false)
        if(reload){
            fetchCouponList(reload)
        }
    }

    const handlePromoDialogOpen = (data) => {
        setModalCPOpen(true)
        if(data){
            setCurrentCouponDetail({ ...data, action_type: 'edit' })
        }else{
            setCurrentCouponDetail(initialCouponDetail)
        }
    }

    const handlePromoDialogClose = (reload) => {
        setModalCPOpen(false)
        setCurrentCouponDetail(initialCouponDetail)
        if(reload){
            fetchCouponList(reload)
        }
    }

    const handleSnackMessage = ( snackMsg, snackVariant ) => {
        setSnack(true)
        setSnackMsg(snackMsg)
        setSnackVariant(snackVariant)
    }

    const hideSnackBar = () => {
		setSnack(false)
    }

	const fetchMoreData = () => {
        // console.log("fetchMoreData ::-----")
        if(couponList.length < totalRecords){
            fetchCouponList()
        }
	}

	const getValidForString = (param) => {
        if(param === 'A') return 'All users'
        if(param === 'E') return 'Existing users'
        if(param === 'N') return 'New users'
        return ''
	}

    const getValidForColor = (param) => {
        if(param === 'A') return '#32CD32'
        if(param === 'E') return '#F1B64C'
        if(param === 'N') return '#673AB7'
        return ''
	}

	const getDiscountTypeString = (param) => {
        if(param === 'F') return 'Flat rate'
        if(param === 'P') return 'Percent rate'
        return ''
	}

	const getDiscountString = (type, data) => {
        if(type === 'F') return AppHelper.makeCurrencyFormat(data)
        if(type === 'P') return `${data}%`
        return ''
	}

    if (props.state.token !== '' && props.state.user_type === 'Admin') {
        return (
            <div className="App">
                <AdminHeader propsState={props.state} />
                <SafeTechSnackbar snackMsg={snackMsg} snack={snack} variant={snackVariant} closeSnack={hideSnackBar} />
                <AddEditCouponDialog
                data={{
                    open: modalCPOpen,
                    currentCouponDetail: currentCouponDetail,
                    initialCouponDetail: initialCouponDetail,
                    token: props.state.token,
                    handleClose: handlePromoDialogClose,
                    setSnackMessage: handleSnackMessage
                }}/>
                <CouponSettingDialog
                data={{
                    open: modalCSOpen,
                    token: props.state.token,
                    handleClose: handleCSDialogClose,
                    setSnackMessage: handleSnackMessage
                }}/>
                <Dialog
                    open={modalDCOpen}
                    onClose={() => resetDeleteCouponDialog()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{`You want to delete this coupon?`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => resetDeleteCouponDialog()} color="primary">
                            No
                        </Button>
                        <Button onClick={() => {
                            actionCoupon(URLHelper.DeleteCoupon)
                        }} color="primary" autoFocus>
                            Yes
                            </Button>
                    </DialogActions>
                </Dialog>
                <div className="my-12 w-11/12 sm:w-9/12 rounded mx-auto">
                    <Paper elevation={3}>
                        <div className="flex justify-between items-center">
                            <div className="text-center ml-2">
                                <span className="text-primary text-sm">Coupons List</span>
                            </div>
                            <div className='flex items-center'>
                                <Tooltip title="Settings" placement="top">
                                    <button className="mx-1 focus:outline-none" onClick={() => handleCSDialogOpen()}>
                                        <SettingsIcon fontSize="large" style={{ color: '#673ab7' }} />
                                    </button>
                                </Tooltip>
                                <div className="text-center mx-2">
                                    <button
                                        type="button"
                                        className="bg-primary w-full text-white p-2 my-1 shadow-sm rounded font-sans text-xs md:text-sm"
                                        onClick={() => handlePromoDialogOpen(null)}
                                        >
                                        Add Coupon
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="mt-2 bg-white w-full justify-center">
                        {/* <div id="account-list-scroll" className="mt-2 overflow-y-scroll rounded-b-lg bg-white w-full justify-center" style={{ height: '80vh' }}> */}
                            <div className="wrapper">
                                <InfiniteScroll
                                    className="scrollable-container"
                                    height={tableHeight}
                                    dataLength={couponList.length}
                                    next={fetchMoreData}
                                    hasMore={hasMore}
                                >
                                    <Table className="w-full sticky-header-table">
                                        <TableHead>
                                            <TableRow>
                                                <SfTableCell headingText="Coupon Code" />
                                                <SfTableCell headingText="Start Date" />
                                                <SfTableCell headingText="End Date" />
                                                <SfTableCell headingText="Discount Type" />
                                                <SfTableCell headingText="Discount" />
                                                <SfTableCell headingText="Total Usage" />
                                                <SfTableCell headingText="Valid For" />
                                                <SfTableCell headingText="Action" />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {couponList.length > 0 && couponList.map(
                                            ({ coupon_id, coupon_code, start_date, end_date, discount_type, discount, valid_for, total_usage }, index) => (
                                            <TableRow key={coupon_id}>
                                                <TableCell>{coupon_code}</TableCell>
                                                <TableCell>{moment(start_date).format('MM-DD-YYYY')}</TableCell>
                                                <TableCell>{moment(end_date).format('MM-DD-YYYY')}</TableCell>
                                                <TableCell><span className='rounded-full px-2 py-1' style={{ backgroundColor: (discount_type == 'F' ? '#32CD32' : '#673AB7'),  color: '#FFFFFF' }} >{getDiscountTypeString(discount_type)}</span></TableCell>
                                                <TableCell>{getDiscountString(discount_type, discount)}</TableCell>
                                                <TableCell>{total_usage}</TableCell>
                                                <TableCell><span className='rounded-full px-2 py-1' style={{ backgroundColor: getValidForColor(valid_for),  color: '#FFFFFF' }} >{getValidForString(valid_for)}</span></TableCell>
                                                <TableCell>
                                                    <Tooltip title="Edit" placement="top">
                                                        <button className="mx-1 focus:outline-none"
                                                            onClick={() => handlePromoDialogOpen(couponList[index]) }
                                                            >
                                                            <EditIcon fontSize="small" style={{ color: '#673ab7' }} />
                                                        </button>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                        <button className="mx-1 focus:outline-none"
                                                            onClick={() => setDeletePromoDialog({coupon_id})}
                                                            >
                                                            <DeleteIcon fontSize="small" style={{ color: '#673ab7' }} />
                                                        </button>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                        {
                                            ((couponList.length < totalRecords) || totalRecords === 0) && ((tableHeight - (couponList.length * 40)) > 0) &&
                                            <TableFooter>
                                                <TableRow>
                                                    <TableCell colSpan="5" style={{ height: `${(tableHeight - ((couponList.length *  40)))}px` }}>
                                                    <div className="mt-2" style={{ width: '100%', alignItems: 'center', display: spinner }}>
                                                        <center><CircularProgress /></center>
                                                    </div>
                                                    </TableCell>
                                                </TableRow>
                                            </TableFooter>
                                        }
                                    </Table>
                                </InfiniteScroll>
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }else{
        return (<div />);
    }
}


const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateCustomer: (user_id, first_name, credit, address, city, state, zip) => {
			let value = {
				user_id: user_id,
				first_name: first_name,
				credit_balance: credit,
				address: address,
				city: city,
				state: state,
				zip: zip,
			}
			dispatch({ type: 'UpdateCustomer', value: value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Coupons));
