export const loadOptions = async (search, prevOptions, additional) => {
    // console.log("additional ::", additional)
    let { url, token, page, type, coupon_id } = additional
    const failedRes = {
        options: [],
        hasMore: false,
        additional: {
            page: 1,
            url,
            search,
            token,
            type,
            coupon_id
        },
    };
    try {
        if(coupon_id){
            url = url + "/" + page + "?search=" + search + "&coupon_id=" + coupon_id
        }else{
            url = url + "/" + page + "?search=" + search
        }
        const response = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                }
            }
        );
        const res = await response.json()
        // console.log("res ::",res)
        const { data, status, total_records } = res
        if(status){
            let newData = [];
            if (data.length > 0) {
                if(type === 'user'){
                    data.forEach((element) => {
                        newData.push({ value: element.user_id, label: `${element.first_name} ${element.last_name}` })
                    })
                }
                if(type === 'coupon'){
                    data.forEach((element) => {
                        newData.push({ value: element.coupon_id, label: element.coupon_code, ...element })
                    })
                }
            }
            const finalData = [...prevOptions, ...newData]
            const hasMore = (finalData.length < total_records);
            const slicedOptions = finalData.slice(
                prevOptions.length,
                prevOptions.length + 20
            );
            return {
                options: slicedOptions,
                hasMore,
                additional: {
                    page: page + 1,
                    url,
                    search,
                    token,
                    type,
                    coupon_id
                },
            };
        }else{
            return failedRes;
        }
    } catch (error) {
        console.log("Error ::",error)
        return failedRes;
    }
}