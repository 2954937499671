import React from 'react';
import { Button, InputAdornment } from '@material-ui/core';

function ApplyButton(props) {
    const { handleApplyCode, couponCode } = props
    return(
        <InputAdornment position="end">
            {couponCode != '' && <Button size="small" className="cursor-pointer" style={{ color: '#673ab7', outline: 'none' }} onClick={() => handleApplyCode()} >APPLY</Button>}
        </InputAdornment>
    )
}

export default ApplyButton