import './css/App.css';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    Grid, Button, Tooltip,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, TextField
} from '@material-ui/core';
import dollar from '../assets/dollar.png'
import { connect } from 'react-redux'
import MaskedInput from 'react-maskedinput';
import URLHelper from '../Helper/URLHelper'
// // import Header from './Header';
import AppHelper from '../Helper/AppHelper';
import UserHeader from './UserHeader';
import AdminHeader from './AdminHeader';
import correctPng from '../assets/correct.png';
import filledCorrectPng from '../assets/filled_correct.png';
import progressPng from '../assets/progress.png';
import visaCardPng from '../assets/credit_card_visa.png';
import americanExpressCardPng from '../assets/credit_card_american_express.png';
import masterCardPng from '../assets/credit_card_master.png';
import successPng from '../assets/success.png';
import CreditCardIcon from '@material-ui/icons/CreditCard';
// import CreateIcon from '@material-ui/icons/Create';
import SafeTechSnackbar from './SafeTechSnackbar';
import UpdateExpiryDateModal from './UpdateExpiryDateModal';
import ApplyButton from './ApplyButton';
import clsx from 'clsx';


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        margin: '10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
});

const PrimaryCheckbox = withStyles({
    root: {
        '&$checked': {
            color: '#273359',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

class PaymentMethod extends Component {
    constructor(props) {
        super(props)
        this.state = {
            card_list: [],
            spinner: 'none',
            payment_spinner: 'none',
            card_list_spinner: 'none',
            snackMsg: '',
            snack: false,
            BUTTONS: true,
            open: false,
            PropsData: '',
            signoutButtonDisabled: false,
            payUsingCreditBalance: false,
            modalCCOpen: false,
            name_on_card: '',
            card_number: '',
            expiry_date: '',
            cvv: '',
            card_street_number: '',
            card_address: '',
            card_city: '',
            card_state: '',
            card_zip: '',
            is_billing_address: false,
            is_primary_card: false,
            is_save_to_account: false,
            is_agree_tnc: false,
            is_agree_tnc_payment: false,
            default_creditcard_id: 0,
            selected_card_cvv: '',
            modalRefillDone: false,
            currentBalance: '',
            currentPrintingBalance: '',
            modalAutoRefillDone: false,
            modalAutoRefillData: null,
            password: '',
            modalOpen: false,
            paymentAdminToken: '',
            snackVariant: 'error',
            modalCEOpen: false,
            update_creditcard_id: 0,
            old_exp_date: '',
            coupon_code: '',
            coupon_code_process: false,
            invalid_coupon_code: false,
            coupon_data: null
        }

        this.setTextInputRef = element => {
            // console.log('setTextInputRef :::', element)
            if (element !== null) {
                // console.log('element not null.....')
                this.addressReference = React.createRef();
            }
        };
    }

    componentDidUpdate(prevProps) {
        // console.log('PaymentMethod componentDidUpdate ::', prevProps);
        if (this.addressReference) {
            const autocompleteFormField = this.addressReference.current;
            // console.log('autocompleteFormField 2::====>', autocompleteFormField);
            const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
                types: ['address'],
            });
            // console.log('autocomplete ::====>', autocomplete);
            window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
                this.setState({ card_street_number: '', card_address: '', card_zip: '', card_state: '', card_city: '' })
                const place = autocomplete.getPlace();
                // console.log('PLACE ===>', place);
                if (place.address_components != null) {
                    place.address_components.map((value, index) => {
                        if (value.types.includes('street_number')) {
                            this.setState({ card_street_number: value.long_name })
                        }
                        if (value.types.includes('route')) {
                            this.setState({ card_address: this.state.card_street_number + " " + value.long_name })
                        }
                        if (value.types.includes('postal_code')) {
                            this.setState({ card_zip: value.long_name })
                        }
                        if (value.types.includes('administrative_area_level_1')) {
                            this.setState({ card_state: value.long_name })
                        }
                        if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
                            this.setState({ card_city: value.long_name })
                        }
                    })
                }
            });
        }
    }

    componentWillMount() {
        // console.log("Payment Method Mount this.props =>:",this.props)
        // console.log("Payment Method Mount :",this.props.location.state.PropsData)
        if (this.props.state.token === '') {
            window.location.href = "/"
        } else if (this.props.location.state !== undefined) {
            this.setState({ PropsData: this.props.location.state.PropsData })
            if (this.state.PropsData.type == 'auto' && this.state.PropsData.plan_price != 0 && this.state.PropsData.plan_id == 0) {
                this.props.history.push("/MainMenu")
            } else {
                this.fetchCardListing()
            }
        } else if (this.state.PropsData.type !== 'item_refill' && this.props.state.user_type === 'User') {
            window.location.href = "/MainMenu"
        } else {
            window.location.href = "/MainMenu"
        }
    }

    async fetchCardListing() {
        if (navigator.onLine) {
            // console.log('this.props.location.state.PropsData ::::',this.props.location.state.PropsData);
            this.setState({ card_list_spinner: 'block', BUTTONS: false })
            var Data = {
                start: 0,
                user_id: this.props.state.user_id
            }
            return fetch(URLHelper.GetCardListing, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify(Data)
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                if (Response.status == 404) {
                    this.setState({ card_list_spinner: 'none' })
                } else {
                    Response.json().then((res) => {
                        this.setState({ card_list_spinner: 'none', BUTTONS: true })
                        if (res.status) {
                            // console.log('RES ::',res)
                            let CARDS = []
                            for (var i = 0; i < res.data.length; i++) {
                                CARDS.push({ default_creditcard_id: res.data[i].default_creditcard_id, card_type: res.data[i].card_type, creditcard_id: res.data[i].creditcard_id, last_four: res.data[i].last_four, exp_date: res.data[i].exp_date, name: res.data[i].name })
                            }
                            this.setState({ card_list: CARDS, default_creditcard_id: CARDS[0].default_creditcard_id })
                        }
                        else {
                            this.setState({ card_list_spinner: 'none', snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                        }
                    })
                        .catch((error) => {
                            console.log(error)
                            this.setState({ card_list_spinner: 'none', snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
                        })
                }
            })

        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

    ChoosePreviousCard = (card) => {
        var Data = this.state.PropsData
        Data.creditcard_id = card.creditcard_id
        Data.CVV = card.CVV
        Data.last_four = card.last_four
        if (this.state.PropsData.type === 'refill' || this.state.PropsData.type === 'item_refill') {
            this.props.history.push("/EnterExistCCInfo/", { PropsData: Data })
        }
        else {
            this.props.history.push("/ConfirmExistCCInfo/", { PropsData: Data })
        }
    }

    creditBalanceSelected = () => {
        if (this.props.state.user_type == 'Admin') {
            this.setState({ payUsingCreditBalance: true },
                () => this.DoPayment())
        } else {
            this.props.history.push("/AdminApprovalPayment", { PropsData: this.state.PropsData })
        }
    }

    cashSelected = () => {
        if (this.props.state.user_type == 'Admin') {
            this.DoPayment()
        } else {
            this.props.history.push("/AdminApprovalPayment", { PropsData: this.state.PropsData })
        }
    }

    async DoPayment() {
        if (navigator.onLine) {
            var Data = {}
            Data.admin_token = this.props.state.token;
            Data.creditcard_id = 0;
            Data.cvv = '';
            Data.user_id = this.props.state.user_id
                (this.state.PropsData.type === 'item_refill') ? Data.item_id = this.props.location.state.PropsData.item_id : Data.plan_id = this.props.location.state.PropsData.plan_id;
            Data.custom_amount = this.props.location.state.PropsData.plan_id == 0 ? this.props.location.state.PropsData.plan_price : (this.props.location.state.PropsData.item_id < 0 ? this.props.location.state.PropsData.price : 0);
            Data.method = this.state.payUsingCreditBalance ? 'creditbalance' : 'cash';
            Data.transactiontype = 'refill';
            Data.purchase_type = this.state.PropsData.type === 'item_refill' ? 'item' : 'plan';
            // apply coupon
            // if(this.state.coupon_data){
            //     Data.coupon_id = this.state.coupon_data.coupon_id
            //     Data.coupon_code = ''
            // }else if(this.state.coupon_code){
            //     Data.coupon_code = this.state.coupon_code
            //     Data.coupon_id = ''
            // }else{
            //     Data.coupon_code = ''
            //     Data.coupon_id = ''
            // }
            // --------------------------
            // console.log("Payment")
            // console.log(Data)
            this.setState({ spinner: 'block', BUTTONS: false, signoutButtonDisabled: true })
            return fetch(URLHelper.DoPayment, {
                method: 'POST',
                headers: {
                    'authorization': 'Bearer ' + this.props.state.token,
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    if (res.status) {
                        // console.log("cash trans resp ::", res);
                        var credit;
                        if (res.data.credit_balance != null && res.data.credit_balance != 'undefined') {
                            //credit= (res.data.credit_balance / AppHelper.chargePerMinute).toFixed(2)
                            credit = res.data.credit_balance
                        }
                        else {
                            credit = 0.00
                        }
                        this.props.UpdateCredit(credit)
                        this.props.history.push("/RefillSuccess", { PropsData: credit, PurchaseType: Data.purchase_type, PurchaseData: res.data })
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, signoutButtonDisabled: false, payUsingCreditBalance: false })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', spinner: 'none', BUTTONS: true, signoutButtonDisabled: false, payUsingCreditBalance: false })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error', payUsingCreditBalance: false })
        }
    }

    listCards = (cardsArray, type) => {
        // console.log('cards ::', cardsArray)
        return (
            cardsArray.map((data, index) =>
                <div key={data.creditcard_id} className="p-2 w-6/12 lg:w-4/12">
                    <div className={clsx((data.creditcard_id === this.state.default_creditcard_id) && "border border-safeGreen", "md:w-mdCw md:h-mdCh rounded p-2 my-2 credit-card-list")}>
                        <div className="grid grid-rows-3 grid-flow-col items-center">
                            <div className='flex flex-row text-safeFormGray justify-between'>
                                <span className="text-smXs sm:text-xs ml-0 sm:ml-2 px-0">{data.name}</span>
                                <span className="pl-4 ml-2 sm:ml-6 text-xs lg:text-sm">{data.last_four}</span>
                            </div>
                            <div className="py-0 sm:py-2">
                                {
                                    (data.creditcard_id === this.state.default_creditcard_id) ?
                                        <img className="h-4 cursor-pointer" src={filledCorrectPng} alt="filled-correct-png" /> :
                                        <img className="h-4 selected-card-png" onClick={() => this.setState({ default_creditcard_id: data.creditcard_id, selected_card_cvv: '' })} src={correctPng} alt="correct-png" />
                                    // <RadioButtonUncheckedIcon fontSize="small" className="text-black bg-gray-200"/>
                                }
                            </div>
                            <div className='flex flex-row text-safeFormGray items-center justify-between'>
                                {
                                    (data.creditcard_id === this.state.default_creditcard_id) && ((type !== '' && type === 'refill')) &&
                                    <input type="tel" value={this.state.selected_card_cvv}
                                        onChange={(event) => this.inputHandler(event, "selected_card_cvv")} placeholder="cvv" className="border-2 rounded w-12 pl-2" />
                                }
                                <Tooltip title='Click to edit' placement="bottom">
                                    {/* <span onClick={ev => this.handleChageExpOpen({ card_id: data.creditcard_id, expDate: 1021 + '' }) } className="cursor-pointer mx-auto -mr-4 px-3 sm:px-4 text-smXs sm:text-xs">{`10/21`}</span> */}
                                    <span onClick={ev => this.handleChageExpOpen({ card_id: data.creditcard_id, expDate: data.exp_date })} className="cursor-pointer mx-auto -mr-4 px-3 sm:px-4 text-smXs sm:text-xs">{AppHelper.makeDateFormat(data.exp_date)}</span>
                                    {/* <CreateIcon fontSize="small" style={{ height: '14px' }} /> */}
                                </Tooltip>
                                {
                                    (typeof data.card_type !== 'undefined' && (data.card_type === 'visa' || data.card_type === 'mastercard' || data.card_type === 'american-express')) ?
                                        <img className="h-5 ml-0 sm:ml-4" src={this.getCardCreditCardImg(data.card_type)} alt="card-png" /> :
                                        <CreditCardIcon className="h-5 ml-0 sm:ml-4" color="primary" />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            ));
    }

    inputHandler = (event, inputID) => {
        let value = event.target.value
        if (inputID == "search_address") {
            this.setState({ search_address: value })
        }
        else if (inputID == "card_address") {
            this.setState({ card_address: value })
        }
        else if (inputID == "card_city") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ card_city: value })
            }
            else {
                //console.log("Not City")
            }
        }
        else if (inputID == "card_state") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ card_state: value })
            }
            else {
                //console.log("Not State")
            }
        }
        else if (inputID == "card_zip") {
            if (value.length < 7) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ card_zip: value })
                }
                else {
                    //console.log("Not Zip")
                }
            }
        }
        else if (inputID == "name_on_card") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ name_on_card: value })
            }
        }
        else if (inputID == "card_number") {
            if (value.length < 17) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ card_number: value })
                }
            }
        }
        else if (inputID == "expiry_date") {
            this.setState({ expiry_date: value })
        }
        else if (inputID == "cvv") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ cvv: value })
                }
            }
        }
        else if (inputID == "selected_card_cvv") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ selected_card_cvv: value })
                }
            }
        }
        else if (inputID == "coupon_code") {
            this.setState({ coupon_code: value })
            if(value == ''){
				this.setState({ coupon_data: null, invalid_coupon_code: false })
			}
        }
    }

    getCardCreditCardImg(card_type) {
        if (card_type === 'visa') {
            return visaCardPng;
        } else if (card_type === 'mastercard') {
            return masterCardPng;
        } else if (card_type === 'american-express') {
            return americanExpressCardPng;
        }
    }

    convertTime = (amount) => {
        // console.log('convertTime ::',amount)
        let givenSeconds = (amount / AppHelper.chargePerMinute) * 60;
        // console.log('givenSeconds ::',givenSeconds)
        let dateObj = new Date(givenSeconds * 1000);
        let hours = dateObj.getUTCHours();
        let minutes = dateObj.getUTCMinutes();
        let seconds = dateObj.getSeconds();

        if ((amount / AppHelper.chargePerMinute) > 120) {
            return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' Hours';
        } else {
            return (amount / AppHelper.chargePerMinute).toFixed(0) + ' Minutes';
        }
    }

    handleBillingCheckbox = (event, inputField) => {
        // console.log('handleBillingCheckbox :', event.target.checked, inputField)
        if (inputField === 'is_billing_address') {
            this.setBillingAddress(event.target.checked)
        } else {
            this.setState({ [inputField]: event.target.checked })
        }
    }

    setBillingAddress = (action) => {
        const { address, city, state, zip } = this.props.state;
        if (action) {
            this.setState({
                is_billing_address: true, card_address: address,
                card_city: city, card_state: state, card_zip: zip
            })
        } else {
            this.setState({
                is_billing_address: false, card_address: '',
                card_city: '', card_state: '', card_zip: ''
            })
        }
    }

    resetAddCardDialog = () => {
        this.setState({
            modalCCOpen: false,
            is_billing_address: false, card_address: '', card_city: '', card_state: '', card_zip: '',
            is_primary_card: false, is_agree_tnc: false, name_on_card: '',
            card_number: '', expiry_date: '', cvv: ''
        });
    }

    billingFormSubmit = () => {
        console.log("called billingFormSubmit...")
        if (this.state.is_agree_tnc) {
            this.addCreditCard()
        } else {
            this.setState({ snackMsg: 'Please read and accept our terms and condition!!', snack: true, snackVariant: 'error' })
        }
    }

    addCreditCard = () => {
        if (navigator.onLine) {
            this.setState({ spinner: 'block' })
            let Data = {
                user_id: this.props.state.user_id,
                name: this.state.name_on_card,
                cc_num: this.state.card_number,
                exp_date: AppHelper.changeDateFormat(this.state.expiry_date),
                cvv: this.state.cvv,
                address: this.state.card_address,
                city: this.state.card_city,
                state: this.state.card_state,
                zip: this.state.card_zip,
                set_default_card: this.state.is_primary_card
            }
            return fetch(URLHelper.AddCreditCard, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    // console.log("Response==>\n" + JSON.stringify(res))
                    if (res.status) {
                        // console.log("TRUE", res)
                        this.fetchCardListing();
                        this.resetAddCardDialog();
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'success', spinner: 'none' })
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', spinner: 'none' })
                    }
                })
                .catch((error) => {
                    console.log("Error==>" + error)
                    this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', spinner: 'none' })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

    refillAction = (action_name, is_cash = false) => {
        let msg = '';
        if (!is_cash) {
            if (action_name === 'refill') {
                if (this.state.default_creditcard_id === 0) {
                    msg += 'Please select a card for transaction,';
                }
                if (this.state.selected_card_cvv === '') {
                    msg += ' Please enter card cvv,';
                }
                if (!this.state.is_agree_tnc_payment) {
                    msg += ' Please read and accept our terms and condition';
                }
            } else {
                if (this.state.default_creditcard_id === 0) {
                    msg += 'Please select a card for transaction,';
                }
                if (!this.state.is_agree_tnc_payment) {
                    msg += ' Please read and accept our terms and condition';
                }
            }
        }
        if (msg !== '') {
            this.setState({ snackMsg: msg, snack: true, snackVariant: 'error' })
        } else {
            if (navigator.onLine) {
                var Data = {}
                if (action_name === 'refill') {
                    Data.creditcard_id = (is_cash) ? 0 : this.state.default_creditcard_id;
                    Data.cvv = (action_name === 'refill') ? ((is_cash) ? '' : this.state.selected_card_cvv) : '';
                    Data.user_id = this.props.state.user_id;
                    Data.plan_id = this.state.PropsData.plan_id;
                    Data.method = (is_cash) ? 'cash' : 'creditcard';
                    Data.transactiontype = action_name;
                    Data.purchase_type = 'plan';
                    Data.custom_amount = 0;
                    // apply coupon
                    if(this.state.coupon_data){
                        Data.coupon_id = (this.state.coupon_code && this.state.coupon_code != '') ? this.state.coupon_data.coupon_id : ''
                        Data.coupon_code = ''
                    }else if(this.state.coupon_code){
                        Data.coupon_code = this.state.coupon_code
                        Data.coupon_id = ''
                    }else{
                        Data.coupon_code = ''
                        Data.coupon_id = ''
                    }
                } else {
                    Data.auto_replenish_creditcard_id = this.state.default_creditcard_id;
                    Data.cvv = '';
                    Data.user_id = this.props.state.user_id;
                    Data.auto_replenish_plan_id = this.state.PropsData.plan_id;
                    Data.method = 'creditcard';
                    Data.transactiontype = 'auto';
                }
                if (is_cash) {
                    Data.admin_token = (this.props.state.user_type === 'Admin') ? this.props.state.token : this.state.paymentAdminToken;
                }
                this.setState({ payment_spinner: 'block', BUTTONS: false, signoutButtonDisabled: true })

                return fetch((action_name === 'refill') ? URLHelper.DoPayment : URLHelper.ConfirmAutoReplenish, {
                    method: 'POST',
                    headers: {
                        'authorization': 'Bearer ' + this.props.state.token,
                        'Accept': 'Application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(Data),
                }).then((Response) => Response.json())
                    .then((res) => {
                        this.setState({ payment_spinner: 'none' })
                        if (res.status) {
                            // console.log("action_name ::", action_name);
                            const { data } = res;
                            if (action_name === 'refill') {
                                var credit;
                                if (res.data.credit_balance != null && res.data.credit_balance != 'undefined') {
                                    credit = res.data.credit_balance
                                }
                                else {
                                    credit = 0.00
                                }
                                this.setState({ modalRefillDone: true, currentBalance: credit, currentPrintingBalance: res.data.printing_balance })
                                this.handleClose();
                            } else {
                                this.setState({ modalAutoRefillDone: true, modalAutoRefillData: data })
                            }
                        }
                        else {
                            this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true, signoutButtonDisabled: false, payUsingCreditBalance: false })
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                        this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', payment_spinner: 'none', BUTTONS: true, signoutButtonDisabled: false, payUsingCreditBalance: false })
                    })
            }
            else {
                this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error', payUsingCreditBalance: false })
            }
        }
    }

    handleCloseRefillDialog = () => {
        this.props.UpdateCredit(this.state.currentBalance);
        this.setState({ modalRefillDone: false, currentBalance: '' });
    }

    handleCloseAutoRefillDialog = () => {
        this.setState({ modalAutoRefillDone: false });
    }

    handleClose = () => {
        this.setState({ paymentAdminToken: '', modalOpen: false, password: '' });
    }

    handleChageExpOpen = (data) => {
        const { card_id, expDate } = data
        this.setState({ modalCEOpen: true, update_creditcard_id: card_id, old_exp_date: expDate })
    }

    handleChageExpClose = updateState => {
        this.setState({ modalCEOpen: false, update_creditcard_id: 0, old_exp_date: '' })
        if (updateState) this.fetchCardListing();
    }

    handleSnackMessage = (snackMsg, snackVariant) => {
        this.setState({ snackMsg, snack: true, snackVariant })
    }

    getCouponAmount = (couponData) => {
        const { price = 0 } = this.props.location.state.PropsData;
        if(couponData){
            const { discount_type, discount } = couponData;
            if(discount_type === 'F') return discount;
            if(discount_type === 'P') return (price * discount) / 100;
        }else{
            return price;
        }
	}

    // getDiscountString = () => {
    //     if(this.state.coupon_data){
    //         const { discount_type, discount } = this.state.coupon_data
    //         if(discount_type === 'F') return  `You will get flat ${AppHelper.makeCurrencyFormat(discount)} discount!`
    //         if(discount_type === 'P') return  `You will get ${discount}% discount!`
    //     }else{
    //         return ''
    //     }
	// }

    getTotalDue = (couponData, currencyFormat = true) => {
        // console.log("couponData ::",couponData)
        const { price = 0 } = this.props.location.state.PropsData;
        if(couponData){
            const { discount_type, discount } = couponData
            if(discount_type === 'F') return currencyFormat ? AppHelper.makeCurrencyFormat(price - discount) : (price - discount);
            if(discount_type === 'P') return currencyFormat ? AppHelper.makeCurrencyFormat(price - ((price * discount) / 100)) : (price - ((price * discount) / 100));
        }else{
            return AppHelper.makeCurrencyFormat(price)
        }
	}

    // getDiscountAmount = () => {
    //     const { price = 0 } = this.props.location.state.PropsData;
    //     if(this.state.coupon_data){
    //         const { discount_type, discount } = this.state.coupon_data
    //         if(discount_type === 'F') return AppHelper.makeCurrencyFormat(discount)
    //         if(discount_type === 'P') return AppHelper.makeCurrencyFormat(((price * discount) / 100))
    //     }else{
    //         return AppHelper.makeCurrencyFormat(0)
    //     }
	// }

    formSubmit = () => {
        if (this.state.password !== '') {
            this.VerifyAdmin()
        } else {
            this.setState({ snackMsg: "Admin Password is Required.", snack: true, snackVariant: 'error' })
        }
    }

    async VerifyAdmin() {
        if (navigator.onLine) {
            var Data = {
                password: this.state.password
            }
            //console.log(Data)
            this.setState({ spinner: 'block', BUTTONS: false })
            return fetch(URLHelper.GetAdminToken, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none', BUTTONS: false })
                    if (res.status) {
                        // console.log("ADMIN APPROVAL :", res.data);
                        this.setState({ paymentAdminToken: res.data }, () => { this.refillAction('refill', true); })
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({ spinner: 'none', BUTTONS: true })
                    this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

    hideSnackBar = () => {
        this.setState({ snack: false })
    }

    async handleApplyCode() {
        if (navigator.onLine) {
            var Data = {}
            Data.user_id = this.props.state.user_id
            Data.coupon_code = this.state.coupon_code
            this.setState({ payment_spinner: 'block', coupon_code_process: true, signoutButtonDisabled: true })
            return fetch(URLHelper.ValidateCouponCode, {
                method: 'POST',
                headers: {
                    'authorization': 'Bearer ' + this.props.state.token,
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ payment_spinner: 'none', coupon_code_process: false })
                    if (res.status) {
                        // console.log("ValidateCouponCode resp ::", res);
                        const { data, message } = res
                        if(this.getTotalDue({...data, message }, false) > 0){
                            this.setState({ coupon_data: {...data, message }, invalid_coupon_code: false })
                        }else{
                            this.setState({ invalid_coupon_code: true })
                        }
                    }
                    else {
                        this.setState({ coupon_data: null, invalid_coupon_code: false, snackMsg: res.message, snack: true, snackVariant: 'error', signoutButtonDisabled: false, payUsingCreditBalance: false })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({ snackMsg: "Something went wrong.", coupon_data: null, invalid_coupon_code: false, snack: true, snackVariant: 'error', spinner: 'none', BUTTONS: true, signoutButtonDisabled: false, payUsingCreditBalance: false })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", coupon_data: null, invalid_coupon_code: false, snack: true, snackVariant: 'error', payUsingCreditBalance: false })
        }
    }

    handleCashTransaction = () => {
        if(this.state.PropsData.type === 'refill'){
            // if(this.state.PropsData.is_unlimited){
            //     this.setState({ snackMsg: "Cash transaction isn't allowed for Monthly subscription plan", snack: true, snackVariant: 'error' })
            //     return;
            // }
            if (!this.state.is_agree_tnc_payment) {
                this.setState({ snackMsg: 'Please read and accept our terms and condition', snack: true, snackVariant: 'error' })
            }else{
                if(this.props.state.user_type === 'Admin'){
                    this.refillAction('refill', true)
                }else{
                    this.setState({ modalOpen: true })
                }
            }
        }else{
            this.setState({ snackMsg: 'This action is unavailable', snack: true, snackVariant: 'error' })
        }
    }

    render() {
        const { type = '', price = 0, bonus = 0 } = this.props.location.state.PropsData;
        if (this.props.state.token !== '') {
            return (
                <div className="App">
                    <Dialog
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">You want to pay using credit balance?</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ open: false })} color="primary">
                                No
                            </Button>
                            <Button onClick={() => {
                                this.setState({ open: false })
                                this.creditBalanceSelected()
                            }} color="primary" autoFocus>
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {/* <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.snack}
                        autoHideDuration={1500}
                        onClose={() => this.setState({ snack: false })}>
                        <SnackbarContent
                            className="error"
                            aria-describedby="client-snackbar"
                            message={this.state.snackMsg}
                        />
                    </Snackbar> */}
                    <SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />
                    <Dialog open={this.state.modalRefillDone} onClose={this.handleCloseRefillDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            <Grid container>
                                <div className="flex flex-col w-full">
                                    <div className="text-center">
                                        <img className="h-8 mx-auto" src={successPng} alt="success-png" />
                                    </div>
                                </div>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <div className="font-semibold w-64">
                                <span className="text-primary">Payment processed successfully!</span>
                                {/* <p className="text-primary text-xs text-center px-2">Your new available {this.props.state.is_subscribed ? ('printing balance is $' +  this.state.currentPrintingBalance) : ('credit is $' + this.state.currentBalance) }</p> */}
                                <p className="text-primary text-xs text-center px-2">Your new available {this.props.state.is_active_monthly_subscription ? ('printing balance is $' +  this.state.currentPrintingBalance) : ('credit is $' + this.state.currentBalance) }</p>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Grid container>
                                <div className="flex flex-col w-full">
                                    {this.props.state.is_active_monthly_subscription ? null :
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="bg-primary w-48 text-white py-3 shadow-xl rounded text-base"
                                            onClick={() => {
                                                const { action_by, user_id } = this.props.location.state.PropsData;
                                                this.handleCloseRefillDialog();
                                                this.props.history.push("/Plans",
                                                    { PropsData: { type: 'auto-refill', action_by, user_id } });
                                            }}>Setup Auto Replenish</button>
                                    </div>}
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="w-20 text-primary py-3 text-base"
                                            onClick={() => {
                                                const { action_by } = this.props.location.state.PropsData;
                                                this.handleCloseRefillDialog();
                                                if (action_by === 'admin') {
                                                    this.props.history.push("/AccountList");
                                                } else {
                                                    this.props.history.push("/MainMenu");
                                                }
                                            }}>Done
                                        </button>
                                    </div>
                                </div>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    <Dialog open={this.state.modalAutoRefillDone} onClose={this.handleCloseAutoRefillDialog} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">
                            <Grid container>
                                <div className="flex flex-col w-full">
                                    <div className="text-center">
                                        <img className="h-8 mx-auto" src={successPng} alt="success-png" />
                                    </div>
                                </div>
                            </Grid>
                        </DialogTitle>
                        <DialogContent>
                            <div className="w-64">
                                <p className="font-semibold text-primary text-center">Auto Replenish ${this.props.location.state.PropsData.price} is setup!</p>
                                <p className="text-safeFormGray text-center text-smXs">
                                    {
                                        (this.state.modalAutoRefillData && this.state.modalAutoRefillData.is_active) ?
                                        `This plan will be renewed in 1 month, your subscription due date is ${this.state.modalAutoRefillData.end_date}.`
                                        : 'This amount will be charged automatically when your account time is 20 minutes.'
                                    }
                                </p>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Grid container>
                                <div className="flex flex-col w-full">
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            className="bg-primary w-24 text-white py-3 my-2 shadow-xl rounded text-base"
                                            onClick={() => {
                                                const { action_by } = this.props.location.state.PropsData;
                                                this.handleCloseAutoRefillDialog();
                                                if (action_by === 'admin') {
                                                    this.props.history.push("/AccountList");
                                                } else {
                                                    this.props.history.push("/MainMenu");
                                                }
                                            }}>Close
                                        </button>
                                    </div>
                                </div>
                            </Grid>
                        </DialogActions>
                    </Dialog>
                    {/* <Button disabled={this.state.signoutButtonDisabled} style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
                        {
                            this.props.Logout()
                            window.location.href = "/"
                        }
                    }}>Sign Out</Button> */}

                    {/* <Header propsState={this.props.state} /> */}
                    <Dialog
                        ref={this.setTextInputRef}
                        fullWidth={true}
                        maxWidth={'xs'}
                        open={this.state.modalCCOpen}
                        onClose={() => this.setState({ modalCCOpen: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        style={{ zIndex: 30 }}>
                        <DialogTitle id="alert-dialog-title">
                            <span className="text-safeFormGray text-base ml-6">Credit Card Info</span>
                        </DialogTitle>
                        <DialogContent>
                            <div className="px-6 mt-2">
                                <div className="flex-col -mt-4">
                                    <div className="text-center">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextField
                                                    id="name_on_card"
                                                    label="Name on card"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.name_on_card}
                                                    onChange={(event) => this.inputHandler(event, "name_on_card")}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <TextField
                                                    id="card_number"
                                                    label="Card Number"
                                                    type="text"
                                                    className="w-full"
                                                    value={this.state.card_number}
                                                    onChange={(event) => this.inputHandler(event, "card_number")}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div className="flex">
                                                    <div className="flex-initial w-1/2 text-center pr-4">
                                                        <MaskedInput
                                                            mask="11/11"
                                                            id="expiry_date"
                                                            name="expiry_date"
                                                            className="w-full pl-2 border-2 border-gray-300 text-black rounded"
                                                            style={{ height: '55px', fontSize: '16px' }}
                                                            value={this.state.expiry_date}
                                                            placeholder="MM/YY"
                                                            onChange={(event) => this.inputHandler(event, "expiry_date")}
                                                        />
                                                    </div>
                                                    <div className="flex-initial w-1/2 text-center">
                                                        <TextField
                                                            type="tel"
                                                            id="cvv"
                                                            label="CVV"
                                                            className="w-full"
                                                            value={this.state.cvv}
                                                            onChange={(event) => this.inputHandler(event, "cvv")}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="text-center mt-4">
                                        <div className="flex flex-col my-2 items-start">
                                            <div>
                                                <div className="text-safeFormGray text-xs -ml-2">
                                                    <PrimaryCheckbox
                                                        size="small"
                                                        checked={this.state.is_billing_address}
                                                        onChange={(event) => { (this.state.edit_button_disabled) ? this.setState({ snackMsg: "You haven't selected any user!!", }) : this.handleBillingCheckbox(event, 'is_billing_address') }}
                                                        inputProps={{ 'aria-label': 'billing address checkbox' }} />
                                                    Billing address same as customer info
                                                </div>
                                            </div>
                                            <div className="text-safeFormGray text-sm">
                                                <span className="text-safeFormGray text-base my-2">Billing Address</span>
                                            </div>
                                        </div>
                                        <div>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <input
                                                        id="location"
                                                        placeholder="Search Address"
                                                        type="text"
                                                        disabled={this.state.is_billing_address}
                                                        margin="normal"
                                                        onChange={(event) => this.inputHandler(event, "search_address")}
                                                        style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
                                                        ref={this.addressReference}
                                                        variant="outlined"
                                                        className="w-full border-solid border border-gray-500 rounded"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <TextField
                                                        id="card_address"
                                                        label="Address"
                                                        type="text"
                                                        disabled={this.state.is_billing_address}
                                                        className="w-full"
                                                        value={this.state.card_address}
                                                        onChange={(event) => this.inputHandler(event, "card_address")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <div className="flex">
                                                        <div className="flex-auto w-2/5 pr-2 text-center">
                                                            <TextField
                                                                id="card_city"
                                                                label="City"
                                                                type="text"
                                                                disabled={this.state.is_billing_address}
                                                                className="w-full"
                                                                value={this.state.card_city}
                                                                onChange={(event) => this.inputHandler(event, "card_city")}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className="flex-auto w-1/5 pr-2 text-center">
                                                            <TextField
                                                                id="card_state"
                                                                label="State"
                                                                type="text"
                                                                disabled={this.state.is_billing_address}
                                                                className="w-full"
                                                                value={this.state.card_state}
                                                                onChange={(event) => this.inputHandler(event, "card_state")}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                        <div className="flex-auto w-2/5 text-center">
                                                            <TextField
                                                                id="card_zip"
                                                                label="Zip"
                                                                type="tel"
                                                                disabled={this.state.is_billing_address}
                                                                className="w-full"
                                                                value={this.state.card_zip}
                                                                onChange={(event) => this.inputHandler(event, "card_zip")}
                                                                variant="outlined"
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="flex flex-col my-2 items-start">
                                            {/* <div>
                                                <div className="text-safeFormGray text-xs -ml-2">
                                                    <PrimaryCheckbox
                                                    size="small"
                                                    checked={this.state.is_save_to_account}
                                                    onChange={(event) => { this.handleBillingCheckbox(event, 'is_save_to_account') }}
                                                    inputProps={{ 'aria-label': 'save to account' }}/>
                                                    Save card to account
                                                </div>
                                            </div> */}
                                            <div>
                                                <div className="text-safeFormGray text-xs -ml-2">
                                                    <PrimaryCheckbox
                                                        size="small"
                                                        checked={this.state.is_primary_card}
                                                        onChange={(event) => { this.handleBillingCheckbox(event, 'is_primary_card') }}
                                                        inputProps={{ 'aria-label': 'billing address checkbox' }} />
                                                    Set as primary card
                                                </div>
                                            </div>
                                            <div className="text-safeFormGray text-xs -ml-2 -mt-4">
                                                <PrimaryCheckbox
                                                    size="small"
                                                    checked={this.state.is_agree_tnc}
                                                    onChange={(event) => { this.handleBillingCheckbox(event, 'is_agree_tnc') }}
                                                    inputProps={{ 'aria-label': 't&c' }} />
                                                Agree to <span className="text-secondary cursor-pointer">
                                                    terms and condition</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <button
                                type="button"
                                disabled={(this.state.spinner === 'block')}
                                className="text-safeRed w-32 py-1 float-right font-sans font-semibold text-xs md:text-sm"
                                onClick={() => this.resetAddCardDialog()}>Back
                            </button>
                            <button
                                type="button"
                                disabled={(this.state.spinner === 'block')}
                                onClick={() => this.billingFormSubmit()}
                                className="bg-primary w-32 float-left text-white py-4 my-2 shadow-xl rounded font-sans text-xs md:text-sm">
                                {(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Add Card'}
                            </button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title"><p className="text-primary text-center">Admin Approval</p></DialogTitle>
                        <DialogContent>
                            <TextField
                                autoFocus={true}
                                id="password"
                                label="Admin Password"
                                type="password"
                                className="w-full"
                                value={this.state.password}
                                onChange={(event) => this.setState({ password: event.target.value })}
                                // onKeyDown={(event) => {
                                //     if(event.key === 'Enter'){
                                //         this.formSubmit()
                                //     }
                                // }}
                                variant="outlined"
                                InputProps={{
                                    autoComplete: 'new-password',
                                    form: {
                                        autoComplete: 'off',
                                    },
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Grid container>
                                <div className="flex flex-col w-full mx-4">
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            disabled={(this.state.spinner === 'block')}
                                            className="bg-primary w-full text-white py-4 mb-2 shadow-xl rounded font-sans text-xs md:text-sm"
                                            onClick={() => this.formSubmit()}>
                                            {(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Continue'}
                                        </button>
                                    </div>
                                    <div className="text-center">
                                        <button
                                            type="button"
                                            disabled={(this.state.spinner === 'block')}
                                            className="text-safeRed w-full py-1 font-sans font-semibold text-xs md:text-sm"
                                            onClick={() => {
                                                this.handleClose();
                                            }}>Back
                                        </button>
                                    </div>
                                </div>
                            </Grid>
                        </DialogActions>
                    </Dialog>

                    <UpdateExpiryDateModal data={{
                        open: this.state.modalCEOpen,
                        token: this.props.state.token,
                        user_id: this.props.state.user_id,
                        update_creditcard_id: this.state.update_creditcard_id,
                        old_exp_date: this.state.old_exp_date,
                        handleClose: this.handleChageExpClose,
                        setSnackMessage: this.handleSnackMessage
                    }} />
                    {
                        (this.props.state.admin_first_name) ?
                            <AdminHeader propsState={this.props.state} /> :
                            <UserHeader propsState={this.props.state} />
                    }

                    <div className={clsx((this.props.state.admin_first_name !== '') ? "mt-12" : "mt-32", "flex flex-wrap font-sans-roboto xl:flex-no-wrap w-10/12 mx-auto")}>
                        <div className="container flex-initial w-full xl:w-2/6 m-2">
                            <div className="flex flex-col">
                                <div className="px-4 py-2 mx-2 mt-10">
                                    <span className="text-primary tracking-normal text-3xl font-medium">
                                        {(type !== '' ? (type === 'refill' ? 'Checkout' : 'Auto Replenish') : 'Checkout')}
                                    </span>
                                </div>
                                <div className="px-4 py-2 mx-2 mt-10">
                                    <span className="text-safeFormGray tracking-normal text-lg font-medium">
                                        {
                                            this.state.coupon_data ?
                                            <>
                                                Time Added: {this.state.PropsData.is_unlimited ? "Unlimited" : this.convertTime(this.getCouponAmount(this.state.coupon_data) + price + bonus)}
                                            </>
                                            :
                                            <>
                                                Time Added: {this.state.PropsData.is_unlimited ? "Unlimited" : this.convertTime(price + bonus)}
                                            </>
                                        }
                                    </span>
                                    {
                                        !this.state.PropsData.is_unlimited &&
                                        <p className="text-safeFormGray text-smXs py-2 pr-32">
                                            * Prints are charged at 1 minute per B&W print or 7 minutes per color print.
                                        </p>
                                    }
                                    <hr className="mt-2 border border-safeGray opacity-25" style={{ width: '307px' }} />
                                </div>

                                <div className="px-4 py-2 m-2">
                                    
                                    <div className='flex flex-col' style={{ width: '307px' }}>
                                    {/* {
                                        this.state.coupon_data &&
                                        <>
                                        <div className='flex items-center justify-between'>
                                            <span className='text-safeFormGray tracking-normal text-sm'>Order amount:</span>
                                            <span className='text-safeFormGray text-sm text-md'>{AppHelper.makeCurrencyFormat(price)}</span>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <span className='text-safeFormGray tracking-normal text-sm'>Coupon discount:</span>
                                            <span className='text-safeFormGray text-sm text-md'>(-) {this.getDiscountAmount()}</span>
                                        </div>
                                        </>
                                    } */}
                                        <div className='flex items-center justify-between'>
                                            <span className='text-safeFormGray tracking-normal text-lg'>Total Due:</span>
                                            <span className='text-primary tracking-normal text-2xl'>{AppHelper.makeCurrencyFormat(price)}</span>
                                            {/* <span className='text-primary tracking-normal text-2xl'>{this.getTotalDue(this.state.coupon_data)}</span> */}
                                        </div>
                                    </div>
                                    {/* <span className="text-safeFormGray tracking-normal text-lg font-medium">
                                        Total Due:
                                    </span>
                                    <p className="text-primary tracking-normal text-3xl font-medium">{price.toFixed(2)}</p> */}
                                    {
                                        (type !== '' && type === 'auto-refill') &&
                                        <p className="text-safeFormGray text-smXs pr-24">
                                            This amount will be charged automatically when your account time is 20 minutes.
                                        </p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex-initial w-full xl:w-4/6 rounded text-center shadow-xl m-2 px-0 sm:px-8">
                            <div className="flex flex-col">
                                <div className="px-1 sm:px-4 py-2 mx-2 mt-10">
                                    <div className="flex items-center">
                                        <span className="text-primary tracking-normal text-xs sm:text-sm font-medium">Choose Payment Method</span>
                                        <div className="flex-grow text-center float-right">
                                            <div className="flex float-right space-x-2">
                                                <button type="button" onClick={() => { this.setState({ modalCCOpen: true }) }} className="bg-primary text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Add New Card</button>
                                                {
                                                    (type !== '' && type !== 'auto-refill') &&
                                                    <button onClick={() => {
                                                        this.props.history.push("/Plans",
                                                            { PropsData: { type: 'auto-refill' } })
                                                    }}
                                                        type="button" className="bg-primary text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">Setup Auto Replenish</button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center py-2 m-2">
                                    {
                                        // this.state.card_list.length > 0 ?
                                        <div className="flex flex-wrap items-center text-sm text-safeFormGray">
                                            {/* this.state.PropsData.type !== 'item_refill' */}
                                            <div key={'static'} className="p-2 w-6/12 lg:w-4/12" onClick={this.handleCashTransaction}>
                                                <Tooltip title={(this.state.PropsData.type !== 'refill') ? 'Cash Payment Disabled' : 'Cash Payment'} placement="top">
                                                {/* <Tooltip title={(this.state.PropsData.type !== 'refill' || this.state.PropsData.is_unlimited) ? ((this.state.PropsData.type !== 'refill' && !this.state.PropsData.is_unlimited) ? 'Cash Payment Disabled' : "Cash transaction isn't allowed for Monthly subscription plan") : 'Cash Payment'} placement="top"> */}
                                                    <div className={clsx((this.state.PropsData.type === 'refill') ? "cursor-pointer" : "opacity-75 cursor-not-allowed", "md:w-mdCw md:h-mdCh rounded p-2 my-2 credit-card-list bg-primary")}>
                                                    {/* <div className={clsx((this.state.PropsData.type === 'refill' && !this.state.PropsData.is_unlimited) ? "cursor-pointer" : "opacity-75 cursor-not-allowed", "md:w-mdCw md:h-mdCh rounded p-2 my-2 credit-card-list bg-primary")}> */}
                                                        <div className='bg-primary'>
                                                            <img className="h-10 md:h-16 mx-auto my-4" src={dollar} alt="Dollar" />
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            </div>
                                            <div style={{ width: '100%', alignItems: 'center', display: this.state.card_list_spinner }}>
                                                <center><CircularProgress /></center>
                                            </div>
                                            {
                                                ((this.state.card_list_spinner === 'none') && (this.state.card_list.length === 0)) &&
                                                <p>No any cards available</p>
                                            }
                                            {this.listCards(this.state.card_list, type)}
                                        </div>
                                        // :
                                        // <p className="text-safeFormGray text-center tracking-normal text-sm font-medium">
                                        //     No any saved card's available!!
                                        // </p>
                                    }
                                </div>
                                {
                                    (this.state.PropsData.type === 'refill') ?
                                    <div className="flex items-center justify-between px-1 sm:px-4 text-black m-2">
                                        <div className="flex-1"></div>
                                        <div className="flex flex-col">
                                            {   !this.state.PropsData.is_unlimited &&
                                                (this.state.coupon_code_process ? 
                                                <CircularProgress /> :
                                                <div className="flex justify-center w-full">
                                                    <TextField
                                                        id="coupon_code"
                                                        label="Coupon Code"
                                                        type="text"
                                                        placeholder="Enter coupon code here"
                                                        className="w-full"
                                                        value={this.state.coupon_code}
                                                        style={{ width: '270px' }}
                                                        // disabled={this.state.invalid_coupon_code}
                                                        onChange={(event) => this.inputHandler(event, "coupon_code")}
                                                        variant="outlined"
                                                        InputProps={{
                                                            endAdornment: (
                                                                <ApplyButton handleApplyCode={() => this.handleApplyCode()} couponCode={this.state.coupon_code} />
                                                            ),
                                                            autoComplete: 'new-coupon_code',
                                                            form: {
                                                                autoComplete: 'off',
                                                            },
                                                        }}
                                                    />
                                                </div>)
                                            }
                                            {
                                                (this.state.coupon_data && !this.state.invalid_coupon_code) &&
                                                <div className="flex justify-center py-1">
                                                    <p className="text-xs text-safeFormGray">
                                                    <span className="text-xs text-safeGreen pr-1">{ this.state.coupon_data.message }</span>
                                                    {/* { this.getDiscountString() } */}
                                                    You will get <span className='font-semibold'>{this.convertTime(this.getCouponAmount(this.state.coupon_data))}</span> Extra!
                                                    </p>
                                                </div>
                                            }
                                            {
                                                this.state.invalid_coupon_code &&
                                                <div className="flex justify-center py-1">
                                                    <span className="text-xs text-safeRed pr-1">Coupon code isn't applicable for this plan!</span>
                                                </div>
                                            }
                                        </div>
                                    </div> : null
                                }
                                <div className="px-1 sm:px-4 text-black text-center pb-4 m-2">
                                    <div className="flex items-center">
                                        <div className="flex-grow text-center">
                                            <div className="flex float-right space-x-6">
                                                <div>
                                                    <PrimaryCheckbox
                                                        size="small"
                                                        checked={this.state.is_agree_tnc_payment}
                                                        onChange={(event) => { this.handleBillingCheckbox(event, 'is_agree_tnc_payment') }}
                                                        inputProps={{ 'aria-label': 't&c payment' }} />
                                                    Agree to <span className="text-secondary cursor-pointer">terms and condition</span>
                                                </div>
                                                {
                                                    (type !== '' && type === 'auto-refill') ?
                                                        <button type="button" disabled={(this.state.payment_spinner === 'block')} onClick={() => this.refillAction('auto')} className="bg-primary text-white px-1 md:px-2 py-2 shadow-xl rounded text-xs md:text-sm">
                                                            {(this.state.payment_spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Confirm Auto Replenish'}
                                                        </button>
                                                        :
                                                        <button type="button" disabled={(this.state.payment_spinner === 'block') || this.state.invalid_coupon_code} onClick={() => this.refillAction('refill')} className="bg-primary text-white px-1 md:px-2 py-2 shadow-xl rounded text-xs md:text-sm">
                                                            {(this.state.payment_spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Process Payment'}
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' }),
        UpdateCredit: (credit) => dispatch({ type: 'UpdateCredit', value: credit })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);