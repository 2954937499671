import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import URLHelper from '../Helper/URLHelper'
import AppHelper from '../Helper/AppHelper'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Header from './Header';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexGrow: 1,
        paddingTop: 50,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class ConfirmExistCCInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            agree: true,
            spinner: 'none',
            BUTTONS: true,
            back: 1,
            open: false,
            PropsData: '',
            signoutButtonDisabled: false
        }
    }

    PaymentProccess = () => {
        if (this.state.password !== '') {
            this.VerifyAdmin()
        } else {
            this.setState({ snackMsg: "Password is Required.", snack: true })
        }
    }

    componentWillMount() {
        console.log("Confirm Exist CC Info")
        console.log(this.props)
        this.setState({ type: this.props.match.params.type, method: 'creditcard' })
        if (this.props.state.token === '') {
            window.location.href = "/"
        } else if (this.props.location.state !== undefined) {
            this.setState({ PropsData: this.props.location.state.PropsData })
            if (this.state.PropsData.type == 'auto' && this.state.PropsData.plan_price != 0 && this.state.PropsData.plan_id == 0) {
                this.props.history.push("/MainMenu")
            }
        }
        else {
            window.location.href = "/MainMenu"
        }
    }

    FormSubmit = (event) => {
        event.preventDefault();
        if (this.state.agree) {
            this.DoPayment()
        } else {
            this.setState({ snackMsg: "Please accept our terms and conditions.", snack: true })
        }
    }

    async DoPayment() {
        if (navigator.onLine) {
            var Data = {
                auto_replenish_creditcard_id: this.props.location.state.PropsData.creditcard_id,
                cvv: '',
                user_id: this.props.state.user_id,
                auto_replenish_plan_id: this.props.location.state.PropsData.plan_id,
                method: 'creditcard',
                transactiontype: 'auto'
            }
            this.setState({ spinner: 'block', BUTTONS: false, signoutButtonDisabled: true })
            // console.log("Confirm Existing Card")
            // console.log(Data)
            return fetch(URLHelper.ConfirmAutoReplenish, {
                method: 'POST',
                headers: {
                    'authorization': 'Bearer ' + this.props.state.token,
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                Response.json().then((res) => {
                    //console.log(res)
                    this.setState({ spinner: 'none', BUTTONS: false, signoutButtonDisabled: true })
                    if (res.status) {
                        this.props.history.push("/ReplenishmentDone", { PropsData: this.props.location.state.PropsData.plan_price })
                    }
                    else {
                        this.props.UpdateMSG(res.message)
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true, signoutButtonDisabled: false })
                        this.props.history.push("/MainMenu")
                    }
                })
                    .catch((error) => {
                        this.setState({ spinner: 'none', BUTTONS: true, signoutButtonDisabled: false })
                        this.setState({ snackMsg: "Something went wrong.", snack: true })
                    })
            })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }

    PaymentCancel = () => {
        this.setState({ open: true })
    }

    render() {
        const { classes } = this.props;
        if (this.props.state.token !== '') {
            return (
                <div className="App">
                    <Dialog
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{AppHelper.DailogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ open: false })} color="primary">
                                No
                        </Button>
                            <Button onClick={() => {
                                this.setState({ open: false })
                                this.props.history.goBack()
                            }} color="primary" autoFocus>
                                Yes
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.snack}
                        autoHideDuration={1500}
                        onClose={() => this.setState({ snack: false })}
                    >
                        <SnackbarContent
                            className="error"
                            aria-describedby="client-snackbar"
                            message={this.state.snackMsg}
                        />
                    </Snackbar>
                    {/* <Button disabled={this.state.signoutButtonDisabled} style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
                        {
                            this.props.Logout()
                            window.location.href = "/"
                        }
                    }}>Sign Out</Button> */}

                    <Header propsState={this.props.state} />

                    <Grid container>
                        <Grid item xs />
                        <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                            <div container style={{ marginTop: '9.44%', border: '0.5px solid #707070', padding: '10px 20px' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6} style={{ padding: 10 }}>
                                        <p style={{ color: '#000000', fontSize: 16, fontWeight: 'bold' }}>Card ending in {this.props.location.state.PropsData.last_four}</p>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} style={{ padding: 10, textAlign: 'end' }}>
                                        <p style={{ color: '#000000', fontSize: 16, float: 'right', fontWeight: 'bold' }}>{this.props.location.state.PropsData.type == 'auto' ? 'Auto Replenish' : 'Refill'} ${this.props.location.state.PropsData.plan_price}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <form className={classes.container} noValidate autoComplete="off" style={{ marginTop: 20, marginBottom: 50 }} onSubmit={this.FormSubmit}>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={this.state.agree}
                                                            onChange={() => this.setState({ agree: !this.state.agree })}
                                                            value={this.state.agree}
                                                            style={{
                                                                color: '#009688'
                                                            }}
                                                        />
                                                    } style={{ color: '#000000' }}
                                                    label="Agree to terms and conditions"
                                                />
                                            </Grid>
                                            {this.state.BUTTONS &&
                                                <Grid container>
                                                    <Button type="submit" variant="contained" className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#32CD32', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                        Confirm Auto Replenish
                                            </Button>
                                                    <Button variant="contained" className={classes.textField} onClick={() => this.PaymentCancel()} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                        Back
                                        </Button>
                                                </Grid>}

                                        </form>
                                    </Grid>
                                    <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                        <center>
                                            <CircularProgress className={classes.progress} />
                                            <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitTitle}</p>
                                            <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitMsg}</p>
                                        </center>
                                    </div>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

ConfirmExistCCInfo.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' }),
        UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ConfirmExistCCInfo));
