import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
// import InputBase from '@material-ui/core/InputBase';
import { connect } from 'react-redux'
import URLHelper from '../Helper/URLHelper'
// import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-maskedinput';
import PublicHeader from './PublicHeader';
import AppHelper from '../Helper/AppHelper';
import jwt_decode from 'jwt-decode';
import progressPng from '../assets/progress.png';
import clsx from 'clsx';
import SafeTechSnackbar from './SafeTechSnackbar';
//import "https://maps.googleapis.com/maps/api/js?key=&libraries=places&callback=initMap";
//const google = window.google;

var MSG = '';
var ERROR = 0;
var MONTH = new Date().getMonth() + 1
var YEAR = new Date().getFullYear() + ""
YEAR = YEAR.match(/\d{2}$/)

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        margin: '10px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
    bootstrapInputSmall: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: '10px 0px 10px 12px',
        margin: '0px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
    signupBackground: {
        background: 'transparent linear-gradient(102deg, #3E61C4 0%, #7D432D 100%) 0% 0% no-repeat padding-box',
        opacity: 1
    },
    contentBackground: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '0px 5px 25px #00000029',
        borderRadius: '5px',
        opacity: 1
    }
});

// //Input Field
// const autocompleteFormField = document.getElementById('Address');

// const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
//     types: ['address'],
//     componentRestrictions: ['us'],
// });

// window.google.maps.event.clearInstanceListeners(autocompleteFormField);
// window.google.maps.event.addListener('place_changed', function() {
//     // Custom methods to populate form fields
// })

// window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
//     const place = autocomplete.getPlace();
// })

class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            action_type: 'adduser',
            signupFormErrors: [],
            show: true,
            user_id: '',
            first_name: '',
            last_name: '',
            Card_number: '',
            Expiry_date: '',
            CVV: '',
            street_number: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            phone: '',
            email: '',
            password: '',
            con_password: '',
            snackMsg: '',
            spinner: 'none',
            snack: false,
            error: 0,
            PropsData: '',
            groups: [],
            policy_string: '',
            modalOpen: true,
            snackVariant: 'error',
            // admin_password: '',
            // signupAdminToken: '',
        }
        this.addressReference = React.createRef();
        // console.log('PROPS ::', props)
    }

    componentDidUpdate(prevProps) {
        // console.log('componentDidUpdate :::', prevProps)
        if (this.addressReference.current) {
            const autocompleteFormField = this.addressReference.current;
            //console.log(autocompleteFormField)
            const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
                types: ['address'],
            });
            //console.log("autocomplete")
            //console.log(autocomplete)
            window.google.maps.event.addListener(autocomplete, 'place_changed', () => {

                this.setState({ street_number: '', zip: '', city: '', state: '' })

                const place = autocomplete.getPlace();
                //console.log("DATA==>")
                //console.log(place)
                if (place.address_components != null) {
                    place.address_components.map((value, index) => {
                        if (value.types.includes('street_number')) {
                            // console.log("ADDRESS street_number==>" + value.long_name)
                            this.setState({ street_number: value.long_name })
                        }
                        if (value.types.includes('route')) {
                            //console.log("ADDRESS Locality==>" + value.long_name)
                            this.setFieldValueAndError('address', this.state.street_number + " " + value.long_name)
                            // this.setState({ address: this.state.street_number + " " + value.long_name })
                        }
                        if (value.types.includes('postal_code')) {
                            //console.log("ADDRESS POSTAL==>" + value.long_name)
                            this.setFieldValueAndError('zip', value.long_name)
                            // this.setState({ zip: value.long_name })
                        }
                        if (value.types.includes('administrative_area_level_1')) {
                            //console.log("ADDRESS STATE==>" + value.long_name)
                            this.setFieldValueAndError('state', value.long_name)
                            // this.setState({ state: value.long_name })
                        }
                        if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
                            //console.log("ADDRESS CITY==>" + value.long_name)
                            this.setFieldValueAndError('city', value.long_name)
                            // this.setState({ city: value.long_name })
                        }
                    })
                }
            });
        }
    }

    componentDidMount() {
        // console.log('componentDidMount ......')
        if (this.props.state.token !== '') {
            console.log('token is not blank')
            if (this.props.state.user_type === 'Admin') {
                window.location.href = "/AccountList";
            } else {
                window.location.href = "/MainMenu";
            }
        }
        // const autocompleteFormField = this.addressReference.current;
        // //console.log(autocompleteFormField)
        // const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
        //     types: ['address'],
        // });
        // //console.log("autocomplete")
        // //console.log(autocomplete)
        // window.google.maps.event.addListener(autocomplete, 'place_changed', () => {

        //     this.setState({ street_number: '', zip: '', city: '', state: '' })

        //     const place = autocomplete.getPlace();
        //     //console.log("DATA==>")
        //     //console.log(place)
        //     if (place.address_components != null) {
        //         place.address_components.map((value, index) => {
        //             if (value.types.includes('street_number')) {
        //                 console.log("ADDRESS street_number==>" + value.long_name)
        //                 this.setState({ street_number: value.long_name })
        //             }
        //             if (value.types.includes('route')) {
        //                 //console.log("ADDRESS Locality==>" + value.long_name)
        //                 this.setState({ address: this.state.street_number + " " + value.long_name })
        //             }
        //             if (value.types.includes('postal_code')) {
        //                 //console.log("ADDRESS POSTAL==>" + value.long_name)
        //                 this.setState({ zip: value.long_name })
        //             }
        //             if (value.types.includes('administrative_area_level_1')) {
        //                 //console.log("ADDRESS STATE==>" + value.long_name)
        //                 this.setState({ state: value.long_name })
        //             }
        //             if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
        //                 //console.log("ADDRESS CITY==>" + value.long_name)
        //                 this.setState({ city: value.long_name })
        //             }
        //         })
        //     }
        // });
    }

    componentWillUnmount() {
        // console.log('sign up compoennt is unmounting...')
        this.props.RemoveAdminToken()
    }

    setFieldValueAndError = (fieldName, value) => {
        if (this.state.signupFormErrors.indexOf(`${fieldName}`) > -1) {
            let newErrors = [...this.state.signupFormErrors]
            newErrors.splice(this.state.signupFormErrors.indexOf(`${fieldName}`), 1)
            this.setState({ [fieldName]: value, signupFormErrors: newErrors })
        } else {
            this.setState({ [fieldName]: value })
        }
    }

    inputHandler = (event, inputID) => {

        var value = event.target.value
        if (inputID === "policy_string") {
            this.setState({ policy_string: value })
        }
        if (inputID == "first_name") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setFieldValueAndError('first_name', value)
                // this.setState({ first_name: value })
            }
            else {
                //console.log("Not First Name")
            }
        }
        else if (inputID == "last_name") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setFieldValueAndError('last_name', value)
                // this.setState({ last_name: value })
            }
            else {
                //console.log("Not Last Name")
            }
        }
        else if (inputID == "Card_number") {
            if (value.length < 17) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setFieldValueAndError('Card_number', value)
                    // this.setState({ Card_number: value })
                    if (value.length > 0) {
                        let formErrors = []
                        if (this.state.Expiry_date === '' && (!this.state.signupFormErrors.includes('Expiry_date'))) {
                            formErrors.push('Expiry_date')
                        }
                        if (this.state.CVV === '' && (!this.state.signupFormErrors.includes('CVV'))) {
                            formErrors.push('CVV')
                        }
                        if (formErrors.length > 0) {
                            this.setState({ signupFormErrors: [...this.state.signupFormErrors, ...formErrors] })
                        }
                    }
                    else {
                        let newErrors = [...this.state.signupFormErrors]
                        if (newErrors.indexOf('Expiry_date') > -1) {
                            newErrors.splice(newErrors.indexOf('Expiry_date'), 1)
                        }
                        if (newErrors.indexOf('CVV') > -1) {
                            newErrors.splice(newErrors.indexOf('CVV'), 1)
                        }
                        this.setState({ signupFormErrors: newErrors })
                    }
                }
            }
        }
        else if (inputID == "Expiry_date") {
            this.setFieldValueAndError('Expiry_date', value)
            // this.setState({ Expiry_date: value })
            if (value.length === 0) {
                let formErrors = []
                if (this.state.Card_number !== '') {
                    formErrors.push('Expiry_date')
                    this.setState({ signupFormErrors: [...this.state.signupFormErrors, ...formErrors] })
                }
            }
        }
        else if (inputID == "CVV") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setFieldValueAndError('CVV', value)
                    // this.setState({ CVV: value })
                    if (value.length === 0) {
                        let formErrors = []
                        if (this.state.Card_number !== '') {
                            formErrors.push('CVV')
                            this.setState({ signupFormErrors: [...this.state.signupFormErrors, ...formErrors] })
                        }
                    }
                }
            }
        }
        else if (inputID == "address") {
            this.setFieldValueAndError('address', value)
            // this.setState({ address: value })
        }
        else if (inputID == "city") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setFieldValueAndError('city', value)
                // this.setState({ city: value })
            }
            else {
                //console.log("Not City")
            }
        }
        else if (inputID == "state") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setFieldValueAndError('state', value)
                // this.setState({ state: value })
            }
            else {
                //console.log("Not State")
            }
        }
        else if (inputID == "zip") {
            if (value.length < 7) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setFieldValueAndError('zip', value)
                    // this.setState({ zip: value })
                }
                else {
                    //console.log("Not Zip")
                }
            }
        }
        else if (inputID == "phone") {
            if (value.length < 11) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setFieldValueAndError('phone', value)
                    // this.setState({ phone: value })
                }
                else {
                    //console.log("Not number")
                }
            }
        }
        else if (inputID == "email") {
            this.setFieldValueAndError('email', value)
            // this.setState({ email: value })
        }
        else if (inputID == "password") {
            this.setFieldValueAndError('password', value)
            // this.setState({ password: value })
        }
        else if (inputID == "con_password") {
            this.setFieldValueAndError('con_password', value)
            // this.setState({ con_password: value })
        }
    }

    ValidateExpiry = () => {
        var date = this.state.Expiry_date.split('/')
        if (date[1] >= YEAR[0] && date[1].length < 5) {
            if (date[1] == YEAR[0]) {
                if (date[0] > MONTH && date[0] < 13) {
                    return true
                }
                else {
                    return false
                }
            }
            if (date[1] > YEAR[0]) {
                if (date[0] >= 1 && date[0] < 13) {
                    return true
                }
                else {
                    return false
                }
            }
        } else {
            return false
        }
    }

    submit = () => {
        // console.log("Submit")
        // console.log('this.state :', this.state)
        MSG = ''
        if (this.state.action_type === 'edituser') {
            if ((this.state.first_name != '' && this.state.last_name != '' && this.state.phone_no != '' && this.state.address != '' && this.state.city != '' && this.state.state != '' && this.state.zip != '' && this.state.email != '')) {
                if (this.state.phone.length == 10 && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
                    this.editUser()
                }
                else {
                    //console.log("SNACK==>" + this.state.snackMsg);
                    if (this.state.phone.length != 10) {
                        //console.log("Invalid Phone")
                        MSG = MSG + "\nInvalid Phone Number."
                        ERROR = 1
                    }

                    if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
                        //console.log("Invalid Email")
                        MSG = MSG + "\nInvalid Email."
                        ERROR = 1
                    }

                    if (ERROR == 1) {
                        //console.log("ERROR")
                        this.setState({ snackMsg: MSG, snack: true, snackVariant: 'error' })
                    }
                }
            }
            else {
                this.setState({ snackMsg: "Please fill all the details.", snack: true, snackVariant: 'error' })
            }
        }
        else {
            let formErrors = []
            if (this.state.first_name === '') {
                formErrors.push('first_name')
            }

            if (this.state.last_name === '') {
                formErrors.push('last_name')
            }

            if (this.state.phone === '') {
                formErrors.push('phone')
            }

            if (this.state.email === '') {
                formErrors.push('email')
            }

            if (this.state.address === '') {
                formErrors.push('address')
            }

            if (this.state.city === '') {
                formErrors.push('city')
            }

            if (this.state.state === '') {
                formErrors.push('state')
            }

            if (this.state.zip === '') {
                formErrors.push('zip')
            }

            if (this.state.password === '') {
                formErrors.push('password')
            }

            if (this.state.con_password === '') {
                formErrors.push('con_password')
            }

            if (formErrors.length > 0) {
                let a = [...this.state.signupFormErrors, ...formErrors];
                let unique = a.filter((item, i, ar) => ar.indexOf(item) === i);
                this.setState({ signupFormErrors: unique })
            }

            if ((this.state.first_name !== '' && this.state.last_name !== '' && this.state.phone_no !== '' && this.state.password !== '' && this.state.address !== '' && this.state.city !== '' && this.state.state !== '' && this.state.zip !== '' && this.state.email !== '' && this.state.password !== '' && this.state.con_password !== '')) {
                // console.log("All Data Found")
                if (this.state.phone.length === 10 && (this.state.password === this.state.con_password) && /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
                    // console.log('this.state.Card_number :', this.state.Card_number)
                    if (this.state.Card_number !== '') {
                        if (this.state.Expiry_date !== '' && this.state.CVV !== '') {
                            if (this.ValidateExpiry()) {
                                console.log('Valid Expiry Date.')
                                this.SignUp()
                            }
                            else {
                                // console.log('Invalid Expiry Date.')
                                MSG = MSG + "\nInvalid Expiry Date."
                                ERROR = 1
                            }
                            if (ERROR == 1) {
                                //console.log("ERROR")
                                this.setState({ snackMsg: MSG, snack: true, snackVariant: 'error' })
                            }
                        } else {
                            this.setState({ snackMsg: "Please fill all the details.", snack: true, snackVariant: 'error' })
                        }
                    } else {
                        console.log('card number is not available.')
                        this.SignUp()
                    }
                }
                else {
                    //console.log("SNACK==>" + this.state.snackMsg);
                    if (this.state.phone.length != 10) {
                        //console.log("Invalid Phone")
                        MSG = MSG + "\nInvalid Phone Number."
                        ERROR = 1
                    }
                    if (this.state.password != this.state.con_password) {
                        //console.log("Password Not Matched")
                        MSG = MSG + "\nPassword and Confirm Password Not Matched."
                        ERROR = 1
                    }
                    if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(this.state.email)) {
                        //console.log("Invalid Email")
                        MSG = MSG + "\nInvalid Email."
                        ERROR = 1
                    }

                    if (ERROR == 1) {
                        //console.log("ERROR")
                        this.setState({ snackMsg: MSG, snack: true, snackVariant: 'error' })
                    }
                }
            }
            else {
                this.setState({ snackMsg: "Please fill all the details.", snack: true, snackVariant: 'error' })
            }
        }
    }

    SetAddress = (data) => {
        //console.log(data)
        if (data.description != '') {
            var ADDRESS_LENGTH = data.terms.length
            var TERMS = data.terms
            //console.log("LENGTH==>" + ADDRESS_LENGTH)
            switch (ADDRESS_LENGTH) {
                case 1: {
                    break
                }
                case 2: {
                    this.setState({ city: '', state: TERMS[ADDRESS_LENGTH - 2].value })
                    break
                }
                case 3: {
                    this.setState({ state: TERMS[ADDRESS_LENGTH - 2].value, city: TERMS[ADDRESS_LENGTH - 3].value })
                    break
                }
                default: {
                    //console.log("Hii")
                    this.setState({ state: TERMS[ADDRESS_LENGTH - 2].value, city: TERMS[ADDRESS_LENGTH - 3].value })
                    break
                }
            }
        }
    }

    FormSubmit = (event) => {
        event.preventDefault();
        this.submit()
        // this.SignUp()
    }

    async fetchUserId(userName) {
        if (navigator.onLine) {
            return fetch(URLHelper.GetUserId + "/" + userName, {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.match.params.adminToken
                },
            }).then((Response) => Response.json())
                .then((res) => {
                    if (res.status) {
                        let userData = res.data[0];
                        // console.log('got user id with other details :->',userData)
                        this.setState({ user_id: userData.user_id });
                        this.fetchCurrentUserDetails(userData.user_id);
                    }
                    else {
                        console.log('invalid user credentials at time of edit profile')
                        this.setState({ show: false });
                        window.location.href = "/";
                    }
                })
                .catch((error) => {
                    // console.log("Error==>" + error)
                    this.setState({ spinner: 'none', snackMsg: "Something went wrong here.", snack: true, snackVariant: 'error', show: false })
                    console.log('Something went wrong at time of edit profile')
                    window.location.href = "/";
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error', show: false })
            console.log('Internet Not Available. Try Again!!')
            window.location.href = "/";
        }
    }

    async fetchCurrentUserDetails(accountid) {
        if (navigator.onLine) {
            this.setState({ spinner: 'block' })
            let token = (this.props.match.params.adminToken !== undefined) ? this.props.match.params.adminToken : this.props.state.token;
            // let authorization = 'Bearer ' + token;
            // console.log(this.props.match.params.adminToken !== undefined ? 'true@@@@@@@@@' : 'false@@@@@@@');
            // console.log('authorization :', authorization);
            return fetch(URLHelper.GetUserDetail + "/" + accountid, {
                method: 'GET',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    if (res.status) {
                        let userData = res.data[0];
                        //console.log('result user details : ', userData);
                        this.setState({
                            show: true,
                            first_name: userData.first_name,
                            last_name: userData.last_name,
                            address: userData.address,
                            city: userData.city,
                            state: userData.state,
                            zip: userData.zip,
                            phone: userData.phone_no,
                            email: userData.email,
                        })
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error' })
                    }
                })
                .catch((error) => {
                    // console.log("Error==>" + error)
                    this.setState({ spinner: 'none', snackMsg: "Something went wrong here.", snack: true, snackVariant: 'error' })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }


    editUser = () => {
        if (navigator.onLine) {
            this.setState({ spinner: 'block' })
            let token = (this.props.match.params.adminToken !== undefined) ? this.props.match.params.adminToken : this.props.state.token;
            // let user_id = (this.props.match.params.userName !== undefined) ? this.props.match.params.userName : this.props.state.user_id;

            let user_id = (this.props.match.params.userName !== undefined) ? this.state.user_id : this.props.state.user_id;
            // let authorization = 'Bearer ' + token;
            // console.log(this.props.match.params.adminToken !== undefined ? 'true@@@@@@@@@' : 'false@@@@@@@');
            // console.log('authorization :', authorization);
            let Data = {
                //admin_token: this.props.state.token,
                actionby_admin: this.props.state.user_type,
                user_id: user_id,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                phone_no: this.state.phone,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                email: this.state.email,
            }
            //console.log('form submit data : ', Data);
            return fetch(URLHelper.EditUser, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + token
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    // console.log("Response==>\n" + JSON.stringify(res))
                    if (res.status) {
                        // console.log("TRUE")
                        this.setState({
                            snackMsg: res.message, snack: true, snackVariant: 'success', spinner: 'none',
                            user_id: '',
                            show: false,
                            first_name: '',
                            last_name: '',
                            address: '',
                            city: '',
                            state: '',
                            zip: '',
                            phone: '',
                            email: '',
                        })
                        window.location.href = "/MainMenu"
                    }
                    else {
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', spinner: 'none' })
                    }
                })
                .catch((error) => {
                    console.log("Error==>" + error)
                    this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', spinner: 'none' })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

    async SignUp() {
        if (navigator.onLine) {
            this.setState({ spinner: 'block' })
            var Data = {
                role: "USER",
                // admin_token: this.state.signupAdminToken,
                // admin_token: this.props.state.admintoken,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                cc_num: this.state.Card_number,
                exp_date: AppHelper.changeDateFormat(this.state.Expiry_date),
                cvv: this.state.CVV,
                phone_no: this.state.phone,
                password: this.state.password,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                zip: this.state.zip,
                email: this.state.email,
                // groups: this.state.groups,
                // policy_string: this.state.policy_string
            }
            // console.log(Data)
            return fetch(URLHelper.SignUp, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ spinner: 'none' })
                    //console.log("Response==>\n" + JSON.stringify(res))
                    if (res.status) {
                        console.log("TRUE")
                        console.log("res :", res)
                        var token = res.data.token
                        var user_id = jwt_decode(token).user_id
                        var type = 'User'
                        this.props.LoginSuccess(token, user_id, type)
                        this.props.history.push("/MainMenu")
                        // window.location.href = "/"
                    }
                    else {
                        this.setState({ spinner: 'none' })
                        this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error' })
                    }
                })
                .catch((error) => {
                    //console.log("Error==>" + error)
                    this.setState({ spinner: 'none' })
                    this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
        }
    }

    // formSubmit = () => {
    //     if (this.state.admin_password !== '') {
    //         this.VerifyAdmin()
    //     } else {
    //         this.setState({ snackMsg: "Admin Password is Required.", snack: true, snackVariant: 'error' })
    //     }
    // }

    // async VerifyAdmin() {
    //     if (navigator.onLine) {
    //         var Data = {
    //             password: this.state.admin_password
    //         }
    //         //console.log(Data)
    //         this.setState({ spinner: 'block', BUTTONS: false })
    //         return fetch(URLHelper.GetAdminToken, {
    //             method: 'POST',
    //             headers: {
    //                 'Accept': 'Application/json',
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify(Data),
    //         }).then((Response) => Response.json())
    //             .then((res) => {
    //                 this.setState({ spinner: 'none', BUTTONS: false })
    //                 if (res.status) {
    //                     //  console.log(res)
    //                     this.setState({ signupAdminToken: res.data, modalOpen: false });
    //                 }
    //                 else {
    //                     this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.log(error)
    //                 this.setState({ spinner: 'none', BUTTONS: true })
    //                 this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error' })
    //             })
    //     }
    //     else {
    //         this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
    //     }
    // }

    hideSnackBar = () => {
        this.setState({ snack: false })
    }

    // handleClose = () => {
    //     this.setState({ modalOpen: false, admin_password: '', signupAdminToken: '' }, () => this.props.history.goBack());
    // }

    render() {
        const { classes } = this.props;
        if (this.state.show) {
            return (
                <div className={clsx(classes.signupBackground, 'h-full pb-12')}>
                    {/* <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={this.state.snack}
                    autoHideDuration={1500}
                    onClose={() => {
                        //console.log("SNACK Close==>" + this.state.snackMsg);
                        this.setState({ snack: false })
                        MSG = ''
                        ERROR = 0
                    }}>
                    <SnackbarContent
                        className="error"
                        aria-describedby="client-snackbar"
                        message={this.state.snackMsg}
                    />
                </Snackbar> */}
                    <SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />
                    {/* <Dialog open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"><p className="text-primary text-center">Admin Approval</p></DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus={true}
                            id="admin_password"
                            label="Admin Password"
                            type="password"
                            className="w-full"
                            value={this.state.admin_password}
                            onChange={(event) => this.setState({ admin_password: event.target.value })}
                            onKeyDown={(event) => {
                                if(event.key === 'Enter'){
                                    this.formSubmit()
                                }
                            }}
                            variant="outlined"
                            InputProps={{
                                autoComplete: 'new-password',
                                form: {
                                    autoComplete: 'off',
                                },
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <div className="flex flex-col w-full mx-4">
                                <div className="text-center">
                                    <button
                                        type="button"
                                        disabled={(this.state.spinner === 'block')}
                                        className="bg-primary w-full text-white py-4 mb-2 shadow-xl rounded font-sans text-xs md:text-sm"
                                        onClick={() => this.formSubmit()}>
                                            {(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Continue' }
                                    </button>
                                </div>
                                <div className="text-center">
                                    <button
                                        type="button"
                                        disabled={(this.state.spinner === 'block')}
                                        className="text-safeRed w-full py-1 font-sans font-semibold text-xs md:text-sm"
                                        onClick={() => { this.handleClose();
                                        }}>Back
                                    </button>
                                </div>
                            </div>
                        </Grid>
                    </DialogActions>
                </Dialog> */}
                    <PublicHeader propsState={this.props.state} />
                    {/* {
                (this.state.signupAdminToken !== '') && */}
                    <>
                        <p className="text-center text-white text-lg">Sign Up</p>

                        <p className='bg-yellow-700 text-center text-white text-lg w-10/12 md:w-8/12 xl:w-4/12 h-full max-w-xl mx-auto mt-6'>YOUR PHONE # WILL BE YOUR COMPUTER "USER NAME"</p>

                        <div className={clsx(classes.contentBackground, "w-10/12 md:w-8/12 xl:w-4/12 h-full max-w-xl mx-auto mt-6")}>
                            <div className="flex flex-col py-6 px-8">
                                <span className="text-primary text-sm mb-2">Fill out this form. All fields are required.</span>
                                <form className={classes.container} noValidate autoComplete="off" onSubmit={this.FormSubmit}>
                                    {/* {console.log(this.state.signupFormErrors)} */}
                                    <div className="flex flex-col w-full md:flex-row my-2 px-2">
                                        <div className="w-full sm:w-1/2 mr-2">
                                            <TextField
                                                autoFocus={true}
                                                id="first_name"
                                                label="First Name *"
                                                // className={classes.Margin}
                                                type="text"
                                                className="w-full"
                                                value={this.state.first_name}
                                                error={this.state.signupFormErrors.includes('first_name')}
                                                onChange={(event) => this.inputHandler(event, "first_name")}
                                                variant="outlined"
                                            />
                                        </div>
                                        <div className="w-full sm:w-1/2 mt-4 sm:mt-0">
                                            <TextField
                                                id="last_name"
                                                label="Last Name *"
                                                // className={classes.Margin}
                                                type="text"
                                                className="w-full"
                                                value={this.state.last_name}
                                                error={this.state.signupFormErrors.includes('last_name')}
                                                onChange={(event) => this.inputHandler(event, "last_name")}
                                                variant="outlined"
                                            />
                                        </div>
                                    </div>

                                    <input
                                        id="location"
                                        placeholder="Search Address"
                                        type="text"
                                        margin="normal"
                                        style={{ width: '100%', height: '50px', borderRadius: '5px', border: '0.5px solid #c4c4c4', fontSize: '16px', margin: '6px', paddingLeft: '12px' }}
                                        ref={this.addressReference}
                                        variant="outlined"
                                    />

                                    <TextField
                                        id="address"
                                        label="Address *"
                                        className={classes.Margin}
                                        type="text"
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        value={this.state.address}
                                        error={this.state.signupFormErrors.includes('address')}
                                        onChange={(event) => this.inputHandler(event, "address")}
                                        variant="outlined"
                                    />

                                    <Grid container>
                                        <Grid item xs={12} sm={5} md={5}>
                                            <TextField
                                                id="city"
                                                label="City *"
                                                className={classes.Margin}
                                                type="text"
                                                margin="normal"
                                                value={this.state.city}
                                                error={this.state.signupFormErrors.includes('city')}
                                                onChange={(event) => this.inputHandler(event, "city")}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={3} md={3} >
                                            <TextField
                                                id="state"
                                                label="State *"
                                                className={classes.Margin}
                                                type="text"
                                                margin="normal"
                                                value={this.state.state}
                                                error={this.state.signupFormErrors.includes('state')}
                                                onChange={(event) => this.inputHandler(event, "state")}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={4} md={4} >
                                            <TextField
                                                id="zip"
                                                label="Zip *"
                                                className={classes.Margin}
                                                type="tel"
                                                margin="normal"
                                                value={this.state.zip}
                                                error={this.state.signupFormErrors.includes('zip')}
                                                onChange={(event) => this.inputHandler(event, "zip")}
                                                variant="outlined"
                                            />
                                        </Grid>
                                    </Grid>

                                    <TextField
                                        id="phone"
                                        label="Phone # *"
                                        className={classes.Margin}
                                        // disabled={(this.state.action_type === 'edituser') ? true : false}
                                        type="tel"
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        value={this.state.phone}
                                        error={this.state.signupFormErrors.includes('phone')}
                                        onChange={(event) => this.inputHandler(event, "phone")}
                                        variant="outlined"
                                    />

                                    <TextField
                                        id="email"
                                        label="Email *"
                                        className={classes.Margin}
                                        type="email"
                                        margin="normal"
                                        style={{ width: '100%' }}
                                        value={this.state.email}
                                        error={this.state.signupFormErrors.includes('email')}
                                        onChange={(event) => this.inputHandler(event, "email")}
                                        variant="outlined"
                                    />
                                    {(this.state.action_type === 'adduser') &&
                                        <TextField
                                            id="password"
                                            label="Password *"
                                            className={classes.Margin}
                                            type="password"
                                            margin="normal"
                                            style={{ width: '100%' }}
                                            value={this.state.password}
                                            error={this.state.signupFormErrors.includes('password')}
                                            onChange={(event) => this.inputHandler(event, "password")}
                                            variant="outlined"
                                            inputProps={{
                                                autoComplete: 'new-password',
                                                form: {
                                                    autoComplete: 'off',
                                                },
                                            }}
                                        />
                                    }

                                    {(this.state.action_type === 'adduser') &&
                                        <TextField
                                            id="con_password"
                                            label="Confirm Password *"
                                            className={classes.Margin}
                                            type="password"
                                            margin="normal"
                                            style={{ width: '100%' }}
                                            value={this.state.con_password}
                                            error={this.state.signupFormErrors.includes('con_password')}
                                            onChange={(event) => this.inputHandler(event, "con_password")}
                                            variant="outlined"
                                            inputProps={{
                                                autoComplete: 'new-cp',
                                                form: {
                                                    autoComplete: 'off',
                                                },
                                            }}
                                        />
                                    }

                                    {(this.state.action_type === 'adduser') &&
                                        <TextField
                                            type="text"
                                            id="Card_number"
                                            label="Card Number"
                                            className={classes.Margin}
                                            value={this.state.Card_number}
                                            onChange={(event) => this.inputHandler(event, "Card_number")}
                                            variant="outlined"
                                            margin="normal"
                                            style={{ width: '100%' }}
                                        />
                                    }

                                    {(this.state.action_type === 'adduser') &&
                                        <Grid container>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <MaskedInput
                                                    margin="normal"
                                                    mask="11/11"
                                                    name="expiry"
                                                    className={clsx(this.state.signupFormErrors.includes('Expiry_date') ? 'focus:outline-none masked-input-error' : 'masked-input')}
                                                    style={{ margin: '16px 0px 16px 6px', width: '100%', height: '54px', borderRadius: '5px', fontSize: '16px', paddingLeft: '12px' }}
                                                    // style={{ margin: '16px 0px 16px 6px', width: '100%', height: '54px', borderRadius: '5px', border: (this.state.signupFormErrors.includes('Expiry_date') ? '0.8px solid #E45048' : '0.5px solid #c4c4c4'), fontSize: '16px', paddingLeft: '12px' }}
                                                    value={this.state.Expiry_date}
                                                    placeholder="Exp Date"
                                                    onChange={(event) => this.inputHandler(event, "Expiry_date")} />
                                            </Grid>
                                            <Grid item xs md={1} />
                                            <Grid item xs={5} sm={5} md={5}>
                                                <TextField
                                                    type="tel"
                                                    id="CVV"
                                                    label="CVV"
                                                    className={classes.Margin}
                                                    value={this.state.CVV}
                                                    error={this.state.signupFormErrors.includes('CVV')}
                                                    onChange={(event) => this.inputHandler(event, "CVV")}
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>
                                    }

                                    <div className="flex-1 px-2">
                                        <div className="float-none sm:float-right">
                                            <button type="reset" onClick={() => this.props.history.goBack()} className="text-safeRed px-2 md:px-6 font-sans font-semibold text-xs md:text-sm">Back</button>
                                            <button
                                                type="submit"
                                                disabled={(this.state.spinner === 'block')}
                                                className="bg-primary text-white w-24 px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm"
                                            >
                                                {(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress' /> : 'Sign Up'}
                                            </button>
                                        </div>
                                    </div>
                                    {/* <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                <center><CircularProgress className={classes.progress} /></center>
                            </div> */}
                                </form>
                            </div>
                        </div>
                    </>
                    {/* } */}
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

SignUp.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        LoginSuccess: (token, user_id, type) => {
            var value = {
                token: token,
                user_id: user_id,
                user_type: type
            }
            dispatch({ type: 'CustomerLoginSuccess', value: value })
        },
        Logout: () => dispatch({ type: 'CustomerLogout' }),
        RemoveAdminToken: () => dispatch({ type: 'RemoveAdminToken' }),
        SaveAdminToken: (token) => dispatch({ type: 'SaveAdminToken', value: token })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(SignUp)));
