import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import URLHelper from '../Helper/URLHelper'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// import Header from './Header';
import UserHeader from './UserHeader';
import userPng from '../assets/user.png';
import invoicePng from '../assets/invoice.png';
import historyPng from '../assets/history.png';
import addPng from '../assets/add.png';
import clockPng from '../assets/clock.png';
import printPng from '../assets/print.png';
import clsx from 'clsx';
import AppHelper from '../Helper/AppHelper';

const styles = theme => ({
	root: {
		flex: 1,
		display: 'flex',
		flexGrow: 1,
		paddingTop: 50,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	roundedBox: {
		height: '200px',
		width: '200px'
	},
	boxIcon: {
		height: '40px',
		width: '40px'
	},
	balanceNumber: {
		textAlign: 'center',
		font: 'Bold Roboto',
		letterSpacing: '0px',
		textShadow: '0px 2px 5px #00000029',
		opacity: 1
	}
});

class MainMenu extends Component {

	constructor(props) {
		super(props)
		this.state = {
			user_name: '',
			first_name: '',
			credit: '',
			min_credit: '',
			printing_balance: '',
			spinner: 'none',
			snackMsg: '',
			snack: false,
			BUTTONS: false,
			modalOpen: false,
			password: '',
			confirm_password: '',
			is_active_monthly_subscription: 0,
			is_subscribed: 0,
		}
	}

	// Logout = () => {
	// 	this.props.Logout()
	// 	window.location.href = "/"
	// }

	componentWillMount() {
		// console.log('on main menu state : ',this.state);
		if (this.props.state.token === '' || this.props.state.user_type === 'Admin') {
			if(this.props.state.token === ''){
				window.location.href = "/"
			}else{
				window.location.href = "/AccountList"
			}
		}
		else {
			if (this.props.state.snackbarMsg != '') {
				this.setState({ snackMsg: this.props.state.snackbarMsg, snack: true })
				this.props.UpdateMSG('')
			}
			this.fetchUserData()
		}
	}

	handleClickOpen = () => {
		// console.log("user id to set new pass : ",this.props.state.user_id);
		this.setState({ modalOpen: true })
	}

	handleClose = () => {
		this.setState({ modalOpen: false, spinner: 'none', password: '', confirm_password: '' })
	}

	setNewPassword = () => {
		// console.log("here : ",this.state.password,this.state.confirm_password);
		if (this.state.password == '') {
			this.setState({ snackMsg: "Password is Required.", snack: true })
		}
		else if (this.state.confirm_password == '') {
			this.setState({ snackMsg: "Confirm Password is Required.", snack: true })
		}
		else if (this.state.password !== this.state.confirm_password) {
			this.setState({ snackMsg: "Confirm Password isn't matched!!.", snack: true })
		}
		else {
			this.actionResetPasswordClick()
		}
	}

	async actionResetPasswordClick() {
		if (navigator.onLine) {
			const Data = {
				user_id: this.props.state.user_id,
				password: this.state.password,
				confirm_change_password: 1
			}
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.SetNewPassword, {
				method: 'POST',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
				body: JSON.stringify(Data),
			}).then((Response) => Response.json())
				.then((res) => {
					if (res.status) {
						console.log(res)
						this.setState({ spinner: 'none', modalOpen: false })
						this.state.password = ''
						this.state.confirm_password = ''
						this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, BUTTONS: true, spinner: 'none' })
					}
				})
				.catch((error) => {
					//console.log("Error==>" + error)
					this.setState({ spinner: 'none', BUTTONS: true })
					this.setState({ snackMsg: "Something went wrong.", snack: true })
				})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	async fetchUserData() {
		if (navigator.onLine) {
			this.setState({ spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetUserDetail + "/" + this.props.state.user_id, {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ spinner: 'none', BUTTONS: true })
					if (res.status) {
						console.log('result on main menu ::=>',res.data)
						let userData = res.data[0];
						const { phone_no, first_name, last_name, credit_balance, printing_balance,
							address, city, state, zip, user_monthly_subscription_id, is_active_monthly_subscription, is_subscribed } = userData;
						let credit = '';
						// if(is_active_monthly_subscription){
						// 	if (printing_balance != null && printing_balance != 'undefined') {
						// 		credit = (printing_balance / AppHelper.chargePerMinute).toFixed(0)
						// 	}
						// 	else {
						// 		credit = 0.00
						// 	}
						// }else{
							if (printing_balance != null && printing_balance != 'undefined') {
								credit = (printing_balance / AppHelper.chargePerMinute).toFixed(0)
							}
							else {
								credit = 0.00
							}
						// }
						this.setState({ user_name: phone_no, first_name: first_name, last_name: last_name, credit: credit_balance, printing_balance: credit, is_active_monthly_subscription, is_subscribed })
						this.props.CustomerDetail({ user_name: phone_no, first_name, last_name, credit: credit_balance, address, city, state, zip, user_monthly_subscription_id, is_active_monthly_subscription, is_subscribed })
					}
					else {
						this.setState({ snackMsg: res.message, snack: true, BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ spinner: 'none', button: '', snackMsg: "Something went wrong.", snack: true, BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
		}
	}

	convertTime = (balance) => {
		// console.log('convertTime ::',balance)
		let givenSeconds = balance * 60; 
		let dateObj = new Date(givenSeconds * 1000); 
		let hours = dateObj.getUTCHours(); 
		let minutes = dateObj.getUTCMinutes(); 
		let seconds = dateObj.getSeconds(); 
		return hours.toString().padStart(2, '0') 
			+ ':' + minutes.toString().padStart(2, '0') 
			+ ':' 
			+ seconds.toString().padStart(2, '0'); 
	}

	render() {
		const { classes } = this.props;
		if (this.props.state.token !== '' && this.props.state.user_type === 'User') {
			return (
				<div>
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar>
				
					<UserHeader propsState={this.props.state} />
					<div>
						<div className="p-2 sm:p-10">
						<div className='user-body-card px-6 md:px-12 py-10 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden bg-white'>
								<p className="tracking-normal font-sans text-primary text-lg font-semibold mb-4 px-4">Welcome {this.state.first_name}</p>
								<div className="flex flex-col sm:flex-row">
									{/* user */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/UserProfile")}>
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={userPng} className="inline mx-auto mt-6" alt="user-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Account Details</h6>
										</div>
									</div>

									{/* invoice */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/Billing")}>
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={invoicePng} className="inline mx-auto mt-6" alt="invoice-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Billing</h6>
										</div>
									</div>

									{/* history */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/UserHistory")}>
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={historyPng} className="inline mx-auto mt-6" alt="history-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Usage History</h6>
										</div>
									</div>

									{/* add time */}
									<div className={clsx(classes.roundedBox, 'sm:w-1/4 p-2 cursor-pointer mx-auto')}
									onClick={() => this.props.history.push("/Plans", {PropsData: { type: 'refill' } })}>
										<div className="bg-primary px-6 py-6 rounded-lg shadow-lg text-center">
											<div className="mb-3">
											<img src={addPng} className="inline mx-auto mt-6" alt="add-png"/>
											</div>
											<h6 className="text-white text-sm font-sans block mb-5">Add Time</h6>
										</div>
									</div>
								</div>
							</div>
							<div className='user-body-card mt-12 px-6 md:px-12 py-10 w-11/12 lg:w-4/5 xl:w-3/5 mx-auto rounded-lg shadow-lg overflow-hidden bg-white'>
								<div className="flex flex-col sm:flex-row">	
									<div className='sm:w-1/2 p-4'>
										<div className="flex flex-row">
											<div className="mb-3">
												<img src={clockPng} className="inline mx-auto w-3/5 md:w-4/5" alt="clock-png"/>
											</div>
											<div className="mb-3" style={{ minWidth: '100px' }}>
												<h3 className="tracking-normal mb-2 md:mb-4 font-sans text-primary text-sm md:text-xl">Time Remaining</h3>
												{/* {
													!this.state.is_active_monthly_subscription && */}
													<button type="button" onClick={() => this.props.history.push("/Plans", {PropsData: { type: 'refill' } })} className="bg-primary text-white px-1 md:px-6 py-1 rounded font-sans text-sm md:text-xl">
														Add Time
													</button>
												{/* } */}
											</div>
										</div>
										{/* <span className={clsx(classes.balanceNumber, this.state.is_active_monthly_subscription ? 'text-2xl md:text-4xl' : 'text-2xl md:text-4xl', 'text-center text-primary')}>{this.state.is_active_monthly_subscription ? 'Unlimited Minutes' : this.convertTime((this.state.credit / AppHelper.chargePerMinute).toFixed(0))}</span> */}
										<span className={clsx(classes.balanceNumber, this.state.is_active_monthly_subscription ? 'text-2xl md:text-4xl' : 'text-2xl md:text-4xl', 'text-center text-primary')}>{this.state.is_active_monthly_subscription ? 'Unlimited Minutes' : this.convertTime(this.state.printing_balance)}</span>
									</div>
									<div className='sm:w-1/2 py-4'>
										<table>
											<tbody>
											<tr>
												<td rowSpan={3}>
													<div className="-mt-16" style={{ minWidth: '75px' }}>
														<img src={printPng} className="inline mx-auto w-3/5 md:w-4/5" alt="print-png"/>
													</div>
												</td>
												<td colSpan={3}><h3 className="tracking-normal mb-2 md:mb-4 font-sans text-primary text-sm md:text-xl">Prints Remaining</h3></td>
											</tr>
											<tr>
												<td><span className={clsx(classes.balanceNumber, 'text-center text-sm md:text-2xl text-primary')}>B&W</span></td>
												<td><span className={clsx(classes.balanceNumber, 'flex justify-end text-center text-sm md:text-2xl text-primary')}>Color</span></td>
												<td>
													{this.state.is_active_monthly_subscription ?
													<button type="button" onClick={() => this.props.history.push("/Plans", {PropsData: { type: 'refill' } })} className="ml-4 bg-primary text-white px-1 md:px-2 py-1 rounded font-sans text-sm md:text-xl" 
													style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
														Add Prints
													</button> : null}
												</td>
											</tr>
											<tr>
												<td><span className={clsx(classes.balanceNumber, ((this.state.printing_balance / 7).toFixed(0) > 99 ? 'text-2xl md:text-4xl ' : 'text-2xl md:text-4xl'), 'text-center text-primary')}>{this.state.printing_balance}</span></td>
												<td><span className={clsx(classes.balanceNumber, ((this.state.printing_balance / 7).toFixed(0) > 99 ? 'text-2xl md:text-4xl ' : 'text-2xl md:text-4xl'), 'ml-6 text-center text-primary')}>{(this.state.printing_balance / 7).toFixed(0)}</span></td>
												{/* <td><span className={clsx(classes.balanceNumber, ((this.state.printing_balance / 7).toFixed(0) > 99 ? 'text-2xl md:text-4xl ' : 'text-2xl md:text-6xl'), 'text-center text-primary')}>{this.state.printing_balance}</span></td>
												<td><span className={clsx(classes.balanceNumber, ((this.state.printing_balance / 7).toFixed(0) > 99 ? 'text-2xl md:text-4xl ' : 'text-2xl md:text-6xl'), 'ml-12 text-center text-primary')}>{(this.state.printing_balance / 7).toFixed(0)}</span></td> */}
												<td></td>
											</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

MainMenu.propTypes = {
	styles: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		CustomerDetail: passData => {
			dispatch({ type: 'CustomerDetail', value: { ...passData } })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainMenu));
