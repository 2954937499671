import './css/App.css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import Header from './Header';
import AppHelper from '../Helper/AppHelper';

const styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        flexGrow: 1,
        paddingTop: 50,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
});

class RefillSuccess extends Component {
    constructor(props) {
        super(props)
        this.state = {
            PropsData: ''
        }
    }
    componentWillMount() {
        // console.log("RefillSucess Method Mount")
        // console.log(this.props)
        if (this.props.state.token === '') {
            window.location.href = "/"
        } else if (this.props.location.state !== undefined) {
            this.setState({ PropsData: this.props.location.state.PropsData })
        }
        else {
            window.location.href = "/"
        }
    }

    onBackButtonEvent = (e) => {
        e.preventDefault();
        if (this.props.state.user_type === 'User') {
            this.props.Logout()
            //window.location.href = '/'
        }
        else if (this.props.state.user_type === 'Admin') {
            window.location.href = '/AccountList'
        }
        else {
            this.props.Logout()
        }
    }

    componentDidMount() {
        window.onpopstate = this.onBackButtonEvent;
    }

    render() {
        const { classes } = this.props;
        const { PurchaseType, PurchaseData } = this.props.location.state;
        if (this.props.state.token !== '') {
            return (
                <div className="App">

                    <Header propsState={this.props.state} />

                    <Grid container>
                        <Grid item xs />
                        <Grid item xs={10} sm={10} md={5} style={{ background: '#FFFFFF' }}>
                            <div container style={{ color: '#000000', marginTop: '9.44%', border: '0.5px solid #707070', padding: '10px 20px', textAlign: 'center' }}>
                                {(PurchaseType === 'plan') ? <h2>Payment processed successfully!</h2> : <h2>{(PurchaseData.item_name)} purchased successfully! </h2>}
                                {(PurchaseType === 'plan') ? <h2>Your new available credit is {(this.state.PropsData / AppHelper.chargePerMinute).toFixed(0)} minutes</h2> : ''}
                                <Grid container>
                                    <Grid item xs />
                                    <Grid item xs={8} sm={8} md={8}>
                                        {(PurchaseType === 'plan') ?
                                            <Button variant="contained" onClick={() => this.props.history.push("/ChoosePlan", { type: 'auto', credit_balance: this.state.PropsData })} className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#32CD32', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                Setup Auto Replenish
				                        </Button> : null}
                                        {console.log("PurchaseType :: ", PurchaseType)}
                                        <Button variant="contained" onClick={() => {
                                            if (this.props.state.user_type != 'Admin') {
                                                this.props.Logout()
                                                window.location.href = "/"
                                            }
                                            else {
                                                this.props.history.push("/AccountList")
                                            }
                                        }} className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#673ab7', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                            Done
                                        </Button>
                                    </Grid>
                                    <Grid item xs />
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

RefillSuccess.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RefillSuccess));
