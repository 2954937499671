import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import logo from '../assets/logo-alt.png';

const useStyles = makeStyles(theme => ({

    menu: {
        color: '#673ab7',
        float: 'right',
        marginTop: 40,
        marginRight: 40,
        fontSize: '16px'
    },
    menuItem: {
        color: '#673ab7',
        fontSize: '16px'
    },
    root: {
        display: 'flex',
    },
    AdminHeader: {
        // height: '180px',
        background: 'transparent linear-gradient(102deg, #3E61C4 0%, #7D432D 100%) 0% 0% no-repeat padding-box',
        opacity: 1
    },
    headerRow: {
        background: 'transparent radial-gradient(closest-side at 50% 50%, #FFFFFFA8 0%, #FFFFFF00 100%) 0% 0% no-repeat padding-box',
        opacity: 0.2
    },
    userPorfile: {
        background: '#273359 0% 0% no-repeat padding-box',
        boxShadow: '0px 5px 25px #00000029',
        border: '5px solid #273359',
        opacity: 1,
    },
    activeNav: {
        borderBottom: '4px solid #F1B64C',
        color: '#F1B64C'
    },
    
}));

function AdminHeader(props) {
    // console.log('AdminHeader component :', props)
    const pathName = props.location.pathname.toLowerCase();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);
    function makeUserName(first_name, last_name) {
        // console.log('called makeUserName first_name : ', first_name)
        // console.log('called makeUserName last_name : ', last_name)
        let firstChar = first_name.charAt(0).toUpperCase();
        let secondChar = last_name.charAt(0).toUpperCase();
        // return firstChar+secondChar;
        return firstChar+secondChar;
    }

    return (
        <header className={clsx(classes.AdminHeader, "z-20 sticky top-0 sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-4")}>
            <div className="flex items-center justify-between px-4 py-4 sm:p-0">
            <div className="flex items-center sm:flex-none sm:justify-between">
                <img src={logo} className="inline h-16 sm:h-20 ml-0 md:ml-6 my-6" alt="qisqam-logo"/>
                <div className="ml-6 relative hover-trigger block sm:hidden">
                    <button className={clsx(classes.userPorfile, 'p-1 text-white hover:border-secondary hover:bg-secondary text-2xl rounded-full transition ease-in duration-200')}>
                        {makeUserName(props.propsState.admin_first_name, props.propsState.admin_last_name)}
                    </button>
                    <div className="absolute shadow-2xl transition ease-in duration-200 focus:outline-none px-4 py-4 hover-target font-sans">
                        My Account
                        <button
                        onClick={() =>{
                            dispatch({ type: 'CustomerLogout' })
                            window.location.href = "/"
                        }}
                        className="my-2 px-3 rounded text-white transition duration-500 ease-in-out bg-safeRed transform hover:-translate-y-1 hover:scale-100 ...">
                            Logout
                        </button>
                    </div>
                </div>
            </div>
            <div className="sm:hidden">
                <button onClick={() => setIsOpen(!isOpen)} type="button" className="block text-gray-500 hover:text-white focus:text-white focus:outline-none">
                    <MenuIcon/>
                </button>
            </div>
            </div>
            <nav className={clsx(isOpen ? 'block' : 'hidden', "px-2 pt-2 pb-4 sm:flex sm:p-0")}>
                <div className="text-white text-lg font-sans">
                    <button
                        onClick={() =>props.history.push("/AccountList")}
                        type="button"
                        className={clsx( pathName === "/accountlist" && "bg-opacity-50 bg-gray-500 shadow-lg",
                        "mt-4 px-4 py-3 rounded lg:mt-0 mr-2 hover:bg-opacity-50 hover:bg-gray-500 hover:shadow-lg")}>
                        Accounts
                    </button>
                    <button
                        onClick={() =>props.history.push("/PromotionList")}
                        type="button"
                        className={clsx( pathName === "/promotionlist" && "bg-opacity-50 bg-gray-500 shadow-lg",
                        "mt-4 px-4 py-3 rounded lg:mt-0 mr-2 hover:bg-opacity-50 hover:bg-gray-500 hover:shadow-lg")}>
                        Promotions
                    </button>
                    <button
                        type="button"
                        onClick={() =>props.history.push("/Coupons")}
                        className={clsx( pathName === "/coupons" && "bg-opacity-50 bg-gray-500 shadow-lg",
                        "mt-4 px-4 py-3 rounded lg:mt-0 mr-2 hover:bg-opacity-50 hover:bg-gray-500 hover:shadow-lg")}>
                        Coupons
                    </button>
                    <button
                        onClick={() => props.history.push("/SalesReportChart")}
                        type="button"
                        className={clsx( pathName === "/salesreportchart" && "bg-opacity-50 bg-gray-500 shadow-lg",
                        "mt-4 px-4 py-3 rounded lg:mt-0 mr-2 hover:bg-opacity-50 hover:bg-gray-500 hover:shadow-lg")}>
                        Sales Report
                    </button>
                    <button
                        onClick={() => props.history.push("/UsageReportChart")}
                        type="button"
                        className={clsx( pathName === "/usagereportchart" && "bg-opacity-50 bg-gray-500 shadow-lg",
                        "mt-4 px-4 py-3 rounded lg:mt-0 mr-2 hover:bg-opacity-50 hover:bg-gray-500 hover:shadow-lg")}>
                        Usage Report
                    </button>
                    <button
                        onClick={() => props.history.push("/ActiveStationReport")}
                        type="button"
                        className={clsx( pathName === "/activestationreport" && "bg-opacity-50 bg-gray-500 shadow-lg",
                        "mt-4 px-4 py-3 rounded lg:mt-0 mr-2 hover:bg-opacity-50 hover:bg-gray-500 hover:shadow-lg")}>
                        Active Stations Report
                    </button>
                    <button
                        type="button"
                        onClick={() =>props.history.push("/AddNewAccount")}
                        className={clsx( pathName === "/addnewaccount" && "bg-opacity-50 bg-gray-500 shadow-lg",
                        "mt-4 px-4 py-3 rounded lg:mt-0 mr-2 hover:bg-opacity-50 hover:bg-gray-500 hover:shadow-lg")}>
                        Add Account
                    </button>
                </div>
            </nav>
            <div className="mr-8 hidden sm:block">
                <div className="relative hover-trigger p-6">
                    <button className={clsx(classes.userPorfile, 'p-2 text-white hover:border-secondary hover:bg-secondary text-2xl rounded-full transition ease-in duration-200')}>
                        {makeUserName(props.propsState.admin_first_name, props.propsState.admin_last_name)}
                    </button>
                    <div className="absolute shadow-2xl transition ease-in duration-200 focus:outline-none px-4 py-4 hover-target font-sans" style={{ marginTop: '-30px' }}>
                        My Account
                        <button
                        onClick={() =>{
                            dispatch({ type: 'CustomerLogout' })
                            window.location.href = "/"
                        }}
                        className="my-2 px-3 rounded text-white transition duration-500 ease-in-out bg-safeRed transform hover:-translate-y-1 hover:scale-100 ...">
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default withRouter(AdminHeader);
