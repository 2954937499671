import './css/App.css';
import React, { Component } from 'react';
import Iframe from 'react-iframe';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { connect } from 'react-redux'
import URLHelper from '../Helper/URLHelper'
import AppHelper from '../Helper/AppHelper'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
// import MaskInput from 'react-maskinput';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import MaskedInput from 'react-maskedinput';
import Header from './Header';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
var MSG = '';
var ERROR = 0;
var MONTH = new Date().getMonth() + 1
// var YEAR = new Date().getFullYear()
var YEAR = new Date().getFullYear() + ""
YEAR = YEAR.match(/\d{2}$/)

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    Margin: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    bootstrapInput: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '96%',
        padding: '10px 12px',
        margin: '10px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
    bootstrapInputSmall: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid #ced4da',
        fontSize: 16,
        width: '99%',
        padding: '10px 0px',
        margin: '10px 0px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#673ab7',
            boxShadow: '0 0 0 0.0rem #673ab7',
        },
    },
    Iframe: {
        border: '0px solid black',
        width: '600px',
        height: '28px',
        padding: '0px',
        margin: '0px 0px 5px',
    }
});

class EnterCCInfo extends Component {
    constructor(props) {
        console.log('EnterCCInfo component :', props)
        super(props)
        //console.log("DATE==>" + moment().add(1, 'month').format('YYYY-MM'))
        this.state = {
            type: '',
            Name: '',
            Card_number: '',
            // X_Card_token: '',
            //Expiry_date: moment().add(1, 'month').format('YYYY-MM'),
            Expiry_date: '',
            CVV: '',
            // X_CVV_token: '',
            Street_number: '',
            Address: '',
            Address2: '',
            City: '',
            State: '',
            Zip: '',
            default_card: true,
            agreement: true,
            method: '',
            snackMsg: '',
            spinner: 'none',
            snack: false,
            min: moment().add(1, 'month').format('YYYY-MM'),
            BUTTONS: true,
            open: false,
            signoutButtonDisabled: false
        }
        this.addressReference = React.createRef();
    }
    componentWillMount() {
        // console.log("Enter CC Info Mount")
        // console.log(this.props)
        if (this.props.state.token === '') {
            window.location.href = "/"
        } else if (this.props.location.state !== undefined) {
            this.setState({ PropsData: this.props.location.state.PropsData, Address:  this.props.state.address,
                City:  this.props.state.city, State:  this.props.state.state, Zip:  this.props.state.zip })
            if (this.props.location.state.PropsData.type == 'auto' && this.props.location.state.PropsData.plan_price != 0 && this.props.location.state.PropsData.plan_id == 0) {
                this.props.history.push("/MainMenu")
            }
        }
        else {
            window.location.href = "/MainMenu"
        }
    }

    componentDidMount() {
        const autocompleteFormField = this.addressReference.current;
        const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
            types: ['address'],
        });
        window.google.maps.event.addListener(autocomplete, 'place_changed', () => {

            this.setState({ street_number: '', zip: '', city: '', state: '' })

            const place = autocomplete.getPlace();
            if (place.address_components != null) {
                place.address_components.map((value, index) => {
                    if (value.types.includes('sublocality_level_1') || value.types.includes('sublocality')) {
                        this.setState({ Address2: value.long_name })
                    }
                    if (value.types.includes('street_number')) {
                        this.setState({ Street_number: value.long_name })
                    }
                    if (value.types.includes('route')) {
                        this.setState({ Address: this.state.Street_number + " " + value.long_name })
                    }
                    if (value.types.includes('postal_code')) {
                        this.setState({ Zip: value.long_name })
                    }
                    if (value.types.includes('administrative_area_level_1')) {
                        this.setState({ State: value.long_name })
                    }
                    if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
                        this.setState({ City: value.long_name })
                    }
                })
            }
        });
    }

    inputHandler = (event, inputID) => {
        var value = event.target.value
        if (inputID == "Name") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ Name: value })
            }
        }
        else if (inputID == "Card_number") {
            if (value.length < 17) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ Card_number: value })
                }
            }
        }
        else if (inputID == "Expiry_date") {
            this.setState({ Expiry_date: value })
        }
        else if (inputID == "CVV") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ CVV: value })
                }
            }
        }

        else if (inputID == "Address") {
            this.setState({ Address: value })
        }
        else if (inputID == "Address2") {
            this.setState({ Address2: value })
        }
        else if (inputID == "City") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ City: value })
            }
        }
        else if (inputID == "State") {
            if (/^[a-zA-Z ]*$/.test(value)) {
                this.setState({ State: value })
            }
        }
        else if (inputID == "Zip") {
            if (value.length < 7) {
                if (/^\d+$/.test(value) || value.length == 0) {
                    this.setState({ Zip: value })
                }
            }
        }
    }

    ValidateExpiry = () => {
        var date = this.state.Expiry_date.split('/')
        if (date[1] >= YEAR[0] && date[1].length < 5) {
            if (date[1] == YEAR[0]) {
                if (date[0] > MONTH && date[0] < 13) {
                    return true
                }
                else {
                    return false
                }
            }
            if (date[1] > YEAR[0]) {
                if (date[0] >= 1 && date[0] < 13) {
                    return true
                }
                else {
                    return false
                }
            }
        } else {
            return false
        }
    }

    SetAddress = (data) => {
        var ADDRESS_LENGTH = data.terms.length
        var TERMS = data.terms
        switch (ADDRESS_LENGTH) {
            case 1: {
                break
            }
            case 2: {
                this.setState({ City: '', State: TERMS[ADDRESS_LENGTH - 2].value })
                break
            }
            case 3: {
                this.setState({ State: TERMS[ADDRESS_LENGTH - 2].value, City: TERMS[ADDRESS_LENGTH - 3].value })
                break
            }
            default: {
                this.setState({ State: TERMS[ADDRESS_LENGTH - 2].value, City: TERMS[ADDRESS_LENGTH - 3].value })
                break
            }
        }
    }

    FormSubmit = (event) => {
        event.preventDefault();
        // console.log(this.state.CVV)
        // console.log(this.props.state.user_id)
        // console.log(this.props.location.state.PropsData.plan_id)
        // console.log(this.props.location.state.PropsData.type)
        // console.log(this.state.Name)
        // console.log(this.state.Card_number)
        // console.log(this.state.Expiry_date)
        // console.log(this.state.Address)
        // console.log(this.state.City)
        // console.log(this.state.State)
        // console.log(this.state.Zip)
        // console.log(this.props.location.state.PropsData.plan_id != 0 ? 0 : this.props.location.state.PropsData.plan_price)
        if (this.state.CVV != '' && this.props.state.user_id != '' && (this.props.location.state.PropsData.plan_id != '' ? true : this.props.location.state.PropsData.plan_price != '' ? true : false) && this.props.location.state.PropsData.type != '' && this.state.Name != '' && this.state.Card_number != '' && this.state.Expiry_date != '' && this.state.Address != '' && this.state.City != '' && this.state.State != '' && this.state.Zip != '') {
            if (this.state.agreement) {
                if (this.state.CVV.length > 2 && this.state.CVV.length < 5) {
                    if (this.ValidateExpiry()) {
                        this.AddCredit()
                        // this.GetXToken()
                    }
                    else {
                        this.setState({ snackMsg: "Invalid Expiry Date.", snack: true })
                    }
                }
                else {
                    this.setState({ snackMsg: "CVV is invalid.", snack: true })
                }
            }
            else {
                this.setState({ snackMsg: "Please accept our terms and conditions.", snack: true })
            }
        } else {
            this.setState({ snackMsg: "Please fill all the details.", snack: true })
        }
    }

    async AddCredit() {
        if (navigator.onLine) {
            var Data = {}
            Data.cvv = this.state.CVV;
            Data.user_id = this.props.state.user_id;
            // Data.custom_amount = this.props.location.state.PropsData.plan_id != 0 ? 0 : this.props.location.state.PropsData.plan_price;
            Data.custom_amount = this.props.location.state.PropsData.plan_id == 0 ? this.props.location.state.PropsData.plan_price : (this.props.location.state.PropsData.item_id < 0 ? this.props.location.state.PropsData.price : 0);
            Data.method = 'creditcard';
            Data.transactiontype = this.props.location.state.PropsData.type === 'item_refill' ? 'refill' : this.props.location.state.PropsData.type;
            Data.purchase_type = this.state.PropsData.type === 'item_refill' ? 'item' : 'plan';
            Data.name = this.state.Name;
            Data.cc_num = this.state.Card_number;
            Data.exp_date = AppHelper.changeDateFormat(this.state.Expiry_date);
            Data.address = this.state.Address;
            Data.address2 = this.state.Address2;
            Data.city = this.state.City;
            Data.state = this.state.State;
            Data.zip = this.state.Zip;
            if (this.state.default_card) {
                Data.set_default_card = 1
            }
            if (this.props.location.state.PropsData.type == 'auto') {
                Data.auto_replenish_creditcard_id = '0'
                Data.auto_replenish_plan_id = this.props.location.state.PropsData.plan_id
            }
            else if (this.props.location.state.PropsData.type == 'refill') {
                Data.creditcard_id = '0'
                Data.plan_id = this.props.location.state.PropsData.plan_id
            } else if (this.props.location.state.PropsData.type == 'item_refill') {
                Data.creditcard_id = '0'
                Data.item_id = this.props.location.state.PropsData.item_id
            }
            this.setState({ spinner: 'block', BUTTONS: false, signoutButtonDisabled: true })
            console.log("New Card Payment")
            console.log(Data)
            return fetch(this.props.location.state.PropsData.type != 'auto' ? URLHelper.DoPayment : URLHelper.ConfirmAutoReplenish, {
                method: 'POST',
                headers: {
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json',
                    'authorization': 'Bearer ' + this.props.state.token
                },
                body: JSON.stringify(Data),
            }).then((Response) => {
                if (Response.status == 401) {
                    this.props.Logout()
                    window.location.href = "/"
                }
                Response.json().then((res) => {
                    this.setState({ spinner: 'none', BUTTONS: true, signoutButtonDisabled: false })
                    if (res.status) {
                        if (this.props.location.state.PropsData.type == 'auto') {
                            this.props.history.push("/ReplenishmentDone", { PropsData: this.props.location.state.PropsData.plan_price })
                        }
                        else if (this.props.location.state.PropsData.type == 'refill') {
                            var credit;
                            if (res.data.credit_balance != null && res.data.credit_balance != 'undefined') {
                                // credit= (res.data.credit_balance / AppHelper.chargePerMinute).toFixed(2)
                                credit = res.data.credit_balance
                            }
                            else {
                                credit = 0.00
                            }
                            this.props.UpdateCredit(credit)
                            // this.props.history.push("/RefillSuccess", { PropsData: credit })
                            this.props.history.push("/RefillSuccess", { PropsData: credit, PurchaseType: Data.purchase_type })
                        }
                        else if (this.props.location.state.PropsData.type == 'item_refill') {
                            this.props.history.push("/RefillSuccess", { PropsData: 0, PurchaseType: Data.purchase_type, PurchaseData: res.data })
                        }
                    }
                    else {
                        console.log('Refill failed for new card.... ', res.message )
                        this.setState({ snackMsg: res.message, snack: true, BUTTONS: true, signoutButtonDisabled: false })
                    }
                })
                    .catch((error) => {
                        this.setState({ spinner: 'none', BUTTONS: true, signoutButtonDisabled: false })
                        this.setState({ snackMsg: "Something went wrong.", snack: true })
                    })
            })

        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true })
        }
    }

    PaymentCancel = () => {
        this.setState({ open: true })
    }

    render() {
        const { classes } = this.props;
        if (this.props.state.token !== '') {
            return (
                <div className="App">
                    <Dialog
                        open={this.state.open}
                        onClose={() => this.setState({ open: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">{AppHelper.DailogTitle}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{AppHelper.DailogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ open: false })} color="primary">
                                No
                        </Button>
                            <Button onClick={() => {
                                this.setState({ open: false })
                                this.props.history.goBack()
                            }} color="primary" autoFocus>
                                Yes
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={this.state.snack}
                        autoHideDuration={10000}
                        onClose={() => this.setState({ snack: false })}>
                        <SnackbarContent
                            className="error"
                            aria-describedby="client-snackbar"
                            message={this.state.snackMsg}
                        />
                    </Snackbar>
                    {/* <Button disabled={this.state.signoutButtonDisabled} style={{ color: '#673ab7', float: 'right', margin: 20, textTransform: 'none' }} onClick={() => {
                        {
                            this.props.Logout()
                            window.location.href = "/"
                        }
                    }}>Sign Out</Button> */}

                    <Header propsState={this.props.state} />

                    <Grid container>
                        <Grid item xs />
                        <Grid item xs={10} sm={10} md={4} style={{ background: '#FFFFFF' }}>
                            <div container style={{ marginTop: '4%', border: '0.5px solid #707070', padding: '5px 20px 20px' }}>
                                <Grid container>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <p style={{ color: '#000000', fontSize: 16, fontWeight: 'bold' }}>Credit Card Info</p>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} style={{ textAlign: 'end' }}>
                                        <p style={{ color: '#000000', float: 'right', fontSize: 16, fontWeight: 'bold' }}>{this.state.PropsData.type === 'auto' ? 'Auto Replenish' : (this.state.PropsData.type === 'item_refill' ? 'Purcahse Item' : 'Refill')} ${this.state.PropsData.type === 'item_refill' ? this.state.PropsData.price : this.state.PropsData.plan_price}</p>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <form className={classes.container} noValidate autoComplete="off" onSubmit={this.FormSubmit}>
                                        <TextField
                                            type="text"
                                            id="Name"
                                            autoFocus={true}
                                            label="Card Holder Name"
                                            className={classes.Margin}
                                            value={this.state.Name}
                                            onChange={(event) => this.inputHandler(event, "Name")}
                                            variant="outlined"
                                            margin="normal"
                                            style={{ width: '100%' }}
                                        />
                                        <TextField
                                            type="text"
                                            id="Card_number"
                                            label="Card Number"
                                            className={classes.Margin}
                                            value={this.state.Card_number}
                                            onChange={(event) => this.inputHandler(event, "Card_number")}
                                            variant="outlined"
                                            margin="normal"
                                            style={{ width: '100%' }}
                                        />
                                        <Grid container>
                                            <Grid item xs={6} sm={6} md={6}>
                                                <MaskedInput
                                                    margin="normal"
                                                    mask="11/11"
                                                    name="expiry"
                                                    style={{ margin: '16px 0px 16px 6px', width: '100%', height: '53px', borderRadius: '5px', border: '0.5px solid #c4c4c4', fontSize: '16px', paddingLeft: '12px' }}
                                                    value={this.state.Expiry_date}
                                                    placeholder="Exp Date"
                                                    onChange={(event) => this.inputHandler(event, "Expiry_date")} />
                                            </Grid>
                                            <Grid item xs md={1} />
                                            <Grid item xs={5} sm={5} md={5}>
                                                <TextField
                                                    type="tel"
                                                    id="CVV"
                                                    label="CVV"
                                                    className={classes.Margin}
                                                    value={this.state.CVV}
                                                    onChange={(event) => this.inputHandler(event, "CVV")}
                                                    variant="outlined"
                                                    margin="normal"
                                                />
                                            </Grid>
                                        </Grid>

                                        <input
                                            id="location"
                                            placeholder="Search Address"
                                            type="text"
                                            margin="normal"
                                            style={{ width: '100%', height: '50px', borderRadius: '5px', border: '0.5px solid #c4c4c4', fontSize: '16px', margin: '6px', paddingLeft: '12px' }}
                                            ref={this.addressReference}
                                            variant="outlined"
                                        />

                                        <TextField
                                            type="text"
                                            id="address"
                                            label="Address"
                                            className={classes.Margin}
                                            value={this.state.Address}
                                            onChange={(event) => this.inputHandler(event, "Address")}
                                            variant="outlined"
                                            margin="normal"
                                            style={{ width: '100%' }}
                                        />

                                        <TextField
                                            type="text"
                                            id="address2"
                                            label="Address2"
                                            className={classes.Margin}
                                            value={this.state.Address2}
                                            onChange={(event) => this.inputHandler(event, "Address2")}
                                            variant="outlined"
                                            margin="normal"
                                            style={{ width: '100%' }}
                                        />
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={4}>
                                                <TextField
                                                    type="text"
                                                    id="City"
                                                    label="City"
                                                    className={classes.Margin}
                                                    value={this.state.City}
                                                    onChange={(event) => this.inputHandler(event, "City")}
                                                    variant="outlined"
                                                    margin="normal"

                                                />
                                            </Grid>
                                            <Grid item xs md />
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextField
                                                    type="text"
                                                    id="State"
                                                    label="State"
                                                    className={classes.Margin}
                                                    value={this.state.State}
                                                    onChange={(event) => this.inputHandler(event, "State")}
                                                    variant="outlined"
                                                    margin="normal"

                                                />
                                            </Grid>
                                            <Grid item xs md />
                                            <Grid item xs={12} sm={12} md={3}>
                                                <TextField
                                                    type="text"
                                                    id="Zip"
                                                    label="Zip"
                                                    className={classes.Margin}
                                                    value={this.state.Zip}
                                                    onChange={(event) => this.inputHandler(event, "Zip")}
                                                    variant="outlined"
                                                    margin="normal"

                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.default_card}
                                                        onChange={() => this.setState({ default_card: !this.state.default_card })}
                                                        value={this.state.default_card}
                                                        style={{ color: '#009688' }}
                                                    />
                                                } style={{ color: '#000000' }}
                                                label="Default card on file"
                                            />
                                        </Grid>
                                        <Grid item md={12}>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={this.state.agreement}
                                                        onChange={() => this.setState({ agreement: !this.state.agreement })}
                                                        value={this.state.agreement}
                                                        style={{
                                                            color: '#009688'
                                                        }}
                                                    />
                                                } style={{ color: '#000000' }}
                                                label="Agree to terms and conditions"
                                            />
                                        </Grid>
                                        {this.state.BUTTONS &&
                                            <Grid container>
                                                <Button type="submit" variant="contained" className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#32CD32', color: '#FFFFFF', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                    {this.props.location.state.PropsData.type == 'auto' ? 'Confirm Auto Replenish' : 'Process Payment'}
                                                </Button>
                                                <Button variant="contained" onClick={() => this.PaymentCancel()} className={classes.textField} style={{ textTransform: 'none', fontWeight: 'bold', width: '100%', backgroundColor: '#FFFFFF', color: '#000000', paddingTop: 10, paddingBottom: 10, marginTop: 10 }}>
                                                    Back
                      				    </Button>
                                            </Grid>}
                                        <div style={{ width: '100%', alignItems: 'center', display: this.state.spinner }}>
                                            <center>
                                                <CircularProgress className={classes.progress} />
                                                <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitTitle}</p>
                                                <p style={{ color: '#000000' }}>{AppHelper.PleaseWaitMsg}</p>
                                            </center>
                                        </div>
                                    </form>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs />
                    </Grid>
                </div>
            );
        }
        else {
            return (<div />);
        }
    }
}

EnterCCInfo.propTypes = {
    styles: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
    return {
        state: state
    }
}

const mapDispatchToProps = dispatch => {
    return {
        Logout: () => dispatch({ type: 'CustomerLogout' }),
        UpdateCredit: (credit) => dispatch({ type: 'UpdateCredit', value: credit })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EnterCCInfo));
