import './css/App.css';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
	Grid, CircularProgress, TextField, Tooltip,
	Dialog, DialogActions, DialogContent, DialogTitle,
	 Switch,  Paper, Checkbox, InputAdornment, Button
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import moment from 'moment';
import URLHelper from '../Helper/URLHelper';
import { connect } from 'react-redux';
import AppHelper from '../Helper/AppHelper';
import MaskedInput from 'react-maskedinput';
import successWhitePng from '../assets/success_white.png';
import successPng from '../assets/success.png';
import AdminHeader from './AdminHeader';
import progressPng from '../assets/progress.png';
import clsx from 'clsx';
import "react-tabs/style/react-tabs.css";
import SafeTechSnackbar from './SafeTechSnackbar';
import ApplyButton from './ApplyButton';

const GreenSwitch = withStyles({
	switchBase: {
		color: green[300],
		'&$checked': {
			color: green[500],
		},
		'&$checked + $track': {
			backgroundColor: green[500],
		},
	},
	checked: {},
	track: {},
})(Switch);

const PrimaryCheckbox = withStyles({
	root: {
	  '&$checked': {
		color: '#273359',
	  },
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />);

class AddNewAccount extends Component {

	constructor(props) {
		console.log('AddNewAccount component :', props)
		super(props)
		this.state = {
			first_name: '',
			credit: '',
			spinner: 'none',
			plans_spinner: 'none',
			infoSpinner: 'none',
			logSpinner: 'none',
			snackMsg: '',
			snackVariant: 'error',
			snack: false,
			BUTTONS: false,
			Page: 1,
			billingPage: 1,
			logPage: 1,
			limit: 20,
			hasMore: true,
			hasMoreBillingData: false,
			hasMoreLogData: false,
			// hasMore: false,
			search: '',
			DisplayAccounts: [],
			modalOpen: false,
			modalCPOpen: false,
			modalUBOpen: false,
			modalDPOpen: false,
			setUserIdToChangePassword: null,
			password: '',
			confirm_password: '',
			account_id: 0,
			user_first_name: '',
			user_last_name: '',
			user_street_number: '',
			user_address: '',
			user_city: '',
			user_state: '',
			user_zip: '',
			user_phone: '',
			user_email: '',
			card_phone: '',
			card_email: '',
			is_set_auto_replenish: '',
			auto_replenish_card_id: '',
			input_field_disabled: true,
			edit_button_disabled: true,
			remove_credit_card_id: 0,
			group_policies: [],
			default_policy_string: '',
			groups: [],
			default_selected_index: [],
			selected_index: [],
			policy_string: '',
			current_selected_plan_id: 0,
			current_selected_plan_amount: 0,
			plan_start_date: moment(new Date(), "DD-MM-YYYY"),
			plan_end_date: moment(new Date(), "DD-MM-YYYY").add(30, 'days'),
			modelConfirmDelete: false,
			subscription_plan_action: '',
			action_dialog_message: '',
			action_subscription_plan_id: 0,
			subscription_plans: [],
			filterEvent: 'all',
			currentUserTab: 'customer_info',
			is_billing_address: false,
			card_street_number: '',
            card_address: '',
			card_city: '',
			card_state: '',
			card_zip: '',
			is_save_card_account: false,
			is_primary_card: false,
			is_agree_tnc: false,
			name_on_card: '',
			card_number: '',
			expiry_date: '',
			cvv: '',
			selected_account_plan: '',
			selected_account_plan_price: 0,
			selected_account_plan_bonus: 0,
			selected_account_plan_unlimited: false,
			selected_payment_method: 'creditcard',
			plans: [],
			addNewAccountErrors: [],
			coupon_code: '',
            coupon_code_process: false,
            invalid_coupon_code: false,
            coupon_data: null
		}
		this.addressReference = React.createRef();
		this.cardAddressReference = React.createRef();
	}

	async fetchPlanListing() {
		if (navigator.onLine) {
			this.setState({ plans_spinner: 'block', BUTTONS: false })
			return fetch(URLHelper.GetPlans + "/0", {
				method: 'GET',
				headers: {
					'Accept': 'Application/json',
					'Content-Type': 'application/json',
					'authorization': 'Bearer ' + this.props.state.token
				},
			}).then((Response) => {
				if (Response.status == 401) {
					this.props.Logout()
					window.location.href = "/"
				}
				Response.json().then((res) => {
					this.setState({ plans_spinner: 'none', BUTTONS: true })
					if (res.status) {
						let planList = [];
						if(res.data.length > 0)
						{
							res.data.forEach((element) => {
								// console.log('element ::::', element)
								planList.push(element)
							})
						}
						this.setState({ plans: planList })
					}
					else {
						this.setState({ plans: [], snackMsg: res.message, snack: true, snackVariant: 'error', BUTTONS: true })
					}
				})
				.catch((error) => {
					this.setState({ plans_spinner: 'none', plans: [], snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', BUTTONS: true })
				})
			})
		}
		else {
			this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
		}
	}

	componentDidMount() {
		if (this.props.state.token === '' || this.props.state.user_type === 'User') {
			if(this.props.state.token === ''){
				window.location.href = "/"
			}else{
				window.location.href = "/MainMenu"
			}
		}else{
			this.fetchPlanListing();
			const autocompleteFormField = this.addressReference.current;
			const autocomplete = new window.google.maps.places.Autocomplete((autocompleteFormField), {
				types: ['address'],
			});
			window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
				this.setState({ user_street_number: '', user_address: '' , user_zip: '', user_state: '', user_city: '' })
				const place = autocomplete.getPlace();
				// console.log('PLACE ===>', place);
				if (place.address_components != null) {
					place.address_components.map((value, index) => {
						if (value.types.includes('street_number')) {
							this.setState({ user_street_number: value.long_name })
						}
						if (value.types.includes('route')) {
							this.setFieldValueAndError('user_address', this.state.user_street_number + " " + value.long_name)
							// this.setState({ user_address: this.state.user_street_number + " " + value.long_name })
						}
						if (value.types.includes('postal_code')) {
							this.setFieldValueAndError('user_zip', value.long_name)
							// this.setState({ user_zip: value.long_name })
						}
						if (value.types.includes('administrative_area_level_1')) {
							this.setFieldValueAndError('user_state', value.long_name)
							// this.setState({ user_state: value.long_name })
						}
						if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
							this.setFieldValueAndError('user_city', value.long_name)
							// this.setState({ user_city: value.long_name })
						}
					})
				}
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const cardAutocompleteFormField = this.cardAddressReference.current;
		const cardAutocomplete = new window.google.maps.places.Autocomplete((cardAutocompleteFormField), {
			types: ['address'],
		});
		window.google.maps.event.addListener(cardAutocomplete, 'place_changed', () => {
			this.setState({ card_street_number: '', card_address: '', card_zip: '', card_state: '', card_city: '' })
			const cardPlace = cardAutocomplete.getPlace();
			// console.log('CARD PLACE ===>', cardPlace);
			if (cardPlace.address_components != null) {
				cardPlace.address_components.map((value, index) => {
					if (value.types.includes('street_number')) {
						this.setState({ card_street_number: value.long_name })
					}
					if (value.types.includes('route')) {
						this.setFieldValueAndError('card_address', this.state.card_street_number + " " + value.long_name)
						// this.setState({ card_address: this.state.card_street_number + " " + value.long_name })
					}
					if (value.types.includes('postal_code')) {
						this.setFieldValueAndError('card_zip', value.long_name)
						// this.setState({ card_zip: value.long_name })
					}
					if (value.types.includes('administrative_area_level_1')) {
						this.setFieldValueAndError('card_state', value.long_name)
						// this.setState({ card_state: value.long_name })
					}
					if ((value.types.includes('locality', 'political')) || (value.types.includes("sublocality_level_1", "sublocality", "political"))) {
						this.setFieldValueAndError('card_city', value.long_name)
						// this.setState({ card_city: value.long_name })
					}
				})
			}
		});

		if(prevState.selected_account_plan_price !== this.state.selected_account_plan_price){
			if(this.state.coupon_code && this.state.coupon_code !== ''){
				this.handleApplyCode();
			}
		}
	}

	isValidUserAddress = () => {
		// if(this.state.user_address !== '' && this.state.user_city !== '' && this.state.user_state !== '' && this.state.user_zip !== ''){
		if(this.state.user_address !== '' && this.state.user_city !== '' && this.state.user_state !== '' && this.state.user_zip !== ''
		&& this.state.user_phone !== '' && this.state.user_email !== ''){
			return true;
		}else{
			return false;
		}
	}

	setCardAddressAsUserAddress = () => {
		this.setState({ is_billing_address: true,
			card_address: this.state.user_address,
			card_city: this.state.user_city,
			card_state: this.state.user_state,
			card_zip: this.state.user_zip,
			card_phone: this.state.user_phone,
			card_email: this.state.user_email
		});
	}

	resetCardAddress = () => {
		this.setState({ addNewAccountErrors: [], is_billing_address: false, card_address: '', card_city: '', card_state: '', card_zip: '', card_phone: '', card_email: '' });
	}

	setFieldValueAndError = (fieldName, value) => {
        if(this.state.addNewAccountErrors.indexOf(`${fieldName}`) > -1){
            let newErrors = [ ...this.state.addNewAccountErrors ]
            newErrors.splice(this.state.addNewAccountErrors.indexOf(`${fieldName}`), 1)
            this.setState({ [fieldName]: value, addNewAccountErrors: newErrors })
        }else{
            this.setState({ [fieldName]: value })
        }
	}
	
	inputHandler = (event, inputID) => {
		let value = event.target.value

		if (inputID === "selected_payment_method") {
			let newErrors = [...this.state.addNewAccountErrors]
			let removeArr = ["card_phone", "card_email", "name_on_card", "card_number", "expiry_date", "cvv", "card_address", "card_city", "card_state", "card_zip"]
			let afterRemoveArr = newErrors.filter(value => !removeArr.includes(value));
			// console.log('afterRemoveArr ::', afterRemoveArr)
			if(event.target.checked){
				this.setState({ selected_payment_method: 'creditcard', addNewAccountErrors: afterRemoveArr })
			}else{
				this.setState({ selected_payment_method: 'cash', addNewAccountErrors: afterRemoveArr })
			}
		}

		if (inputID === "policy_string") {
			this.setState({ policy_string: value })
		}

		if (inputID == "first_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setFieldValueAndError('user_first_name', value)
				// this.setState({ user_first_name: value })
			}
			else {
				//console.log("Not First Name")
			}
		}
		else if (inputID == "last_name") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setFieldValueAndError('user_last_name', value)
				// this.setState({ user_last_name: value })
			}
			else {
				//console.log("Not Last Name")
			}
		}
		else if (inputID == "user_address") {
			this.setFieldValueAndError('user_address', value)
			// this.setState({ user_address: value })
		}
		else if (inputID == "card_address") {
			this.setFieldValueAndError('card_address', value)
			// this.setState({ card_address: value })
		}
		else if (inputID == "user_city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setFieldValueAndError('user_city', value)
				// this.setState({ user_city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "card_city") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setFieldValueAndError('card_city', value)
				// this.setState({ card_city: value })
			}
			else {
				//console.log("Not City")
			}
		}
		else if (inputID == "user_state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setFieldValueAndError('user_state', value)
				// this.setState({ user_state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "card_state") {
			if (/^[a-zA-Z ]*$/.test(value)) {
				this.setFieldValueAndError('card_state', value)
				// this.setState({ card_state: value })
			}
			else {
				//console.log("Not State")
			}
		}
		else if (inputID == "user_zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setFieldValueAndError('user_zip', value)
					// this.setState({ user_zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
		else if (inputID == "card_zip") {
			if (value.length < 7) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setFieldValueAndError('card_zip', value)
					// this.setState({ card_zip: value })
				}
				else {
					//console.log("Not Zip")
				}
			}
		}
		else if (inputID == "phone") {
			if (value.length < 11) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setFieldValueAndError('user_phone', value)
					// this.setState({ user_phone: value })
				}
				else {
					//console.log("Not number")
				}
			}
		}
		else if (inputID == "card_phone") {
			if (value.length < 11) {
				if (/^\d+$/.test(value) || value.length == 0) {
					this.setFieldValueAndError('card_phone', value)
					// this.setState({ card_phone: value })
				}
				else {
					//console.log("Not number")
				}
			}
		}
		else if (inputID == "email") {
			this.setFieldValueAndError('user_email', value)
			// this.setState({ user_email: value })
		}
		else if (inputID == "card_email") {
			this.setFieldValueAndError('card_email', value)
			// this.setState({ card_email: value })
		}
		else if (inputID == "name_on_card") {
            if (/^[a-zA-Z ]*$/.test(value)) {
				this.setFieldValueAndError('name_on_card', value)
                // this.setState({ name_on_card: value })
            }
        }
        else if (inputID == "card_number") {
            if (value.length < 17) {
                if (/^\d+$/.test(value) || value.length == 0) {
					this.setFieldValueAndError('card_number', value)
                    // this.setState({ card_number: value })
                }
            }
        }
        else if (inputID == "expiry_date") {
			this.setFieldValueAndError('expiry_date', value)
            // this.setState({ expiry_date: value })
        }
        else if (inputID == "cvv") {
            if (value.length < 5) {
                if (/^\d+$/.test(value) || value.length == 0) {
					this.setFieldValueAndError('cvv', value)
                    // this.setState({ cvv: value })
                }
            }
        }
        else if (inputID == "password") {
			this.setFieldValueAndError('password', value)
            // this.setState({ password: value })
        }
        else if (inputID == "confirm_password") {
			this.setFieldValueAndError('confirm_password', value)
			// this.setState({ confirm_password: value })
        }
        else if (inputID == "coupon_code") {
			this.setFieldValueAndError('coupon_code', value)
			if(value == ''){
				this.setState({ coupon_data: null, invalid_coupon_code: false })
			}
        }
	}

	handleBillingCheckbox = (event, inputField) => {
		// console.log('handleBillingCheckbox :',event.target.checked, inputField)
		if(inputField === 'is_billing_address'){
			this.setBillingAddress(event.target.checked)
		}else{
			this.setState({ [inputField]: event.target.checked })
		}
	}

	setBillingAddress = (action) => {
		if(action){
			this.setState({ is_billing_address: true, card_address: this.state.user_address,
                card_city: this.state.user_city, card_state: this.state.user_state, card_zip: this.state.user_zip })
		}else{
			this.setState({ is_billing_address: false, card_address: '',
                card_city: '', card_state: '', card_zip: '' })
		}
	}

	formSubmit = (event) => {
		event.preventDefault()
		console.log("submit called...")
		this.addNewUser()
	}

	cancelSubmit = () => {
		console.log("cancel called...")
		this.resetCardAddress();
		this.setState({ user_first_name: '', user_last_name: '', user_phone: '', user_address: '', user_city: '',
		user_state: '', user_zip: '', user_email: '', password: '', confirm_password: '', selected_account_plan: '',
		selected_account_plan_price: 0, selected_account_plan_bonus: 0, selected_account_plan_unlimited: false,
		selected_payment_method: 'creditcard', name_on_card: '', card_number: '',
		expiry_date: '',cvv: '', coupon_code: '', coupon_code_process: false, invalid_coupon_code: false, coupon_data: null })
	}

	addNewUser = () => {
		let formErrors = []
		if(this.state.user_first_name === ''){
			formErrors.push('user_first_name')
		}

		if(this.state.user_last_name === ''){
			formErrors.push('user_last_name')
		}

		if(this.state.user_phone === ''){
			formErrors.push('user_phone')
		}

		if(this.state.user_email === ''){
			formErrors.push('user_email')
		}

		if(this.state.user_address === ''){
			formErrors.push('user_address')
		}

		if(this.state.user_city === ''){
			formErrors.push('user_city')
		}

		if(this.state.user_state === ''){
			formErrors.push('user_state')
		}

		if(this.state.user_zip === ''){
			formErrors.push('user_zip')
		}

		if(this.state.password === ''){
			formErrors.push('password')
		}

		if(this.state.confirm_password === ''){
			formErrors.push('confirm_password')
		}

		if(this.state.selected_account_plan !== '' && this.state.selected_payment_method === 'creditcard'){
			if(this.state.name_on_card === ''){
				formErrors.push('name_on_card')
			}

			if(this.state.card_number === ''){
				formErrors.push('card_number')
			}

			if(this.state.expiry_date === ''){
				formErrors.push('expiry_date')
			}

			if(this.state.cvv === ''){
				formErrors.push('cvv')
			}

			if(this.state.card_phone === ''){
				formErrors.push('card_phone')
			}

			if(this.state.card_email === ''){
				formErrors.push('card_email')
			}

			if(this.state.card_address === ''){
				formErrors.push('card_address')
			}

			if(this.state.card_city === ''){
				formErrors.push('card_city')
			}

			if(this.state.card_state === ''){
				formErrors.push('card_state')
			}

			if(this.state.card_zip === ''){
				formErrors.push('card_zip')
			}
		}
		// console.log('formErrors ::',formErrors)
		if(formErrors.length > 0){
			let a = [ ...this.state.addNewAccountErrors,  ...formErrors ];
			let unique = a.filter((item, i, ar) => ar.indexOf(item) === i);
			// console.log('unique ::',unique)
			this.setState({ addNewAccountErrors: unique, snackMsg: "Please fill all the details.", snack: true, snackVariant: 'error' })
		}else{
			let msg = '';
			// if(this.state.password === ''){
			// 	msg += "Password is required";
			// }
			// else if(this.state.confirm_password === ''){
			// 	msg += "Confirm password is required";
			// }
			if(this.state.password !== '' && this.state.confirm_password !== ''){
				if(this.state.password !== this.state.confirm_password){
					msg += "Password and Confirm Password Not Matched";
				}
			}
			if(msg !== ''){
				this.setState({ snackMsg: msg, snack: true, snackVariant: 'error' });
			}	
			else{
				if (navigator.onLine) {
					this.setState({ spinner: 'block' })
					let Data = {
						admin_token: this.props.state.token,
						first_name: this.state.user_first_name,
						last_name: this.state.user_last_name,
						phone_no: this.state.user_phone,
						address: this.state.user_address,
						city: this.state.user_city,
						state: this.state.user_state,
						zip: this.state.user_zip,
						email: this.state.user_email,
						password: this.state.password,
						plan_id: this.state.selected_account_plan,
						method: this.state.selected_payment_method,
						name_on_card: this.state.name_on_card,
						cc_num: this.state.card_number,
						exp_date: AppHelper.changeDateFormat(this.state.expiry_date),
						cvv: this.state.cvv,
						billing_address: this.state.card_address,
						billing_city: this.state.card_city,
						billing_state: this.state.card_state,
						billing_zip: this.state.card_zip,
						billing_phone: this.state.card_phone,
						billing_email: this.state.card_email,
					}
					// apply coupon
					if(this.state.coupon_data){
						Data.coupon_id = (this.state.coupon_code && this.state.coupon_code != '') ? this.state.coupon_data.coupon_id : ''
						Data.coupon_code = ''
					}else if(this.state.coupon_code){
						Data.coupon_code = this.state.coupon_code
						Data.coupon_id = ''
					}else{
						Data.coupon_code = ''
						Data.coupon_id = ''
					}
					return fetch(URLHelper.AddNewAccount, {
						method: 'POST',
						headers: {
							'Accept': 'Application/json',
							'Content-Type': 'application/json',
							'authorization': 'Bearer ' + this.props.state.token
						},
						body: JSON.stringify(Data),
					}).then((Response) => Response.json())
						.then((res) => {
							this.setState({ spinner: 'none' })
							// console.log("Response==>\n" + JSON.stringify(res))
							if (res.status) {
								// console.log("TRUE ====>")
								this.setState({ modalOpen: true, spinner: 'none'}, () => {
									this.cancelSubmit();
								})
								// this.fetchUserData(1, '')
							}
							else {
								this.setState({ snackMsg: res.message, snack: true, snackVariant: 'error', spinner: 'none' })
							}
						})
						.catch((error) => {
							console.log("Error==>" + error)
							this.setState({ snackMsg: "Something went wrong.", snack: true, snackVariant: 'error', spinner: 'none' })
						})
				}
				else {
					this.setState({ snackMsg: "Internet Not Available. Try Again!!", snack: true, snackVariant: 'error' })
				}
			}
		}
	}

    listItems = (plansArray) => {
		// console.log('plans ::',plansArray)
        return (
		plansArray.map((data, index) =>
			<div key={data.plan_id} className="p-2 w-6/12 lg:w-4/12 xl:w-3/12">
				<div className="border-2 rounded py-2 my-2">
					{
						(data.bonus > 0) &&
						<div className="-mt-6" style={{ marginLeft: '86%'}}>
						{/* <div className="-mt-6 -mr-32 md:-mr-40 lg:-mr-32 xl:-mr-40"> */}
							<button type="button"
								className="bg-safeYellow h-10 w-10 p-1 text-white rounded-lg font-sans text-xs">
								Save <span className="text-smXs">{(data.bonus * 100 / data.price) % 1 != 0 ? (data.bonus * 100 / data.price).toFixed(2) : (data.bonus * 100 / data.price)}%</span>
							</button>
						</div>
					}
					<div className={clsx((data.bonus > 0) && '-mt-4', 'flex flex-col items-center')}>
						<span className="py-1 md:py-2">{data.is_unlimited ? 'Monthly Subscription' : this.convertTime(data.price + data.bonus)}</span>
						{
							(this.state.selected_account_plan !== '' && this.state.selected_account_plan === data.plan_id) ?
							<button onClick={() => {
									let newErrors = [...this.state.addNewAccountErrors]
									let removeArr = ["card_phone", "card_email", "name_on_card", "card_number", "expiry_date", "cvv", "card_address", "card_city", "card_state", "card_zip"]
									let afterRemoveArr = newErrors.filter(value => !removeArr.includes(value));
									this.setState({ selected_account_plan: '', selected_account_plan_price: 0, selected_account_plan_bonus: 0, selected_account_plan_unlimited: false, selected_payment_method: 'creditcard', addNewAccountErrors: afterRemoveArr })
								}}
							 	type="button" className="bg-safeGreen w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
								<div className="flex flex-row items-center">
									<img className="h-4" src={successWhitePng} alt="success-white-png"/>
									<span className="pl-2">Selected</span>
								</div>
							</button>
							:
							<button onClick={() => {
									let newStateData = {
										selected_account_plan: data.plan_id, selected_account_plan_price: data.price, selected_account_plan_bonus: data.bonus, selected_account_plan_unlimited: data.is_unlimited
									}
									if(data.is_unlimited){
										newStateData = { ...newStateData, coupon_code: "", coupon_data: null }
									}
									this.setState(newStateData)
								}}
								type="button" className="bg-primary w-24 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
								<span className="text-xs">$ </span>{ data.price.toFixed(2) }
							</button>
						}
					</div>
				</div>
			</div>
		));
	}
	
	handleClose = () => {
		this.setState({ modalOpen: false})
	}

    hideSnackBar = () => {
		this.setState({ snack: false })
	}

	convertTime = (amount) => {
        // console.log('convertTime ::',amount)
        let givenSeconds = (amount / AppHelper.chargePerMinute) * 60;
        // console.log('givenSeconds ::',givenSeconds)
		let dateObj = new Date(givenSeconds * 1000); 
		let hours = dateObj.getUTCHours(); 
		let minutes = dateObj.getUTCMinutes();

        if((amount / AppHelper.chargePerMinute) > 120){
            return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' Hours';
        }else{
            return (amount / AppHelper.chargePerMinute).toFixed(0) + ' Minutes'; 
        }
    }

	getCouponAmount = (couponData) => {
		const price = this.state.selected_account_plan_price;
        if(couponData){
            const { discount_type, discount } = couponData;
            if(discount_type === 'F') return discount;
            if(discount_type === 'P') return (price * discount) / 100;
        }else{
            return price;
        }
	}
	
	// getDiscountString = () => {
    //     if(this.state.coupon_data){
    //         const { discount_type, discount } = this.state.coupon_data
    //         if(discount_type === 'F') return  `You will get flat ${AppHelper.makeCurrencyFormat(discount)} discount!`
    //         if(discount_type === 'P') return  `You will get ${discount}% discount!`
    //     }else{
    //         return ''
    //     }
	// }

	async handleApplyCode() {
        if (navigator.onLine) {
            var Data = {}
            Data.admin_token = this.props.state.token
            Data.coupon_code = this.state.coupon_code
            this.setState({ payment_spinner: 'block', coupon_code_process: true, signoutButtonDisabled: true })
            return fetch(URLHelper.ValidateCouponCode, {
                method: 'POST',
                headers: {
                    'authorization': 'Bearer ' + this.props.state.token,
                    'Accept': 'Application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(Data),
            }).then((Response) => Response.json())
                .then((res) => {
                    this.setState({ payment_spinner: 'none', coupon_code_process: false })
                    if (res.status) {
                        // console.log("ValidateCouponCode resp ::", res);
                        const { data, message } = res
						if(this.getTotalDue({...data, message }, false) > 0){
                            this.setState({ coupon_data: {...data, message }, invalid_coupon_code: false })
                        }else{
                            this.setState({ invalid_coupon_code: true })
                        }
                    }
                    else {
                        this.setState({ coupon_data: null, invalid_coupon_code: false, snackMsg: res.message, snack: true, snackVariant: 'error', signoutButtonDisabled: false, payUsingCreditBalance: false })
                    }
                })
                .catch((error) => {
                    console.log(error)
                    this.setState({ snackMsg: "Something went wrong.", coupon_data: null, invalid_coupon_code: false, snack: true, snackVariant: 'error', spinner: 'none', BUTTONS: true, signoutButtonDisabled: false, payUsingCreditBalance: false })
                })
        }
        else {
            this.setState({ snackMsg: "Internet Not Available. Try Again!!", coupon_data: null, invalid_coupon_code: false, snack: true, snackVariant: 'error', payUsingCreditBalance: false })
        }
    }

	getTotalDue = (couponData, currencyFormat = true) => {
        const price = this.state.selected_account_plan_price;
        if(couponData){
            const { discount_type, discount } = couponData;
            if(discount_type === 'F') return currencyFormat ? (AppHelper.makeCurrencyFormat(price - discount)) : (price - discount);
            if(discount_type === 'P') return currencyFormat ? (AppHelper.makeCurrencyFormat(price - ((price * discount) / 100))) : (price - ((price * discount) / 100));
        }else{
            return AppHelper.makeCurrencyFormat(price)
        }
	}

    // getDiscountAmount = () => {
    //     const price = this.state.selected_account_plan_price;
    //     if(this.state.coupon_data){
    //         const { discount_type, discount } = this.state.coupon_data
    //         if(discount_type === 'F') return AppHelper.makeCurrencyFormat(discount)
    //         if(discount_type === 'P') return AppHelper.makeCurrencyFormat(((price * discount) / 100))
    //     }else{
    //         return AppHelper.makeCurrencyFormat(0)
    //     }
	// }
	
	render() {
		if (this.props.state.token !== '' && this.props.state.user_type === 'Admin') {
			return (
				<div className="App">
					{/* <Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						open={this.state.snack}
						autoHideDuration={1500}
						onClose={() => this.setState({ snack: false })}>
						<SnackbarContent
							className="error"
							aria-describedby="client-snackbar"
							message={this.state.snackMsg}
						/>
					</Snackbar> */}
					<SafeTechSnackbar snackMsg={this.state.snackMsg} snack={this.state.snack} variant={this.state.snackVariant} closeSnack={this.hideSnackBar} />

					<AdminHeader propsState={this.props.state} />
					
					<div className="my-12 w-11/12 sm:w-9/12 rounded mx-auto">
                        <Paper elevation={3}>
                            <div className="flex-initial w-full rounded text-center shadow-xl py-4 px-2 sm:px-24">
                                <div className="rounded-md">

                                    <div className="flex pt-4 pb-2">
                                        <span className="float-left text-primary text-sm">Add New Account</span>
                                    </div>

                                    <form noValidate autoComplete="off" onSubmit={this.formSubmit}>
                                    
                                        <div className="border-2 my-4 py-4 px-8 rounded">
                                            <span className="float-left my-2 text-safeFormGray text-sm">Customer Info</span>
                                            <Grid container spacing={2} className="pb-4">
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextField
                                                        autoFocus={true}
                                                        id="first_name"
                                                        label="First Name *"
                                                        type="text"
                                                        className="w-full"
														value={this.state.user_first_name}
														error={this.state.addNewAccountErrors.includes('user_first_name')}
                                                        onChange={(event) => this.inputHandler(event, "first_name")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6}>
                                                    <TextField
                                                        id="last_name"
                                                        label="Last Name *"
                                                        type="text"
                                                        className="w-full"
														value={this.state.user_last_name}
														error={this.state.addNewAccountErrors.includes('user_last_name')}
                                                        onChange={(event) => this.inputHandler(event, "last_name")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
												<Grid item xs={12} sm={12} md={12}>
													<input
														id="location-user-info"
														placeholder="Search Address"
														type="text"
														margin="normal"
														style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
														ref={this.addressReference}
														variant="outlined"
														className="w-full text-black border-solid border border-gray-500 rounded"
													/>
												</Grid>
                                            </Grid>
                                            <TextField
                                                id="user_address"
                                                label="Address *"
                                                type="text"
                                                className="w-full"
												value={this.state.user_address}
												error={this.state.addNewAccountErrors.includes('user_address')}
                                                onChange={(event) => this.inputHandler(event, "user_address")}
                                                variant="outlined"
                                            />
                                            <div className="flex pt-4 pb-2">
                                                <div className="flex-initial w-2/5 pr-2 text-center">
                                                    <TextField
                                                        id="user_city"
                                                        label="City *"
                                                        type="text"
                                                        className="w-full"
														value={this.state.user_city}
														error={this.state.addNewAccountErrors.includes('user_city')}
                                                        onChange={(event) => this.inputHandler(event, "user_city")}
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="flex-initial w-1/5 pr-2 text-center">
                                                    <TextField
                                                        id="user_state"
                                                        label="State *"
                                                        type="text"
                                                        className="w-full"
														value={this.state.user_state}
														error={this.state.addNewAccountErrors.includes('user_state')}
                                                        onChange={(event) => this.inputHandler(event, "user_state")}
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="flex-initial w-2/5 text-gray-700 text-center">
                                                    <TextField
                                                        id="user_zip"
                                                        label="Zip *"
                                                        type="tel"
                                                        className="w-full"
														value={this.state.user_zip}
														error={this.state.addNewAccountErrors.includes('user_zip')}
                                                        onChange={(event) => this.inputHandler(event, "user_zip")}
                                                        variant="outlined"
                                                    />
                                                </div>
                                            </div>
                                            <Grid container spacing={2} className="py-2">
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="phone"
                                                        label="Phone # *"
                                                        type="tel"
                                                        className="w-full"
														value={this.state.user_phone}
														error={this.state.addNewAccountErrors.includes('user_phone')}
                                                        onChange={(event) => this.inputHandler(event, "phone")}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="email"
                                                        label="Email *"
                                                        type="email"
                                                        className="w-full"
														value={this.state.user_email}
														error={this.state.addNewAccountErrors.includes('user_email')}
                                                        onChange={(event) => this.inputHandler(event, "email")}
														variant="outlined"
														inputProps={{
															autoComplete: 'new-email',
															form: {
																autoComplete: 'off',
															},
														}}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="password"
                                                        label="Temp Password *"
                                                        type="password"
                                                        className="w-full"
														value={this.state.password}
														error={this.state.addNewAccountErrors.includes('password')}
                                                        onChange={(event) => this.inputHandler(event, "password")}
														variant="outlined"
														inputProps={{
															autoComplete: 'new-password',
															form: {
																autoComplete: 'off',
															},
														}}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={6} >
                                                    <TextField
                                                        id="confirm_password"
                                                        label="Confirm Temp Password *"
                                                        type="password"
                                                        className="w-full"
														value={this.state.confirm_password}
														error={this.state.addNewAccountErrors.includes('confirm_password')}
                                                        onChange={(event) => this.inputHandler(event, "confirm_password")}
														variant="outlined"
														inputProps={{
															autoComplete: 'confirm-password',
															form: {
																autoComplete: 'off',
															},
														}}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </div>

                                        <div className="border-2 my-4 py-4 px-8 rounded">
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={12}>
                                                    <span className="float-left block text-safeFormGray text-sm">Select Plan</span>
                                                </Grid>
                                            </Grid>
                                            <div className="py-6">
												<div className="flex flex-wrap text-sm text-safeFormGray">
													<div style={{ width: '100%', alignItems: 'center', display: this.state.plans_spinner }}>
														<center><CircularProgress /></center>
													</div>
													{ this.listItems(this.state.plans) }
													{/* <div key={'static'} className="p-2 w-6/12 lg:w-4/12 xl:w-3/12">
														<div className="border-2 rounded py-2 my-2">
															<div className='flex flex-col items-center'>
																<span className="py-1 md:py-2">Monthly Subscription</span>
																<button type="button" className="bg-primary w-32 -mb-6 text-white px-1 md:px-2 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
																	<span className="text-xs">Comming soon</span>
																</button>
															</div>
														</div>
													</div> */}
												</div>
                                            </div>
                                        </div>
										{/* {console.log(this.state.addNewAccountErrors)} */}
										{
											(this.state.selected_account_plan !== '') &&
											<div className="flex items-center justify-between" style={{ marginBottom: ((this.state.selected_payment_method === 'creditcard') ? '0px' : '10px' ) }}>
												<div className="flex items-center">
													<span className="float-left text-primary text-sm">Payment Method: </span>
													<Tooltip title={(this.state.selected_payment_method === 'creditcard') ? "Credit Card" : "Cash"} placement="top">
														<GreenSwitch
															checked={(this.state.selected_payment_method === 'creditcard') ? true : false}
															onChange={(ev) => this.inputHandler(ev, "selected_payment_method")}
															value={this.state.selected_payment_method}
														/>
													</Tooltip>
													<span className="float-right text-primary font-semibold text-sm">{(this.state.selected_payment_method === 'creditcard') ? 'Credit Card' : 'Cash'}</span>
												</div>
												{
													this.state.coupon_data &&
													<div className='flex flex-col border rounded-lg shadow py-2 px-4' style={{ width: '250px' }}>
														<span className="text-safeFormGray tracking-normal text-lg font-medium">
															Time Added: {this.convertTime(this.getCouponAmount(this.state.coupon_data) + this.state.selected_account_plan_price + this.state.selected_account_plan_bonus)}
														</span>
														{/* <div className='flex items-center justify-between'>
															<span className='text-safeFormGray tracking-normal text-xs'>Order amount:</span>
															<span className='text-safeFormGray text-sm'>{AppHelper.makeCurrencyFormat(this.state.selected_account_plan_price)}</span>
														</div>
														<div className='flex items-center justify-between'>
															<span className='text-safeFormGray tracking-normal text-xs'>Coupon discount:</span>
															<span className='text-safeFormGray text-sm'>(-) {this.getDiscountAmount()}</span>
														</div>
														<div className='flex items-center justify-between'>
															<span className='text-safeFormGray tracking-normal text-sm'>Total Due:</span>
															<span className='text-primary tracking-normal text-lg'>{this.getTotalDue(this.state.coupon_data)}</span>
														</div> */}
													</div>
												}
												<div className='flex flex-col'>
													{
                                                        this.state.coupon_code_process ? 
                                                        <CircularProgress /> :
														<>
															{
																(!this.state.selected_account_plan_unlimited) &&
																<div className="flex justify-center w-full">
																	<TextField
																		id="coupon_code"
																		label="Coupon Code"
																		type="text"
																		placeholder="Enter coupon code here"
																		value={this.state.coupon_code}
																		style={{ width: '270px' }}
																		// disabled={this.state.invalid_coupon_code}
																		onChange={(event) => this.inputHandler(event, "coupon_code")}
																		variant="outlined"
																		InputProps={{
																			endAdornment: (
																				<ApplyButton handleApplyCode={() => this.handleApplyCode()} couponCode={this.state.coupon_code} />
																			),
																			autoComplete: 'new-coupon_code',
																			form: {
																				autoComplete: 'off',
																			},
																		}}
																	/>
																</div>
															}
														</>
                                                    }
													{
                                                        (this.state.coupon_data && !this.state.invalid_coupon_code) &&
                                                        <div className="flex justify-center py-1">
															<p className="text-xs text-safeFormGray">
                                                            <span className="text-xs text-safeGreen pr-1">{ this.state.coupon_data.message }</span>
                                                            {/* { this.getDiscountString() } */}
															You will get <span className='font-semibold'>{this.convertTime(this.getCouponAmount(this.state.coupon_data))}</span> Extra!
                                                            </p>
                                                        </div>
                                                    }
													{
														this.state.invalid_coupon_code &&
														<div className="flex justify-center py-1">
															<span className="text-xs text-safeRed pr-1">Coupon code isn't applicable for this plan!</span>
														</div>
													}
												</div>
											</div>
										}

										{
											(this.state.selected_payment_method === 'creditcard' && this.state.selected_account_plan !== '') &&
											<div className="border-2 my-4 py-4 px-8 rounded">
												<span className="float-left my-2 text-safeFormGray text-sm">Credit Card Info</span>
												<div className="flex-col">
													<div className="text-center">
														<Grid container spacing={2}>
															<Grid item xs={12} sm={12} md={12}>
																<div className="flex">
																	<div className="flex-initial w-1/2 text-center pr-4">
																		<TextField
																			id="name_on_card"
																			label="Name on card *"
																			type="text"
																			className="w-full"
																			value={this.state.name_on_card}
																			error={this.state.addNewAccountErrors.includes('name_on_card')}
																			onChange={(event) => this.inputHandler(event, "name_on_card")}
																			variant="outlined"
																		/>
																	</div>
																	<div className="flex-initial w-1/2 text-center">
																		<TextField
																			id="card_number"
																			label="Card Number *"
																			type="text"
																			className="w-full"
																			value={this.state.card_number}
																			error={this.state.addNewAccountErrors.includes('card_number')}
																			onChange={(event) => this.inputHandler(event, "card_number")}
																			variant="outlined"
																		/>
																	</div>
																</div>
															</Grid>
															<Grid item xs={12} sm={12} md={12}>
																<div className="flex">
																	<div className="flex-initial w-1/2 text-center pr-4">
																		<MaskedInput
																			mask="11/11"
																			id="expiry_date"
																			name="expiry_date"
																			className={clsx(this.state.addNewAccountErrors.includes('expiry_date') ? 'focus:outline-none masked-input-error' : 'masked-input', 'w-full pl-2 rounded')}
																			// className={clsx(true ? 'focus:outline-none masked-input-error' : 'masked-input', 'w-full pl-2 border-2 border-gray-300 text-black rounded')}
																			style={{ height: '55px', fontSize: '16px' }}
																			value={this.state.expiry_date}
																			placeholder="MM/YY *"
																			onChange={(event) => this.inputHandler(event, "expiry_date")}
																		/>
																	</div>
																	<div className="flex-initial w-1/2 text-center">
																		<TextField
																			type="tel"
																			id="cvv"
																			label="CVV *"
																			className="w-full"
																			value={this.state.cvv}
																			error={this.state.addNewAccountErrors.includes('cvv')}
																			onChange={(event) => this.inputHandler(event, "cvv")}
																			variant="outlined"
																		/>
																	</div>
																</div>
															</Grid>
														</Grid>
													</div>
													<div className="text-center mt-4">
														<div className="flex-wrap sm:flex my-2 items-end">
															<div className="flex-1 text-safeFormGray text-sm">
																<span className="float-left my-2">Billing Address</span>
															</div>
															<div className="flex-1">
																<div className="float-none sm:float-right">
																	<div className="items-center">
																		<div className="text-center text-safeFormGray text-xs py-2 float-left">
																			<PrimaryCheckbox
																			size="small"
																			checked={this.state.is_billing_address}
																			onChange={(event) => {
																				let newErrors = [...this.state.addNewAccountErrors]
																				let removeArr = ["card_phone", "card_email", "card_address", "card_city", "card_state", "card_zip"]
																				let afterRemoveArr = newErrors.filter(value => !removeArr.includes(value));
																				if(event.target.checked){
																					if(this.isValidUserAddress()){
																						this.setState({ addNewAccountErrors: afterRemoveArr }, () => this.setCardAddressAsUserAddress())
																					}else{
																						this.setState({ snackMsg: "Please fill customer info first!!", snack: true, snackVariant: 'error' })
																					}
																				}else{
																					this.setState({ addNewAccountErrors: afterRemoveArr }, () => this.resetCardAddress())
																				}
																			}}
																			// onChange={(event) => { (event.target.checked) ? this.state.edit_button_disabled) ? this.setState({ snackMsg: "You haven't selected any user!!", snack: true, snackVariant: 'error' }) : this.handleBillingCheckbox(event, 'is_billing_address') }}
																			inputProps={{ 'aria-label': 'billing address checkbox' }}/>
																			Same as customer info
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div>
															<Grid container spacing={2}>
																<Grid item xs={12} sm={12} md={12}>
																	<input
																		id="location-card-info"
																		placeholder="Search Address"
																		disabled={this.state.is_billing_address}
																		type="text"
																		margin="normal"
																		style={{ height: '50px', fontSize: '16px', paddingLeft: '12px' }}
																		ref={this.cardAddressReference}
																		variant="outlined"
																		className="w-full border-solid border border-gray-500 rounded"
																	/>
																</Grid>
																<Grid item xs={12} sm={12} md={12}>
																	<TextField
																		id="card_address"
																		label="Address *"
																		type="text"
																		disabled={this.state.is_billing_address}
																		className="w-full"
																		value={this.state.card_address}
																		error={this.state.addNewAccountErrors.includes('card_address')}
																		onChange={(event) => this.inputHandler(event, "card_address")}
																		variant="outlined"
																	/>
																</Grid>
																<Grid item xs={12} sm={12} md={12}>
																	<div className="flex">
																		<div className="flex-auto w-2/5 pr-2 text-center">
																			<TextField
																				id="card_city"
																				label="City *"
																				type="text"
																				disabled={this.state.is_billing_address}
																				className="w-full"
																				value={this.state.card_city}
																				error={this.state.addNewAccountErrors.includes('card_city')}
																				onChange={(event) => this.inputHandler(event, "card_city")}
																				variant="outlined"
																			/>
																		</div>
																		<div className="flex-auto w-1/5 pr-2 text-center">
																			<TextField
																				id="card_state"
																				label="State *"
																				type="text"
																				disabled={this.state.is_billing_address}
																				className="w-full"
																				value={this.state.card_state}
																				error={this.state.addNewAccountErrors.includes('card_state')}
																				onChange={(event) => this.inputHandler(event, "card_state")}
																				variant="outlined"
																			/>
																		</div>
																		<div className="flex-auto w-2/5 text-center">
																			<TextField
																				id="card_zip"
																				label="Zip *"
																				type="tel"
																				disabled={this.state.is_billing_address}
																				className="w-full"
																				value={this.state.card_zip}
																				error={this.state.addNewAccountErrors.includes('card_zip')}
																				onChange={(event) => this.inputHandler(event, "card_zip")}
																				variant="outlined"
																			/>
																		</div>
																	</div>
																</Grid>
																<Grid item xs={12} sm={12} md={12}>
																	<div className="flex">
																		<div className="flex-initial w-1/2 text-center pr-4">
																			<TextField
																				id="card_phone"
																				label="Phone # *"
																				type="tel"
																				disabled={this.state.is_billing_address}
																				className="w-full"
																				value={this.state.card_phone}
																				error={this.state.addNewAccountErrors.includes('card_phone')}
																				onChange={(event) => this.inputHandler(event, "card_phone")}
																				variant="outlined"
																			/>
																		</div>
																		<div className="flex-initial w-1/2 text-center">
																			<TextField
																				id="crad_email"
																				label="Email *"
																				type="email"
																				disabled={this.state.is_billing_address}
																				className="w-full"
																				value={this.state.card_email}
																				error={this.state.addNewAccountErrors.includes('card_email')}
																				onChange={(event) => this.inputHandler(event, "card_email")}
																				variant="outlined"
																			/>
																		</div>
																	</div>
																</Grid>
															</Grid>
														</div>
													</div>
												</div>
											</div>
										}
                                            
                                        <div className="flex">
											<div className="flex-1">
											</div>
											<div className="flex-1">
												<div className="float-none sm:float-right">
													<button type="reset" onClick={() => this.cancelSubmit()} className="text-safeRed px-2 md:px-6 font-sans font-semibold text-xs md:text-sm">Cancel</button>
													<button type="submit" disabled={( (this.state.spinner === 'block' || this.state.invalid_coupon_code) ? true : false )} className="bg-primary text-white px-2 md:px-4 py-2 shadow-xl rounded font-sans text-xs md:text-sm">
														{(this.state.spinner === 'block') ? <img className="h-5 mx-auto" src={progressPng} alt='progress'/> : 'Add Account' }
													</button>
												</div>
											</div>
                                    	</div>
                                    
                                    </form>
                                
                                </div>
                            </div>
                        </Paper>
					</div>
					
					<Dialog open={this.state.modalOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
						<DialogTitle id="form-dialog-title">
							<Grid container>
								<div className="flex flex-col w-full">
									<div className="text-center">
										<img className="h-8 mx-auto" src={successPng} alt="success-png"/>
									</div>
								</div>
							</Grid>
						</DialogTitle>
						<DialogContent>
							<span className="text-primary">Account added successfully!</span>
						</DialogContent>
						<DialogActions>
							<Grid container>
								<div className="flex flex-col w-full">
									<div className="text-center">
										<button
											type="button"
											className="bg-primary w-24 text-white py-3 my-2 shadow-xl rounded text-base"
											onClick={() => this.handleClose()}>Done
										</button>
									</div>
								</div>
							</Grid>
						</DialogActions>
					</Dialog>
				</div>
			);
		}
		else {
			return (<div />);
		}
	}
}

const mapStateToProps = state => {
	return {
		state: state
	}
}

const mapDispatchToProps = dispatch => {
	return {
		Logout: () => dispatch({ type: 'CustomerLogout' }),
		UpdateCustomer: (user_id, first_name, credit, address, city, state, zip) => {
			let value = {
				user_id: user_id,
				first_name: first_name,
				credit_balance: credit,
				address: address,
				city: city,
				state: state,
				zip: zip,
			}
			dispatch({ type: 'UpdateCustomer', value: value })
		},
		UpdateMSG: (value) => dispatch({ type: 'UpdateSnackBarMsg', value: value })
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewAccount);